<div class="admin-container">
    <div class="row">
        <h1 class="col page-title">{{ 'management.main.title' | translate }}</h1>
    </div>
    <div class="list-group">
        <a (click)="goToUserManagement()"
           class="list-group-item list-group-item-action cursor-pointer">{{ 'management.main.nav.user' | translate }}
            <i class="fa fa-arrow-right"></i></a>
        <a (click)="goToRightManagement()"
           class="list-group-item list-group-item-action cursor-pointer">{{ 'management.main.nav.right' | translate }}
            <i class="fa fa-arrow-right"></i></a>
    </div>
</div>
