<ng-template #addResponseBlock>
    <div class="modal-header">
        <h4 class="modal-title">{{ "actions.dialog.response.title" | translate }}</h4>
    </div>
    <form (ngSubmit)="saveResponse()">
        <div class="modal-body">
            <p class="text-warning">{{ "actions.dialog.response.info status" | translate }}</p>
            <div class="form-group">
                <textarea
                    class="form-control"
                    name="reponse"
                    [(ngModel)]="actionToAddResponse.reponse"
                    placeholder="{{ 'actions.dialog.response.your response' | translate }}"
                ></textarea>
            </div>
        </div>

        <div class="modal-footer">
            <button type="submit" class="btn btn-success mr-3" [ngClass]="{ disabled: isSaving }">
                <span [hidden]="isSaving">
                    {{ "actions.dialog.response.button add response" | translate }}
                </span>
                <i [hidden]="!isSaving" class="fas fa-sync fa-spin"></i>
            </button>
            <button type="button" class="btn btn-light" (click)="closeDialog()">
                {{ "actions.dialog.response.close" | translate }}
            </button>
        </div>
    </form>
</ng-template>
