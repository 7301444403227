import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import {SatisfaiComponent} from './app.component';
import {SharedModule} from '@shared/shared.module';
import {HomeModule} from './home/home.module';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {UserModule} from './user/user.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AnomaliesModule} from './anomalies/anomalies.module';
import {LoadingInterceptor} from '@shared/interceptors/loading.interceptor';
import {MenuModule} from './menu/menu.module';
import {ActionsModule} from './actions/actions.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDatepickerModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {ErrorInterceptor} from '@shared/interceptors/error.interceptor';
import {NgxLoadingModule} from 'ngx-loading';
import {ChartsModule} from 'ng2-charts';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {DpDatePickerModule} from 'ng2-date-picker';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ManagementModule} from './management/management.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {CredentialsInterceptor} from "@shared/interceptors/credentials.interceptor";
import {PopoverModule} from "ngx-bootstrap/popover";

registerLocaleData(localeFr);

@NgModule({
    declarations: [SatisfaiComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule,
        SweetAlert2Module.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        NgxLoadingModule.forRoot({
            backdropBackgroundColour: 'transparent',
            primaryColour: '#17a2b8',
            secondaryColour: '#17a2b8',
            tertiaryColour: '#17a2b8',
        }),
        NgbDatepickerModule,
        NgbPopoverModule,
        PopoverModule.forRoot(),
        SharedModule,
        HomeModule,
        UserModule,
        MenuModule,
        AnomaliesModule,
        ActionsModule,
        ManagementModule,
        AppRoutingModule,
        ChartsModule,
        DpDatePickerModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        NgSelectModule,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: 'fr-FR'},
    ],
    bootstrap: [SatisfaiComponent],
})
export class AppModule {
    constructor() {
        registerLocaleData(localeFr, 'fr');
        registerLocaleData(localeEn, 'en');
    }
}

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/');
}
