import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionStatus} from 'src/app/actions/model/action-status.constant';
import {Investigation} from 'src/app/anomalies/model/investigation.model';

@Component({
    selector: 'sai-action-response',
    templateUrl: './action-response.component.html',
    styleUrls: ['./action-response.component.sass'],
})
export class ActionResponseComponent implements OnInit {
    @Input() action: Investigation;
    @Input() showAddResponseButton: boolean;
    @Output() openAddResponseDialog = new EventEmitter<Investigation>();
    public actionStatus: ActionStatus;

    constructor() {
        this.actionStatus = new ActionStatus();
    }

    ngOnInit(): void {
    }

    openAddResponse(): void {
        this.openAddResponseDialog.emit(this.action);
    }
}
