import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {ActionsMonitoringComponent} from './actions-monitoring/actions-monitoring.component';
import {TranslateModule} from '@ngx-translate/core';
import {ActionService} from './services/action.service';
import {AddResponseDialogComponent} from './add-response-dialog/add-response-dialog.component';
import {FormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {AnomalyDetailDialogComponent} from './anomaly-detail-dialog/anomaly-detail-dialog.component';
import {AnomaliesModule} from '../anomalies/anomalies.module';
import {DashboardComponent} from './dashboard/dashboard.component';
import {FiltersModule} from '../filters/filters.module';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {ActionsMonitoringService} from './services/actions-monitoring.service';
import {DpDatePickerModule} from 'ng2-date-picker';
import {ActionsDateFilterComponent} from './actions-date-filter/actions-date-filter.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';

@NgModule({
    declarations: [
        ActionsMonitoringComponent,
        AddResponseDialogComponent,
        AnomalyDetailDialogComponent,
        DashboardComponent,
        ActionsDateFilterComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        SharedModule,
        NgbPopoverModule,
        NgbDropdownModule,
        AnomaliesModule,
        FiltersModule,
        RouterModule,
        SweetAlert2Module,
        DpDatePickerModule,
        BsDatepickerModule
    ],
    providers: [ActionService, ActionsMonitoringService],
    exports: []
})
export class ActionsModule {
}
