import {Component, Input, OnInit} from '@angular/core';
import {Anomaly} from 'src/app/anomalies/model/anomaly.model';
import {AnomalyService} from 'src/app/anomalies/services/anomaly.service';

@Component({
    selector: 'sai-view-shipping-numbers',
    templateUrl: './view-shipping-numbers.component.html',
    styleUrls: ['./view-shipping-numbers.component.sass'],
})
export class ViewShippingNumbersComponent implements OnInit {
    @Input() anomaly: Anomaly;
    public loadingShippingNumbers: boolean;

    constructor(private anomalyService: AnomalyService) {
    }

    ngOnInit(): void {
        this.fulfillShippingNumbers();
    }

    fulfillShippingNumbers() {
        this.loadingShippingNumbers = true;
        this.anomalyService
            .findShippingNumbersFor(this.anomaly)
            .toPromise()
            .then((shippingNumbers) => {
                this.anomaly.shippingNumbers = shippingNumbers;
            })
            .finally(() => this.loadingShippingNumbers = false);
    }
}
