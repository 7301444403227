import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserManagement} from '../model/user-management.model';
import {UserManagementService} from '../services/user-management.service';
import {CompanyCode} from "@shared/referential/refe-company-code.model";
import {RefeService} from "@shared/services/refe.service";
import {Subscription} from "rxjs";
import {UserService} from "@app/user/user.service";
import {User} from "@app/user/user.model";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {includes} from "lodash";


@Component({
    selector: 'sai-users-management',
    templateUrl: './users-management.component.html',
    styleUrls: ['./users-management.component.sass'],
})
export class UsersManagementComponent implements OnInit, OnDestroy {
    private userSubscription!: Subscription;
    public isLoadingUsers: boolean = false;
    public currentUser: User;
    public users: UserManagement[];
    public companyCodes: CompanyCode[];
    public userFilter = '';

    constructor(private userManagementService: UserManagementService,
                private router: Router,
                private refeService: RefeService,
                private userService: UserService,
                private translate: TranslateService) {
        this.users = [];
        this.currentUser = {} as User;
        this.companyCodes = [];
    }

    ngOnInit(): void {
        this.isLoadingUsers = true;

        this.userSubscription = this.userService.connectedUser.subscribe(user => this.currentUser = user);

        this.userManagementService.getAllUsers()
            .then((users: UserManagement[]) => {
                this.users = users;
            }).finally(() => this.isLoadingUsers = false);

        // Récupération des codes compagnies
        this.refeService.getAllCompanyCodes().subscribe((companyCodes) => {
            this.companyCodes = companyCodes;
        });
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    public goToUserCreation() {
        this.router.navigate(['/management/user']).then();
    }

    public findUsers(): UserManagement[] {
        const filter = this.userFilter.toLowerCase();

        return this.users.filter(user =>
            this.userFilter === '' ||
            includes(user.email?.toLowerCase(), filter) ||
            user.firstname?.toLowerCase().startsWith(filter) ||
            user.lastname?.toLowerCase().startsWith(filter) ||
            user.role?.toLowerCase().startsWith(filter) ||
            user.visibleCarriers?.some(carrier => carrier.toLowerCase().startsWith(filter)) ||
            user.companyCode?.toLowerCase().startsWith(filter)
        )
    }

    public goToUserDetail(user: UserManagement) {
        this.router.navigate(['/management/user', {id: user._id}]).then();
    }

    public getCorrespondingCompanyLabel(companyCode: string): string {
        return this.companyCodes.find(value => value.companyCode === companyCode)?.companyLabel;
    }

    /**
     * Check si l'utilisateur courant peut modifier l'utilisateur donné : regarde si le poids du role est supérieur ou égale
     * @param user L'utilisateur à modifier
     */
    public canUpdate(user: UserManagement) {
        return this.currentUser.roleWeight >= user.roleWeight;
    }

    public deactivateUser(user: UserManagement) {
        if (user.deactivated) {
            Swal.fire({
                title: this.translate.instant("management.user.table.confirm reactivation title"),
                text: this.translate.instant("management.user.table.confirm reactivation msg"),
                icon: "question",
                showCancelButton: true,
                confirmButtonText: this.translate.instant("management.user.table.reactivate")
            })
                .then(result => {
                    if (result.isConfirmed) {
                        this.userManagementService.reactivateUser(user)
                            .toPromise()
                            .then(reactivatedUser => this.replaceUserInList(reactivatedUser));
                    }
                });
        } else {
            Swal.fire({
                title: this.translate.instant("management.user.table.confirm deactivation title"),
                text: this.translate.instant("management.user.table.confirm deactivation msg"),
                icon: "question",
                showCancelButton: true,
                confirmButtonText: this.translate.instant("management.user.table.deactivate")
            })
                .then(result => {
                    if (result.isConfirmed) {
                        this.userManagementService.deactivateUser(user)
                            .toPromise()
                            .then(deactivatedUser => this.replaceUserInList(deactivatedUser));
                    }
                });
        }
    }

    public deleteUser(user: UserManagement) {
        Swal.fire({
            title: this.translate.instant("management.user.table.confirm deletion title"),
            text: this.translate.instant("management.user.table.confirm deletion msg"),
            icon: "question",
            showCancelButton: true,
            confirmButtonText: this.translate.instant("management.user.table.delete")
        })
            .then(result => {
                if (result.isConfirmed) {
                    this.userManagementService.deleteUser(user)
                        .toPromise()
                        .then(() => this.removeUserFromList(user));
                }
            });
    }

    private replaceUserInList(user: UserManagement) {
        const index = this.users.findIndex(value => value._id === user._id);
        if (index !== -1) {
            this.users[index] = user;
        }
    }

    /**
     * Fait suite à une suppression
     * @param user L'utilisateur à retirer
     * @private
     */
    private removeUserFromList(user: UserManagement) {
        const index = this.users.findIndex(value => value._id === user._id);
        if (index !== -1) {
            this.users.splice(index, 1);
        }
    }
}
