import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StorageService} from './services/storage.service';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {LoadingService} from './services/loading.service';
import {NgxLoadingModule} from 'ngx-loading';
import {LoadingInterceptor} from './interceptors/loading.interceptor';
import {MontherComponent} from './components/monther/monther.component';
import {LoadingComponent} from './components/loading/loading.component';
import {PageTitleComponent} from './components/page-title/page-title.component';
import {NgbDropdownModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {HasRightDirective} from './directives/has-right/has-right.directive';
import {ActionStatusComponent} from './components/action-status/action-status.component';
import {ActionDeadlineComponent} from './components/action-deadline/action-deadline.component';
import {ActionToDoComponent} from './components/action-to-do/action-to-do.component';
import {ActionResponseComponent} from './components/action-response/action-response.component';
import {TableR1Component} from './components/table-r1/table-r1.component';
import {TableR3Component} from './components/table-r3/table-r3.component';
import {EnvironmentService} from './services/environment.service';
import {CookieService} from './services/cookie.service';
import {ActionRecapService} from './services/action-recap.service';
import {BadgeComponent} from './components/badge/badge.component';
import {AlertComponent} from './components/alert/alert.component';
import {RefeService} from './services/refe.service';
import {ActionAccountableComponent} from './components/action-accountable/action-accountable.component';
import {LoadingCircleComponent} from './components/loading-circle/loading-circle.component';
import {MontherService} from './services/monther.service';
import { ManagementTitleComponent } from '@shared/components/management-title/management-title.component';

@NgModule({
    declarations: [
        MontherComponent,
        LoadingComponent,
        PageTitleComponent,
        HasRightDirective,
        ActionStatusComponent,
        ActionDeadlineComponent,
        ActionToDoComponent,
        ActionResponseComponent,
        TableR1Component,
        TableR3Component,
        BadgeComponent,
        AlertComponent,
        ActionAccountableComponent,
        LoadingCircleComponent,
        ManagementTitleComponent,
    ],
    imports: [CommonModule, TranslateModule, BsDropdownModule, NgxLoadingModule, NgbDropdownModule, NgbPopoverModule],
    exports: [
        MontherComponent,
        LoadingComponent,
        PageTitleComponent,
        HasRightDirective,
        ActionStatusComponent,
        ActionDeadlineComponent,
        ActionToDoComponent,
        ActionResponseComponent,
        TableR1Component,
        TableR3Component,
        AlertComponent,
        BadgeComponent,
        ActionAccountableComponent,
        LoadingCircleComponent,
        ManagementTitleComponent,
    ],
    providers: [
        LoadingInterceptor,
        StorageService,
        LoadingService,
        EnvironmentService,
        CookieService,
        ActionRecapService,
        RefeService,
        MontherService
    ],
})
export class SharedModule {
}
