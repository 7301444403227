<div [ngSwitch]="action.statut">
    <div *ngSwitchCase="actionStatus.inProgress">
        <ng-container *ngIf="showAddResponseButton">
            <button
                type="button"
                (click)="openAddResponse()"
                class="btn btn-outline-primary btn-sm"
            >
                {{ "actions.table.add response" | translate }}
            </button>
        </ng-container
        >
    </div>

    <div *ngSwitchCase="actionStatus.done">
        <span
            *ngIf="action.reponse && action.reponse.length > 100; else fullResponseBlock"
            class="text-except"
            ngbPopover=" {{ action.reponse }}"
            [openDelay]="100"
            [autoClose]="'outside'"
            popoverClass="popover-info shadow"
        ><i class="fas fa-info fa-fw text-info"></i>{{ action.reponse }}</span
        >
        <ng-template #fullResponseBlock>{{ action.reponse }}</ng-template>
    </div>

    <div *ngSwitchCase="actionStatus.cancelled" class="text-muted">
        <i class="fas fa-fw fa-exclamation"></i>
        {{ "actions.table.cancelled action" | translate }}
    </div>
</div>
