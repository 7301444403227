import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ChartType} from 'chart.js';
import {MultiDataSet, Label, SingleDataSet} from 'ng2-charts';

@Component({
    selector: 'sai-doughnut',
    templateUrl: './doughnut.component.html',
    styleUrls: ['./doughnut.component.sass']
})
export class DoughnutComponent implements OnChanges {
    @Input() sumCriticalityPattern: number[];
    @Input() totalPattern: number;

    public criticalityLegend: string[];

    // Doughnut
    public doughnutChartData: SingleDataSet[];
    public doughnutChartLabels: Label[];
    public options: any = {
        legend: {display: false}
    };
    public chartColors: Array<any> = [
        {
            backgroundColor: ['#90be6d', '#f77f00', '#f94144']
        }
    ];
    public doughnutChartType: ChartType = 'doughnut';

    constructor(public translate: TranslateService) {
        this.translate.get('criticality').subscribe((criticality: any) => {
            this.criticalityLegend = criticality;
        });

    }

    ngOnChanges(): void {
        this.doughnutChartData = [this.sumCriticalityPattern];
        this.percentageCriticality();
        this.doughnutChartLabels = ['Faible', 'Moyen', 'Elevée'];
    }

    // events
    public chartClicked({event, active}: { event: MouseEvent, active: {}[] }): void {
    }

    public chartHovered({event, active}: { event: MouseEvent, active: {}[] }): void {

    }

    public percentageCriticality() {
        const sumOfCriticalityPattern = this.totalPattern;
        const criticalityPattern = this.sumCriticalityPattern;
        const percentageLowCriticality = (criticalityPattern[0] / sumOfCriticalityPattern) * 100;
        const percentageMediumCriticality = (criticalityPattern[1] / sumOfCriticalityPattern) * 100;
        const percentageHighCriticality = (criticalityPattern[2] / sumOfCriticalityPattern) * 100;
        const percentageCriticalityPattern = [percentageLowCriticality, percentageMediumCriticality, percentageHighCriticality];

        //  this.criticalityLegend = percentageCriticalityPattern.map((pattern) => pattern.toFixed(1) + ' %');


    }
}
