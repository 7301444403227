import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RefeCarrier} from 'src/app/shared/referential/refe-carrier.model';
import {RefeService} from 'src/app/shared/services/refe.service';
import {UserManagement} from '../model/user-management.model';
import {UserManagementService} from '../services/user-management.service';
import {CompanyCode} from "@shared/referential/refe-company-code.model";
import {RoleDto} from "@shared/models/RoleDto";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'sai-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.sass'],
})
export class UserDetailComponent implements OnInit {
    public isCreationMode: boolean;
    public user: UserManagement;
    public carriers: RefeCarrier[];
    public companyCodes: CompanyCode[];
    public roles: RoleDto[];
    public isSavingUser = false;
    public isLoadingUser = false;

    constructor(
        private userManagementService: UserManagementService,
        private refeService: RefeService,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {
        this.user = {} as UserManagement;
        this.isCreationMode = true;
        this.roles = [];
        this.companyCodes = [];
        this.carriers = [];
    }

    ngOnInit(): void {
        this.loadUser();

        // Récupération des carriers pour la sélection des accessibles
        this.refeService.getAllCarriers().subscribe((carriers) => {
            this.carriers = carriers;
        });

        // Récupération des codes compagnies
        this.refeService.getAllCompanyCodes().subscribe((companyCodes) => {
            this.companyCodes = companyCodes;
        });

        // Des rôles
        this.userManagementService.findAttributableRoles().subscribe((roles) => {
            this.roles = roles;
        });
    }

    loadUser() {
        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            // Mode édition : on charge l'utilisateur demandé via son email
            this.isLoadingUser = true;
            this.isCreationMode = false;
            this.userManagementService.getUserById(id)
                .toPromise()
                .then(user => this.user = user ? user : {} as UserManagement)
                .finally(() => this.isLoadingUser = false);
        }
    }

    private async isFormValid(): Promise<boolean> {
        let valid = true;

        if (!this.user.email) {
            Swal.fire(this.translate.instant('management.user.creation.email missing'), '', 'error').then();
            valid = false;
        } else if (!this.isAValidEmail(this.user.email)) {
            Swal.fire(this.translate.instant('management.user.creation.email format invalid'), '', 'error').then();
            valid = false;
        } else if (!this.user.firstname) {
            Swal.fire(this.translate.instant('management.user.creation.first name missing'), '', 'error').then();
            valid = false;
        } else if (!this.user.lastname) {
            Swal.fire(this.translate.instant('management.user.creation.last name missing'), '', 'error').then();
            valid = false;
        } else if (this.isCreationMode && (await this.isEmailAlreadyUsed())) {
            Swal.fire(this.translate.instant('management.user.creation.email already in use'), '', 'error').then();
            valid = false;
        }

        return valid;
    }

    public isAValidEmail(emailToCheck: string): boolean {
        let regexp = new RegExp('^[a-zA-Z\\d._%+-]+@[a-zA-Z\\d.-]+\\.[a-zA-Z]{2,4}$');
        return regexp.test(emailToCheck);
    }

    private async isEmailAlreadyUsed(): Promise<boolean> {
        const user = await this.userManagementService.getUserByEmail(this.user?.email).toPromise();
        return !!user?._id;
    }

    public saveUser(): void {
        this.isFormValid()
            .then(isValid => {
                if (isValid) {
                    this.performSaveUser();
                }
            });
    }

    private performSaveUser() {
        this.isSavingUser = true;

        let savingUserPromise;
        if (this.isCreationMode) {
            savingUserPromise = this.userManagementService.createUser(this.user).toPromise();
        } else {
            savingUserPromise = this.userManagementService.updateUser(this.user).toPromise();
        }

        savingUserPromise
            .then((user) => {
                if (user) {
                    Swal.fire(
                        this.translate.instant("management.user.creation.user created title popin"),
                        this.translate.instant("management.user.creation.user created msg popin"), 'success')
                        .then(() => {
                            this.user = user;
                            this.isCreationMode = false;
                        });
                }
            })
            .finally(() => {
                this.isSavingUser = false;
            });
    }
}
