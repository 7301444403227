import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RefeCarrier} from '../referential/refe-carrier.model';
import {Endpoints} from './endpoint.constant';
import {CompanyCode} from "../referential/refe-company-code.model";

@Injectable()
export class RefeService {
    private endpoints = new Endpoints();

    constructor(private http: HttpClient) {
    }

    public getAllCarriers(): Observable<RefeCarrier[]> {
        return this.http.get<RefeCarrier[]>(this.endpoints.REFE_CARRIERS);
    }

    public getAllCompanyCodes(): Observable<CompanyCode[]> {
        return this.http.get<CompanyCode[]>(this.endpoints.REFE_COMPANY_CODES);
    }
}
