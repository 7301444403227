<button type="button" class="btn btn-outline-primary" [ngClass]="buttonCssClasses"
        (click)="openModalAddInvestigation()">
    {{ buttonLabel | translate }}
</button>

<ng-template #addInvestigation>
    <div class="modal-header">
        <h3 class="modal-title">{{ "recommendations.actions-detail.title.investigations" | translate }}</h3>
    </div>
    <div class="modal-body">
        <sai-actions-detail
            [anomaly]="anomaly"
            [showInvestigationList]="showInvestigationList"
            (addInvestigationCompleted)="addingInvestigationCompleted($event)">
        </sai-actions-detail>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="closeDialog()">
            {{ "recommendations.actions-detail.close" | translate }}
        </button>
    </div>
</ng-template>
