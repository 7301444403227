<div class="modal-body">
    <h4 class="modal-title text-center mb-3">{{ modalTitle }}</h4>

    <ng-container *ngIf="isCreationMode">
        <div class="alert alert-warning" role="alert">
            {{ "management.right.role.AlertRoleAboutCreation" | translate }}
        </div>
    </ng-container>

    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label class="col-form-label">{{ "management.right.role.Name" | translate }} <sup class="text-danger">*</sup></label>
                <input type="text" class="form-control" [(ngModel)]="role.name" [disabled]="!isCreationMode"/>
            </div>
            <div class="form-group">
                <label class="col-form-label">{{ "management.right.role.Weight" | translate }} <sup class="text-danger">*</sup></label>
                <input type="number" class="form-control" [(ngModel)]="role.weight" />
            </div>
        </div>
    </div>
</div>

<div class="modal-footer border-0">
    <button type="button" class="btn btn-success" (click)="saveModification()" [ngClass]="{ disabled: isSaving }">
        <i *ngIf="isSaving; else btnSave" class="fas fa-sync fa-spin"></i>
        <ng-template #btnSave>{{ "management.right.role.save role" | translate }}</ng-template>
    </button>
    <button type="button" class="btn btn-secondary" (click)="cancelModification()" [ngClass]="{ disabled: isSaving }">
        {{ "cancel" | translate }}
    </button>
</div>
