<sai-add-response-dialog
    #addResponseDialog
    (addResponseCompleted)="addingResponseCompleted($event)"
></sai-add-response-dialog>

<sai-anomaly-detail-dialog #anomalyDetailDialog></sai-anomaly-detail-dialog>

<sai-page-title title="{{ 'actions.title.monitoring' | translate }}"></sai-page-title>
<div *ngIf="overdueActions != 0">
    <sai-alert class="clickable" (click)="overdueAlertFilter()" [overdueActions]="overdueActions"></sai-alert>
</div>

<div class="container-fluid px-5">
    <div class="row">
        <div class="col">
            <sai-anomaly-filters [filterRights]="filterConfig"></sai-anomaly-filters>
        </div>
    </div>

    <sai-loading-circle [loading]="loading"></sai-loading-circle>

    <ng-container *ngIf="!loading">
        <ng-container *ngIf="filteredActionsMonitoring?.length > 0; else noDataBlock">
            <div class="row">
                <div class="col">
                    <table class="col table table-sticky">
                        <thead class="">
                        <tr class="text-left">
                            <th>
                                <p>{{ "actions.table.pattern" | translate }}</p>
                                <input
                                    class="form-control form-control-sm"
                                    [(ngModel)]="anomalyFilter"
                                    placeholder="{{ 'actions.table.filter' | translate }}"
                                />
                            </th>
                            <th>
                                <p>{{ "actions.table.description" | translate }}</p>
                                <input
                                    class="form-control form-control-sm"
                                    [(ngModel)]="actionFiler"
                                    placeholder="{{ 'actions.table.filter' | translate }}"
                                />
                            </th>
                            <th class="mw-8">
                                <p>{{ "actions.table.release date" | translate }}</p>
                                <sai-actions-date-filter
                                    (selectedDateUpdated)="initDateFilter($event)"
                                ></sai-actions-date-filter>
                            </th>
                            <th class="mw-8">
                                <p>{{ "actions.table.deadline" | translate }}</p>
                                <sai-actions-date-filter [dateFilterCode]="'deadline'"
                                                         (selectedDateUpdated)="deadlineFilter($event)"
                                ></sai-actions-date-filter>
                            </th>
                            <th *saiHasRight="'CAN_READ_SHIPPER_DATA'">
                                <p>{{ "actions.table.accountable" | translate }}</p>
                                <input
                                    class="form-control form-control-sm"
                                    [(ngModel)]="accountableFilter"
                                    placeholder="{{ 'actions.table.filter' | translate }}"
                                />
                            </th>
                            <th *saiHasRight="'CAN_READ_CARRIER_DATA'">
                                <p>{{ "actions.table.accountability" | translate }}</p>
                                <input
                                    class="form-control form-control-sm"
                                    [(ngModel)]="accountabilityFilter"
                                    placeholder="{{ 'actions.table.filter' | translate }}"
                                />
                            </th>

                            <th *saiHasRight="'CAN_READ_CARRIER_DATA'">
                                <p>{{ "actions.table.name" | translate }}</p>
                                <input
                                    class="form-control form-control-sm"
                                    [(ngModel)]="nameFilter"
                                    placeholder="{{ 'actions.table.filter' | translate }}"
                                />
                            </th>

                            <th>
                                <p>{{ "actions.table.status" | translate }}</p>
                                <select
                                    id="inputState"
                                    [(ngModel)]="statusFilter"
                                    class="custom-select custom-select-sm"
                                >
                                    <option value="{{ actionStatus.all }}">
                                        {{ "actions.status." + actionStatus.all | translate }}
                                    </option>
                                    <option value="{{ actionStatus.inProgress }}">
                                        {{ "actions.status." + actionStatus.inProgress | translate }}
                                    </option>
                                    <option value="{{ actionStatus.done }}">
                                        {{ "actions.status." + actionStatus.done | translate }}
                                    </option>
                                    <option value="{{ actionStatus.cancelled }}">
                                        {{ "actions.status." + actionStatus.cancelled | translate }}
                                    </option>
                                </select>
                            </th>
                            <th>
                                <p>{{ "actions.table.response" | translate }}</p>
                            </th>
                        </tr>
                        </thead>

                        <tbody *ngIf="filteredActionsMonitoring && filteredActionsMonitoring.length > 0">
                        <ng-container
                            *ngFor="
                                    let anomaly of filteredActionsMonitoring;
                                    let indexAnomaly = index;
                                    let even = even
                                "
                        >
                            <ng-container
                                *ngFor="
                                        let action of anomaly.investigations;
                                        let indexAction = index;
                                        let first = first
                                    "
                            >
                                <tr #row [ngClass]="{ 'row-even': even, 'row-odd': !even }">
                                    <!-- La première ligne c'est l'anomalie étendue sur un nombre de lignes égal au nombre d'actions -->
                                    <td *ngIf="first" [rowSpan]="anomaly.investigations.length" class="anomaly-td">
                                        <h5 class="mb-3 text-muted">
                                            <small>{{ anomaly.num_regle }} - {{ anomaly.anomalie_desc }}</small>
                                        </h5>
                                        <table class="table table-sm anomaly-table no-hover mb-3">
                                            <tbody>
                                            <!-- Les caractéristiques propres à l'anomalie -->
                                            <ng-container
                                                *ngFor="let characteristic of anomaly.anomalie_characteristics"
                                            >
                                                <tr>
                                                    <td>
                                                        {{
                                                        "anomaly.label." + characteristic.label | translate
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                        characteristic.percent
                                                            ? (characteristic.value | percent: "1.0-2")
                                                            : characteristic.value
                                                        }}
                                                    </td>
                                                </tr>
                                            </ng-container>

                                            <!-- Enjeu global -->
                                            <tr class="font-weight-bold">
                                                <td>{{ "anomaly.label.enjeu_global" | translate }}</td>
                                                <td>
                                                    {{ anomaly.latest_anomaly.enjeu_global | percent: "1.0-2" }}
                                                </td>
                                            </tr>
                                            <!-- Enjeu transporteur -->
                                            <tr class="font-weight-bold">
                                                <td>{{ "anomaly.label.enjeu_transporteur" | translate }}</td>
                                                <td>
                                                    {{
                                                    anomaly.latest_anomaly.enjeu_transporteur
                                                        | percent: "1.0-2"
                                                    }}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div class="d-flex flex-column">
                                            <!-- Les récurrences -->
                                            <button
                                                type="button"
                                                class="btn btn-outline-info mb-3"
                                                (click)="openAnomalyDetailFor(anomaly)"
                                            >
                                                {{ "actions.table.see recurrence" | translate }}
                                            </button>
                                            <!-- Nouvelle action -->
                                            <sai-add-investigation
                                                [buttonLabel]="'actions.table.add action'"
                                                [buttonCssClasses]="'btn-block'"
                                                [anomaly]="anomaly"
                                                [showInvestigationList]="false"
                                                (addInvestigationCompleted)="addingInvestigationCompleted($event)"
                                            ></sai-add-investigation>
                                        </div>
                                    </td>
                                    <td [ngClass]="{ 'overdue-alert': overdueCheck(action) }">
                                        <sai-action-to-do [action]="action"></sai-action-to-do>
                                    </td>
                                    <td [ngClass]="{ 'overdue-alert': overdueCheck(action) }">
                                        {{ action.date_lancement | date: "mediumDate" }}
                                    </td>
                                    <td [ngClass]="{ 'overdue-alert': overdueCheck(action) }">
                                        <sai-action-deadline [action]="action"></sai-action-deadline>
                                    </td>
                                    <!-- Responsable -->
                                    <td
                                        *saiHasRight="'CAN_READ_SHIPPER_DATA'"
                                        [ngClass]="{ 'overdue-alert': overdueCheck(action) }"
                                    >
                                        <sai-action-accountable
                                            [accountable]="action.responsable"
                                        ></sai-action-accountable>
                                    </td>
                                    <!-- Responsabilité -->
                                    <td
                                        *saiHasRight="'CAN_READ_CARRIER_DATA'"
                                        [ngClass]="{ 'overdue-alert': overdueCheck(action) }"
                                    >
                                        <select
                                            [ngClass]="{
                                                    emptyField:
                                                        !isKnownAccountable(action.responsable_transporteur_service) ||
                                                        action.responsable_transporteur_service == '' ||
                                                        action.responsable_transporteur_service == null
                                                }"
                                            class="form-control edit dropdown-container"
                                            [(ngModel)]="action.responsable_transporteur_service"
                                            (change)="updateResponsable(action)"
                                        >
                                            <option value="QA">
                                                {{
                                                "recommendations.actions-detail.table.accountability.QA"
                                                    | translate
                                                }}
                                            </option>
                                            <option value="transportPlanManager">
                                                {{
                                                "recommendations.actions-detail.table.accountability.transportPlanManager"
                                                    | translate
                                                }}
                                            </option>
                                            <option value="localDirector">
                                                {{
                                                "recommendations.actions-detail.table.accountability.localDirector"
                                                    | translate
                                                }}
                                            </option>
                                        </select>
                                    </td>
                                    <!-- Responsabilité Nom -->
                                    <td
                                        *saiHasRight="'CAN_READ_CARRIER_DATA'"
                                        [ngClass]="{ 'overdue-alert': overdueCheck(action) }"
                                        class="text-center"
                                    >
                                        <input
                                            [ngClass]="{
                                                    emptyField:
                                                        action.responsable_transporteur_nom == '' ||
                                                        action.responsable_transporteur_nom == null
                                                }"
                                            type="text"
                                            class="form-control edit"
                                            [(ngModel)]="action.responsable_transporteur_nom"
                                            (blur)="updateResponsable(action)"
                                        />
                                    </td>
                                    <td [ngClass]="{ 'overdue-alert': overdueCheck(action) }">
                                        <sai-action-status [action]="action"></sai-action-status>
                                    </td>
                                    <!-- Réponse -->
                                    <td [ngClass]="{ 'overdue-alert': overdueCheck(action) }">
                                        <sai-action-response
                                            [action]="action"
                                            [showAddResponseButton]="true"
                                            (openAddResponseDialog)="openAddResponseDialogFor($event)"
                                        ></sai-action-response>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>
        <ng-template #noDataBlock>
            <div class="col alert alert-info">{{ "alert.no_actions_available" | translate }}</div>
        </ng-template>
    </ng-container>
</div>
