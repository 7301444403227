import {Component, OnDestroy, OnInit} from '@angular/core';
import {from, Subscription} from 'rxjs';
import {AnomalyService} from 'src/app/anomalies/services/anomaly.service';
import {UserService} from 'src/app/user/user.service';
import {Period} from '../../shared/components/monther/period.model';
import {AnomalySummary} from '../model/anomalySummary.model';
import {User} from "@app/user/user.model";

@Component({
    selector: 'sai-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit, OnDestroy {
    public personDisplayName: User;
    private userSubscription!: Subscription;
    public anomalyMonthlySummary: AnomalySummary[];
    public sumCriticalityPattern: number[];
    public patternTotal: number;
    public loading: boolean;

    constructor(private userService: UserService, private anomaly: AnomalyService) {
        this.anomalyMonthlySummary = [];
    }

    ngOnInit(): void {
        this.userSubscription = this.userService.connectedUser.subscribe(user => {
            this.personDisplayName = user;
        });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
    }

    public updatePeriod(period: Period) {
        this.getAnomalyMonthlySummary(period);
    }

    getAnomalyMonthlySummary(period: Period) {
        this.loading = true;
        this.anomaly.getAnomalyMonthlySummary(period).then((anomalyMonthlySummary) => {
            this.anomalyMonthlySummary = anomalyMonthlySummary.sort((a, b) => b.enjeu_global - a.enjeu_global);
            this.sumOfCriticalPattern(this.anomalyMonthlySummary);
            this.totalNumberOfCriticalPattern(this.anomalyMonthlySummary);
            this.loading = false;
        });
    }

    private sumOfCriticalPattern(summary: AnomalySummary[]) {
        let sumLowCriticality = 0;
        let sumMediumCriticality = 0;
        let sumHighCriticality = 0;
        // Sum for patterns with Low criticality
        summary.forEach((lowCriticality) => (sumLowCriticality += lowCriticality.nbLowCriticalPattern));

        // Sum for patterns with Medium criticality
        summary.forEach((mediumCriticality) => (sumMediumCriticality += mediumCriticality.nbMediumCriticalPattern));

        // Sum for patterns with High criticality
        summary.forEach((highCriticality) => (sumHighCriticality += highCriticality.nbHighCriticalPattern));

        this.sumCriticalityPattern = [sumLowCriticality, sumMediumCriticality, sumHighCriticality];
    }

    private totalNumberOfCriticalPattern(summary: AnomalySummary[]) {
        let total = 0;
        summary.forEach((totalPattern) => (total += totalPattern.nbTotalPattern));

        this.patternTotal = total;
    }
}
