import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RecommendationsSelectionComponent} from './recommendations-selection/reco-selection.component';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {RecommendationsService} from './services/recommendations.service';
import {RecommendationsVisualizationComponent} from './recommendations-visualization/reco-visu.component';
import {PatternService} from './services/pattern.service';
import {ActionsDetailComponent} from './actions-detail/actions-detail.component';
import {AnomalyService} from './services/anomaly.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {UserModule} from '../user/user.module';
import {AddInvestigationComponent} from './add-investigation/add-investigation.component';
import {SolderAnomalieComponent} from './solder-anomalie/solder-anomalie.component';
import {EventsComponent} from './events/events.component';
import {FiltersModule} from '../filters/filters.module';
import {KnownAccountableService} from './services/known-accountable.service';
import {LexiconComponent} from './lexicon/lexicon.component';
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {ViewShippingNumbersComponent} from "./view-shipping-numbers/view-shipping-numbers.component";


@NgModule({
    declarations: [
        RecommendationsSelectionComponent,
        RecommendationsVisualizationComponent,
        ActionsDetailComponent,
        AddInvestigationComponent,
        SolderAnomalieComponent,
        EventsComponent,
        LexiconComponent,
        ViewShippingNumbersComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        TranslateModule,
        SharedModule,
        FormsModule,
        NgbDatepickerModule,
        UserModule,
        ReactiveFormsModule,
        FiltersModule,
        SweetAlert2Module,
    ],
    exports: [AddInvestigationComponent, ViewShippingNumbersComponent],
    providers: [RecommendationsService, PatternService, AnomalyService, KnownAccountableService],
})
export class AnomaliesModule {
}
