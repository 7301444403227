export class ActionReport {
    nbTotalInvestigations: number;
    nbDoneInvestigations: number;
    nbCancelledInvestigations: number;
    nbOverdueInvestigations: number;

    constructor() {
        this.nbTotalInvestigations = 0;
        this.nbDoneInvestigations = 0;
        this.nbCancelledInvestigations = 0;
        this.nbOverdueInvestigations = 0;
    }
}
