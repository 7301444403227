import {Component, Input, OnInit} from '@angular/core';
import {th} from 'date-fns/locale';
import {AnomalyFilters} from 'src/app/filters/model/anomaly-filters.model';
import {AnomalyFilterService} from 'src/app/filters/services/anomaly-filter.service';
import {Period} from 'src/app/shared/components/monther/period.model';
import {Event} from '../model/event.model';
import {FilterConfig} from '../model/filter-config.model';
import {EventsService} from '../services/events.service';
import {MontherService} from '../../shared/services/monther.service';

@Component({
    selector: 'sai-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.sass'],
})
export class EventsComponent implements OnInit {
    public filterRights: FilterConfig;
    public eventR1: Event[];
    public eventR3: Event[];
    public filterSelected: boolean;
    public selectedFilter: string[];
    public selectedFilterName: string;
    public selectedPeriod: Period;
    public loadingR1: boolean;
    public loadingR3: boolean;

    constructor(
        private eventsService: EventsService,
        private filterService: AnomalyFilterService,
        private montherService: MontherService
    ) {
        this.filterRights = new FilterConfig();
        this.filterSelected = false;
        this.eventR1 = [];
        this.eventR3 = [];
    }

    ngOnInit(): void {
        this.filterRights.departureSite = true;
        this.selectedPeriod = this.montherService.getSelectedPeriod();

        this.filterService.anomalyFilters.subscribe((filter) => {
            this.checkSelectedFilter(filter);
            this.getEvents();
        });
    }

    public updatePeriod(period: Period) {
        this.selectedPeriod = period;
        this.getEvents();
    }

    getEventR1() {
        this.loadingR1 = true;
        this.eventsService
            .getEventsR1(this.selectedPeriod, this.selectedFilterName)
            .then((events) => {
                this.eventR1 = events;
            })
            .finally(() => (this.loadingR1 = false));
    }

    getEventR3() {
        this.loadingR3 = true;
        this.eventsService
            .getEventsR3(this.selectedPeriod, this.selectedFilterName)
            .then((events) => {
                this.eventR3 = events;
            })
            .finally(() => (this.loadingR3 = false));
    }

    checkSelectedFilter(filters: AnomalyFilters) {
        this.selectedFilter = filters.departureSiteFilters
            .filter((departureSite) => departureSite.selected)
            .map((departureSite) => departureSite.value);

        this.selectedFilterName = this.selectedFilter[0];

        if (this.selectedFilter.length > 0) {
            this.filterSelected = true;
        } else {
            this.filterSelected = false;
        }
    }

    getEvents() {
        if (this.selectedFilterName != null) {
            this.getEventR1();
            this.getEventR3();
        }
    }

    public isLoading(): boolean {
        return this.loadingR1 || this.loadingR3;
    }
}
