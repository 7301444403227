import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {th} from 'date-fns/locale';
import {PermanentFilter} from 'src/app/filters/model/permanent-filter.model';
import {ActionReport} from '../../actions/model/action-report.model';
import {Endpoints} from './endpoint.constant';
import {Investigation} from '../../anomalies/model/investigation.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {AnomalyFilterService} from 'src/app/filters/services/anomaly-filter.service';
import {ActionStatus} from 'src/app/actions/model/action-status.constant';

@Injectable()
export class ActionRecapService {
    private endpoints: Endpoints;
    public updatedValue: number;
    private actionReportSource: BehaviorSubject<ActionReport>;
    public actionReport: Observable<ActionReport>;
    public actionStatus: ActionStatus;


    constructor(public http: HttpClient, private filterService: AnomalyFilterService) {
        this.endpoints = new Endpoints();
        this.actionStatus = new ActionStatus();
        this.actionReportSource = new BehaviorSubject<ActionReport>(new ActionReport());
        this.filterService.anomalyFilters.subscribe(async (anomalyFilters) => {
            const permanentFilters = this.filterService.buildPermanentFiltersFromAnomalyFilters(anomalyFilters);
            this.actionReportSource.next(await this.getActionReportFor(permanentFilters));
            this.actionReport = this.actionReportSource;
        });
        this.actionReport = this.actionReportSource.asObservable();
    }

    public completedOverdueActions(actionCompleted: Investigation) {
        if (actionCompleted.statut === this.actionStatus.inProgress) {
            let action: ActionReport;
            action = this.actionReportSource.getValue();
            action.nbOverdueInvestigations--;
            this.actionReportSource.next(action);

        }
    }

    // POST request is used to get permanentFilter (in the request body) since it is not possible with GET
    public getActionReportFor(permanentFilter: PermanentFilter): Promise<ActionReport> {
        return this.http.post<ActionReport>(this.endpoints.REPORT_ACTIONS, permanentFilter).toPromise();
    }

    public addResponseToInvestigation(investigation: Investigation): Promise<Investigation> {
        return this.http.put<Investigation>(this.endpoints.ACTION_ADD_RESPONSE, investigation).toPromise();
    }
}
