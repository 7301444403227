import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Endpoints} from 'src/app/shared/services/endpoint.constant';
import {Event} from '../model/event.model';
import {Period} from 'src/app/shared/components/monther/period.model';
import {AnomalyFilters} from 'src/app/filters/model/anomaly-filters.model';

@Injectable({
    providedIn: 'root'
})
export class EventsService {

    constructor(public http: HttpClient) {
    }

    endpoints = new Endpoints();

    public getEventsR1(period: Period, departureSite: string): Promise<Event[]> {
        const url = `${this.endpoints.EVENTS_R1}/${departureSite}/${period.year}/${period.month}`;
        return this.http.get<Event[]>(url).toPromise();
    }

    public getEventsR3(period: Period, departureSite: string): Promise<Event[]> {
        const url = `${this.endpoints.EVENTS_R3}/${departureSite}/${period.year}/${period.month}`;
        return this.http.get<Event[]>(url).toPromise();
    }

}
