<div class="row">
    <h2 class="col sub-title d-flex">
        <small class="mr-auto">{{ "events.title.labelR1" | translate }}</small>
        <!-- <span>R1</span> -->
    </h2>
</div>
<table class="col table table-hover mb-0">
    <thead class="text-center">
    <tr>
        <th colspan="8">
            {{ "recommendations.table.anomaly caracteristic" | translate }}
        </th>
        <th colspan="3" class="bg-secondary text-white">
            {{ "recommendations.table.external caracteristics" | translate }}
        </th>
    </tr>
    <tr>
        <th>{{ "events.tableR1.date_de_depart" | translate }}</th>
        <th>{{ "events.tableR1.site_de_depart_analyse" | translate }}</th>
        <th>{{ "events.tableR1.vigilance" | translate }}</th>
        <th>{{ "events.tableR1.exp_en_retard" | translate }}</th>
        <th>{{ "events.tableR1.exp_a_lheure" | translate }}</th>
        <th>{{ "events.tableR1.taux_retard" | translate }}</th>
        <th>{{ "events.tableR1.enjeu_global" | translate }}</th>
        <th>{{ "events.tableR1.jour_de_depart" | translate }}</th>
        <th class="bg-secondary text-white">{{ "events.tableR1.exp_en_retard_hors" | translate }}</th>
        <th class="bg-secondary text-white">{{ "events.tableR1.exp_a_lheure_hors" | translate }}</th>
        <th class="bg-secondary text-white">{{ "events.tableR1.taux_retard_hors" | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr class="text-center" *ngFor="let event of eventR1">
        <td>{{ event.date_de_depart }}</td>
        <td>{{ event.site_de_depart_analyse }}</td>
        <td>{{ event.meteo_vigilance_color_depart }}</td>
        <td>{{ event.exp_en_retard }}</td>
        <td>{{ event.exp_a_lheure }}</td>
        <td>{{ event.taux_retard | percent: "1.0-2"  }}</td>
        <td>{{ event.enjeu_global | percent: "1.0-2" }}</td>
        <td>{{ event.jour_de_depart }}</td>
        <td>{{ event.exp_en_retard_hors }}</td>
        <td>{{ event.exp_a_lheure_hors }}</td>
        <td>{{ event.taux_retard_hors | percent: "1.0-2"}}</td>
    </tr>
    <tr></tr>
    <tr></tr>
    </tbody>
</table>
