import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'sai-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.sass']
})
export class NavComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
