import {Injectable} from '@angular/core';
import {Anomaly} from '../model/anomaly.model';
import {Investigation} from '../model/investigation.model';
import {Endpoints} from 'src/app/shared/services/endpoint.constant';
import {HttpClient} from '@angular/common/http';
import {Action} from '../model/action.model';
import {orderBy} from 'lodash';
import {ShippingNumber} from '../model/shipping-number.model';
import {from, Observable} from 'rxjs';
import {SoldeDeCause} from '../model/solde-de-cause.model';
import {AnomalyToSolde} from '../model/anomaly-to-solde.model';
import {AnomalySummary} from 'src/app/home/model/anomalySummary.model';
import {Period} from 'src/app/shared/components/monther/period.model';

@Injectable()
export class AnomalyService {
    constructor(public http: HttpClient) {
    }

    endpoints = new Endpoints();

    public getInvestigationsFor(anomaly: Anomaly): Promise<Investigation[]> {
        const encodedPattern = encodeURIComponent(anomaly.anomalie_pattern);
        return this.http
            .get<Investigation[]>(this.endpoints.INVESTIGATIONS_FOR_PATTERN + '/' + encodedPattern)
            .toPromise();
    }

    public async getPossibleActionsFor(anomaly: Anomaly): Promise<Action[]> {
        const encodedNumRegle = encodeURIComponent(anomaly.num_regle);
        return this.http
            .get<Action[]>(this.endpoints.ACTIONS_FOR_REGLE + '/' + encodedNumRegle)
            .toPromise()
            .then((actions) => {
                return orderBy(actions, (action) => action.actions_ordre);
            });
    }

    public addInvestigation(investigation: Investigation): Promise<Investigation> {
        return this.http.post<Investigation>(this.endpoints.INVESTIGATIONS, investigation).toPromise();
    }

    public findShippingNumbersFor(anomaly: Anomaly): Observable<ShippingNumber[]> {
        const url = `${this.endpoints.ANOMALY_SHIPPING_NUMBER}/${anomaly.idanomalies}/${anomaly.num_regle}`;
        return this.http.get<ShippingNumber[]>(url);
    }

    public getSoldeDeCauses(): Promise<SoldeDeCause[]> {
        const url = `${this.endpoints.ANOMALY_SOLDE_DE_CAUSE}`;
        return this.http.get<SoldeDeCause[]>(url).toPromise();
    }

    public solderAnomaly(anomalyToSolde: AnomalyToSolde): Promise<void> {
        const url = this.endpoints.SOLDER_ANOMALY;
        return this.http.put<void>(url, anomalyToSolde).toPromise();
    }

    public getAnomalyMonthlySummary(period: Period): Promise<AnomalySummary[]> {
        const url = `${this.endpoints.ANOMALY_MONTHLY_SUMMARY}/${period.year}/${period.month}`;
        return this.http.get<AnomalySummary[]>(url).toPromise();
    }
}
