import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'sai-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.sass']
})
export class LegendComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
