import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'sai-page-title',
    template: `<div class="container-fluid px-5">
        <div class="row">
            <h1 class="col page-title">{{ title }}</h1>
        </div>
    </div>`
})
export class PageTitleComponent {
    @Input() title: string;

    constructor() {
    }
}
