<button
    type="button"
    data-toggle="modal"
    data-target="#solderAnomalie"
    class="btn btn-outline-danger"
    (click)="openModalSolderAnomalie()"
>
    {{ "recommendations.table.solder" | translate }}
</button>
<ng-template #modalSolderAnomalie>
    <div id="solderAnomalie" data-backdrop="static">
        <form (ngSubmit)="updateSolderAnomaly()">
            <div class="modal-header">
                <h4 class="modal-title">{{ "recommendations.solder-anomalie.title" | translate }}</h4>

            </div>
            <div class="modal-body">
                <label>Causes:</label>
                <select class="custom-select" [(ngModel)]="causeDeSolde" name="cause" ngModel>
                    <ng-container *ngFor="let soldeDecause of soldeDeCauses">
                        <option [ngValue]="soldeDecause">{{ soldeDecause.cause }}</option>
                    </ng-container>
                </select>
                <div class="mt-3" *ngIf="causeDeSolde.idanomalie_solde_cause_list === 3" ngModel>
                    <textarea class="form-control" [(ngModel)]="autreCause"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-danger">Solder</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="closeModalSolderAnomalie()">
                    Annuler
                </button>
            </div>
        </form>
    </div>
</ng-template>
