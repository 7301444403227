import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from 'src/app/user/user.model';
import {UserService} from 'src/app/user/user.service';
import {Subscription} from "rxjs";

@Component({
    selector: 'sai-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    private userSubscription!: Subscription;
    public user: User;

    constructor(public userService: UserService) {
    }

    ngOnInit(): void {
        this.userSubscription = this.userService.connectedUser.subscribe(user => {
            this.user = user
        });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
    }

    logout() {
        document.location.href = this.userService.logoutAndGetRedirectionUrl();
    }
}
