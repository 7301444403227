import {InitDate} from '../actions-date-filter/init-date.model';

export class ActionsMonitoringFilter {
    anomaly: string;
    action: string;
    accountable: string;
    status: string;
    initDate: InitDate;
    deadline: InitDate;
    accountability: string;
    name: string;

    constructor() {
        this.initDate = {} as InitDate;
        this.deadline = {} as InitDate;
    }
}
