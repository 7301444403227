import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class LoadingService {

    _loading: Subject<boolean> = new Subject();

    constructor() {
    }

    startLoading() {
        this._loading.next(true);
    }

    stopLoading() {
        this._loading.next(false);
    }
}
