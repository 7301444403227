<sai-page-title title="{{ 'recommendations.title.visualization' | translate }}"></sai-page-title>
<sai-monther (periodSelected)="updatePeriod($event)"></sai-monther>

<div class="container-fluid px-5 mt-2">
    <div class="row">
        <div class="col">
            <sai-anomaly-filters [filterRights]="filterConfig"></sai-anomaly-filters>
        </div>
    </div>

    <sai-loading-circle [loading]="loading"></sai-loading-circle>

    <ng-container *ngIf="!loading">
        <ng-container *ngFor="let pattern of patternList">
            <ng-container *ngIf="pattern?.anomalies.length > 0">
                <div class="mt-4">
                    <div class="row">
                        <h2 class="col sub-title d-flex">
                            <small class="mr-auto">{{ "regles." + pattern.regle.numRegle | translate }}</small>
                        </h2>
                    </div>
                    <div class="row mb-5">
                        <div class="col table-responsive">
                            <table class="col table table-hover reco-table breakable-word">
                                <thead>
                                <tr class="text-center">
                                    <th id="caract" [colSpan]="pattern.regle.columns.length - 3">
                                        {{ "recommendations.table.anomaly caracteristic" | translate }}
                                    </th>
                                    <th id="classic" colspan="3"
                                        class="bg-secondary text-white border-left border-right">
                                        {{ "recommendations.table.external caracteristics" | translate }}
                                    </th>
                                    <th id="actions"></th>
                                </tr>
                                <tr>
                                    <ng-container *ngFor="let column of pattern.regle.columns">
                                        <th
                                            (click)="sortListBy(column, pattern)"
                                            [ngClass]="column.theadClasses"
                                            class="clickable"
                                        >
                                            <div class="align-header">
                                                {{ "anomaly.label." + column.designation | translate }}
                                                <i
                                                    [ngClass]="{
                                                            'fa-sort':
                                                                docSortObj[pattern.regle.numRegle][
                                                                    column.designation
                                                                ] == -1,
                                                            'fa-sort-up':
                                                                docSortObj[pattern.regle.numRegle][column.designation] %
                                                                    2 ==
                                                                1,
                                                            'fa-sort-down':
                                                                docSortObj[pattern.regle.numRegle][column.designation] %
                                                                    2 ==
                                                                0
                                                        }"
                                                    class="fa ml-2 align-item"
                                                ></i>
                                            </div>
                                        </th>
                                    </ng-container>
                                    <th class="text-center align-text-top">
                                        {{ "recommendations.table.actions" | translate }}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let anomaly of pattern.anomalies">
                                    <tr [ngClass]="{ 'table-active': currentRow.loading }" #currentRow>
                                        <ng-container *ngFor="let column of pattern.regle.columns">
                                            <td [ngClass]="getAnomalyCssClasses(column)">
                                                {{ getAnomalyValue(anomaly, column) }}
                                            </td>
                                        </ng-container>
                                        <td>
                                            <div class="d-flex flex-row flex-nowrap align-items-center">
                                                <sai-add-investigation
                                                    [buttonLabel]="'recommendations.table.agir'"
                                                    [anomaly]="anomaly"
                                                    [showInvestigationList]="true"
                                                ></sai-add-investigation>
                                                <sai-solder-anomalie
                                                    class="ml-3"
                                                    [anomaly]="anomaly"
                                                ></sai-solder-anomalie>
                                                <button class="btn btn-outline-info ml-3" [swal]="shippingNumber">
                                                    Afficher Nr.Exp
                                                </button>
                                                <swal #shippingNumber>
                                                    <sai-view-shipping-numbers
                                                        *swalPortal
                                                        [anomaly]="anomaly"
                                                    ></sai-view-shipping-numbers>
                                                </swal>
                                                <button
                                                    *saiHasRight="'CAN_WRITE_RECOMMENDATION'"
                                                    class="btn btn-warning ml-3"
                                                    (click)="
                                                            removeAnomalyFromRecommended(anomaly, pattern, currentRow)
                                                        "
                                                >
                                                    <i class="fas fa-eye-slash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="patternList?.length === 0">
            <div class="col alert alert-info">{{ "alert.no_recommendation_available" | translate }}</div>
        </ng-container>
    </ng-container>
</div>
