<div class="sub-title mt-3">
    <div class="text-center">
        <h2>Nombre de patterns</h2>
        <h2>{{ totalPattern }}</h2>
    </div>

    <div class="doughnut-size" style="display: block">
        <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType"
                [colors]="chartColors" [options]="options">
        </canvas>
    </div>
    <div class="text-center my-4">
        <h4>{{ 'home.taux_de_service' | translate }}</h4>
    </div>

    <div class="mx-3 my-4">
        <sai-legend></sai-legend>
    </div>
</div>
