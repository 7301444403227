<div class="filters-block">
    <ng-container *ngIf="filters">
        <ng-container *ngIf="filterRights.carrier">
            <ng-container *saiHasRight="'CAN_FILTER_CARRIER'">
                <sai-dropdown-filter
                    [filtersLabel]="'carrier'"
                    [filters]="filters.carrierFilters"
                    (filterSelected)="filterHasBeenSelected($event)"
                ></sai-dropdown-filter>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="filterRights.enseigne">
            <ng-container *saiHasRight="'CAN_FILTER_CLIENT'">
                <sai-dropdown-filter
                    [filtersLabel]="'enseigne'"
                    [filters]="filters.enseigneFilters"
                    (filterSelected)="filterHasBeenSelected($event)"
                ></sai-dropdown-filter>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="filterRights.departureSite">
            <sai-dropdown-filter
                [filtersLabel]="'departure site'"
                [filters]="filters.departureSiteFilters"
                (filterSelected)="filterHasBeenSelected($event)"
            ></sai-dropdown-filter>
        </ng-container>

        <ng-container *ngIf="filterRights.department">
            <sai-dropdown-filter
                [filtersLabel]="'department'"
                [filters]="filters.departmentFilters"
                (filterSelected)="filterHasBeenSelected($event)"
            ></sai-dropdown-filter>
        </ng-container>
    </ng-container>
</div>
