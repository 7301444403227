<sai-page-title title="{{ 'lexicon.title' | translate }}"></sai-page-title>
<div class="container-fluid px-5 mt-2">
    <div class="row">
        <div class="col">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr>
                    <td rowspan="12">{{ "lexicon.caract.label" | translate }}</td>
                    <td>{{ "lexicon.caract.statut.label" | translate }}</td>
                    <td>{{ "lexicon.caract.statut.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.transporteur.label" | translate }}</td>
                    <td>{{ "lexicon.caract.transporteur.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.site de départ.label" | translate }}</td>
                    <td>{{ "lexicon.caract.site de départ.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.dpt de destination.label" | translate }}</td>
                    <td>{{ "lexicon.caract.dpt de destination.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.exp. en retard.label" | translate }}</td>
                    <td>{{ "lexicon.caract.exp. en retard.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.taux de retard.label" | translate }}</td>
                    <td>{{ "lexicon.caract.taux de retard.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.enjeu global.label" | translate }}</td>
                    <td>{{ "lexicon.caract.enjeu global.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.enjeu transporteur.label" | translate }}</td>
                    <td>{{ "lexicon.caract.enjeu transporteur.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.ville.label" | translate }}</td>
                    <td>{{ "lexicon.caract.ville.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.enseigne.label" | translate }}</td>
                    <td>{{ "lexicon.caract.enseigne.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.client.label" | translate }}</td>
                    <td>{{ "lexicon.caract.client.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.caract.code client.label" | translate }}</td>
                    <td>{{ "lexicon.caract.code client.definition" | translate }}</td>
                </tr>
                <tr>
                    <td colspan="3">&nbsp;</td>
                </tr>
                <tr>
                    <td rowspan="3">{{ "lexicon.general.label" | translate }}</td>
                    <td>{{ "lexicon.general.exp. en retard.label" | translate }}</td>
                    <td>{{ "lexicon.general.exp. en retard.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.general.taux de retard.label" | translate }}</td>
                    <td>{{ "lexicon.general.taux de retard.definition" | translate }}</td>
                </tr>
                <tr>
                    <td>{{ "lexicon.general.différence.label" | translate }}</td>
                    <td>{{ "lexicon.general.différence.definition" | translate }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
