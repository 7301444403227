<div>
    <div>
        <div class="chart-container" style="display: block">
            <canvas baseChart
                    [datasets]="barChartData"
                    [options]="barChartOptions"
                    [chartType]="barChartType"
                    [legend]="barChartLegend"
                    [labels]="barChartLabels"
                    [colors]="barChartColors"
            >
            </canvas>
        </div>
    </div>
</div>
