import {Component, Input, OnInit} from '@angular/core';
import {isAfter} from 'date-fns';
import {ActionStatus} from 'src/app/actions/model/action-status.constant';
import {Investigation} from 'src/app/anomalies/model/investigation.model';

@Component({
    selector: 'sai-action-deadline',
    templateUrl: './action-deadline.component.html',
    styleUrls: ['./action-deadline.component.sass'],
})
export class ActionDeadlineComponent implements OnInit {
    @Input() action: Investigation;
    public actionStatus: ActionStatus;

    constructor() {
        this.actionStatus = new ActionStatus();
    }

    ngOnInit(): void {
    }

    getCssClassesForDeadline(action: Investigation) {
        if (action.statut === this.actionStatus.inProgress) {
            if (isAfter(Date.now(), Date.parse(action.date_deadline))) {
                return ['font-weight-bold', 'text-danger'];
            }
        }
        return [];
    }
}
