<ng-container *ngIf=" loadingShippingNumbers; else shippingNumbersBlock">
    <i class="fas fa-sync fa-spin"></i>
</ng-container>

<ng-template #shippingNumbersBlock>
    <ng-container *ngIf="anomaly.shippingNumbers && anomaly.shippingNumbers.length > 0; else noShipping">
    <span *ngFor="let shippingNumber of anomaly.shippingNumbers">
        {{ shippingNumber.livraisons_num_commandes }}
    </span>
    </ng-container>
    <ng-template #noShipping>
        <p>No shipping numbers available</p>
    </ng-template>
</ng-template>
