<div class="table-responsive">
    <table class="table table-hover table table-striped table-center">
        <thead>
        <tr class="text-center">
            <th scope="col"></th>
            <th
                *ngFor="let permission of permissions"
                [popover]="popoverActive"
                [popoverTitle]="currentLanguage === 'fr' ? permission.labelFr : permission.labelEn"
                [outsideClick]="true">

                <ng-template #popoverActive>
                    <span *ngIf="currentLanguage === 'fr'" [innerHTML]="permission.descriptionFr"></span>
                    <span *ngIf="currentLanguage === 'en'" [innerHTML]="permission.descriptionEn"></span>
                </ng-template>

                <span *ngIf="currentLanguage === 'fr'">{{ permission.labelFr }}</span>
                <span *ngIf="currentLanguage === 'en'">{{ permission.labelEn }}</span>
                <i class="fa fa-info-circle ml-2"></i>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let role of roles">
            <th scope="row">{{ role.name | translate }}</th>
            <td *ngFor="let permission of permissions">
                <div class="text-center">
                    <label [for]="permission.code + role._id" class="fa-stack text-link">
                        <i
                            class="far fa-circle fa-stack-2x"
                            [ngClass]="{
                              'text-success': role.permissionCodes.includes(permission.code),
                              'text-danger': !role.permissionCodes || !role.permissionCodes.includes(permission.code)
                            }"
                        ></i>
                        <i
                            [ngClass]="{
                              'fa-check text-success': role.permissionCodes && role.permissionCodes.includes(permission.code),
                              'fa-times text-danger': !role.permissionCodes || !role.permissionCodes.includes(permission.code)
                            }"
                            class="fa fa-stack-1x"
                        ></i>
                    </label>
                    <input
                        *saiHasRight="RightNames.CAN_WRITE_RIGHT"
                        [id]="permission.code + role._id"
                        (change)="revertPermissionAffection(permission, role)"
                        class="d-none form-check-input position-static"
                        type="checkbox"
                        value="option1"
                        [checked]="role.permissionCodes && role.permissionCodes.includes(permission.code)"
                    />
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</div>
