export class FilterConfig {
    carrier: boolean;
    enseigne: boolean;
    departureSite: boolean;
    department: boolean;

    constructor() {
        this.carrier = false;
        this.enseigne = false;
        this.departureSite = false;
        this.departureSite = false;
    }
}
