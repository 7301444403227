import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators"

@Injectable({
    providedIn: 'root'
})
export class CredentialsInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // With Credentials dira au navigateur d'envoyer les cookies dans ses requests : requis pour que l'API le reçoive
        request = request.clone({
            withCredentials: true
        });

        return next.handle(request);
    }
}
