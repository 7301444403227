import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PermissionDto} from "@shared/models/PermissionDto";
import {RoleDto} from "@shared/models/RoleDto";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {RightNames} from "@shared/constants/right-names.constant";
import {RightManagementService} from "../../services/right-management.service";

@Component({
    selector: 'track-right-table',
    templateUrl: './right-table.component.html',
    styleUrls: ['./right-table.component.scss']
})
export class RightTableComponent implements OnInit, OnDestroy {
    public readonly RightNames = RightNames;

    @Input() permissions: PermissionDto[];
    @Input() roles: RoleDto[];
    @Output() permissionHasChanged = new EventEmitter<void>();

    public currentLanguage: string;
    private languageChangedSubscription!: Subscription;

    constructor(public translate: TranslateService, public rightManagementService: RightManagementService) {
        this.permissions = [];
        this.roles = [];
        this.currentLanguage = this.translate.currentLang;
    }

    ngOnInit(): void {
        this.languageChangedSubscription = this.translate.onLangChange
            .subscribe((langChangeEvent: LangChangeEvent) => this.currentLanguage = langChangeEvent.lang);
    }

    ngOnDestroy(): void {
        this.languageChangedSubscription.unsubscribe();
    }

    public revertPermissionAffection(permission: PermissionDto, role: RoleDto) {
        this.rightManagementService.addOrRemovePermissionToRole(permission, role).then((x) => {
            this.permissionHasChanged.emit();
        });
    }
}
