export class RoleDto {
    _id: string;
    name: string;
    weight: number;
    permissionCodes: string[];

    public constructor() {
        this._id = '';
        this.name = '';
        this.weight = 0;
        this.permissionCodes = [];
    }
}
