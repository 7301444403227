<div id="monther" class="container-fluid px-5 mt-3">
    <nav id="temporality-selector" class="row mb-3">
        <!-- Months selection -->
        <div dropdown id="month-selection" class="col">
            <a dropdownToggle>
                <span class="mr-1">{{ "monther.month" | translate }}</span> <i class="fas fa-chevron-down"></i
            ></a>
            <div *dropdownMenu class="dropdown-menu" role="menu">
                <a
                    *ngFor="let month of selectableMonths"
                    [ngClass]="{ 'dropdown-item': true, 'font-weight-bold': month == selectedPeriod.month }"
                    (click)="selectYearAndMonth(selectedPeriod.year, month)"
                ><i *ngIf="month == selectedPeriod.month" class="fas fa-chevron-right"></i>
                    {{ "constant.month." + month | translate | titlecase }}
                </a>
            </div>
        </div>

        <!-- Previous and next month selection -->
        <a id="previous-month" class="col" (click)="selectPreviousMonth()">
            <span>{{ "constant.month." + monthly.previousMonth | translate | lowercase }}</span></a
        >
        <a id="current-month" class="col-auto">
            <span>{{ "constant.month." + monthly.currentMonth | translate | lowercase }} {{ selectedPeriod.year }}</span></a
        >
        <a id="next-month" class="col" (click)="selectNextMonth()">
            <span>{{ "constant.month." + monthly.nextMonth | translate | lowercase }}</span></a
        >

        <!-- Years selection -->
        <div dropdown id="year-selection" class="dropdown" class="col">
            <a dropdownToggle>
                <span class="mr-1">{{ "monther.year" | translate }}</span>
                <i class="fas fa-chevron-down fa-fw"></i>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                <a
                    *ngFor="let year of selectableYears"
                    [ngClass]="{ 'dropdown-item': true, 'font-weight-bold': year == selectedPeriod.year }"
                    (click)="selectYearAndMonth(year, selectedPeriod.month)"
                ><i *ngIf="year == selectedPeriod.year" class="fas fa-chevron-right"></i>
                    {{ year }}</a
                >
            </div>
        </div>
    </nav>

    <div id="temporality-indicator" class="row">
        <span id="indicator-first" class="col col ml-3 mr-4"></span>
        <span id="indicator-middle" class="col-auto"></span>
        <span id="indicator-last" class="col col ml-4 mr-3"></span>
    </div>
</div>
