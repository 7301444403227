import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {sumBy} from 'lodash';
import {Filter} from '../model/filter.model';

@Component({
    selector: 'sai-dropdown-filter',
    templateUrl: './dropdown-filter.component.html',
    styleUrls: ['./dropdown-filter.component.sass'],
    providers: [NgbDropdownConfig],
})
export class DropdownFilterComponent implements OnInit {
    @Input() filters: Filter[];
    @Input() filtersLabel: string;
    @Output() filterSelected = new EventEmitter<Filter[]>();

    constructor(config: NgbDropdownConfig) {
        config.autoClose = 'outside';
        config.container = 'body';
    }

    ngOnInit(): void {
    }

    clearFilters(filtersToClear: Filter[]) {
        filtersToClear.forEach((filter) => (filter.selected = false));
        this.filterSelected.emit(filtersToClear);
    }

    selectFilter(filterToUpdate: Filter) {
        filterToUpdate.selected = !filterToUpdate.selected;
        this.filterSelected.emit([filterToUpdate]);
    }

    countSelected(filters: Filter[]) {
        return filters.filter((filter) => filter.selected).length;
    }
}
