import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Investigation} from 'src/app/anomalies/model/investigation.model';
import {Endpoints} from 'src/app/shared/services/endpoint.constant';

@Injectable()
export class ActionService {
    private endpoints: Endpoints;

    constructor(public http: HttpClient) {
        this.endpoints = new Endpoints();
    }

    public getAllInvestigations(): Promise<Investigation[]> {
        return this.http.get<Investigation[]>(this.endpoints.ACTIONS).toPromise();
    }

    public findInvestigationBy(idActionSuivi: number): Promise<Investigation> {
        return this.http.get<Investigation>(this.endpoints.ACTION_DETAILS + '/' + idActionSuivi).toPromise();
    }

    public updateResponsable(investigation: Investigation): Promise<Investigation> {
        const url = this.endpoints.INVESTIGATIONS + '/' + investigation.idaction_suivi;
        return this.http.put<Investigation>(url, investigation).toPromise();

    }
}
