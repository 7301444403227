<div class="row">
    <h2 class="col sub-title d-flex">
        <small class="mr-auto">{{ "events.title.labelR3" | translate }}</small>
        <!-- <span>R3</span> -->
    </h2>
</div>
<table class="col table table-hover mb-0">
    <thead class="text-center">
    <tr>
        <th colspan="8">
            {{ "recommendations.table.anomaly caracteristic" | translate }}
        </th>
        <th colspan="3" class="bg-secondary text-white">
            {{ "recommendations.table.external caracteristics" | translate }}
        </th>
    </tr>
    <tr>
        <th>{{ "events.tableR3.departement_de_destination" | translate }}</th>
        <th>{{ "events.tableR3.date_de_livraison_theorique" | translate }}</th>
        <th>{{ "events.tableR3.meteo_vigilance_color_arrive" | translate }}</th>
        <th>{{ "events.tableR3.exp_en_retard" | translate }}</th>
        <th>{{ "events.tableR3.jour_de_livraison_theorique" | translate }}</th>
        <th>{{ "events.tableR3.exp_a_lheure" | translate }}</th>
        <th>{{ "events.tableR3.taux_retard" | translate }}</th>
        <th>{{ "events.tableR3.enjeu_global" | translate }}</th>
        <th class="bg-secondary text-white">{{ "events.tableR3.exp_a_lheure_hors" | translate }}</th>
        <th class="bg-secondary text-white">{{ "events.tableR3.exp_en_retard_hors" | translate }}</th>
        <th class="bg-secondary text-white">{{ "events.tableR3.taux_retard_hors" | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr class="text-center" *ngFor="let event of eventR3">
        <td>{{ event.departement_de_destination }}</td>
        <td>{{ event.date_de_livraison_theorique }}</td>
        <td>{{ event.meteo_vigilance_color_arrive }}</td>
        <td>{{ event.exp_en_retard }}</td>
        <td>{{ event.jour_de_livraison_theorique }}</td>
        <td>{{ event.exp_a_lheure }}</td>
        <td>{{ event.taux_retard | percent: "1.0-2" }}</td>
        <td>{{ event.enjeu_global | percent: "1.0-2" }}</td>
        <td>{{ event.exp_a_lheure_hors }}</td>
        <td>{{ event.exp_en_retard_hors }}</td>
        <td>{{ event.taux_retard_hors | percent: "1.0-2" }}</td>
    </tr>
    <tr></tr>
    <tr></tr>
    </tbody>
</table>
