<div ngbDropdown id="dropdown-filter" [ngClass]="{ 'has-selected-filter': countSelected(filters) > 0 }">
    <div ngbDropdownToggle class="dropdown-toggle">
        <span>{{ "filters." + filtersLabel | translate }}</span>
        <i class="fas fa-angle-down"></i>
    </div>
    <div ngbDropdownMenu>
        <div class="dropdown-header d-flex justify-content-between align-items-center">
            <span class="">{{ "filters.selected" | translate: {count: countSelected(filters)} }}</span>
            <button class="btn btn-outline-dark btn-sm" (click)="clearFilters(filters)">
                {{ "filters.clear" | translate }}
            </button>
        </div>
        <div class="dropdown-divider"></div>
        <div>
            <ng-container *ngFor="let filter of filters">
                <span ngbDropdownItem [ngClass]="{ active: filter.selected }" (click)="selectFilter(filter)"
                >{{ filter.label }}
                    <span
                        class="badge"
                        [ngClass]="{
                            'badge-primary': !filter.selected,
                            'badge-light': filter.selected,
                            'badge-secondary': filter.nbRecord === 0
                        }"
                    >
                        {{ filter.nbRecord }}</span
                    ></span
                >
            </ng-container>
        </div>
    </div>
</div>
