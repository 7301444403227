import {Component, Input, OnInit} from '@angular/core';
import {ActionStatus} from 'src/app/actions/model/action-status.constant';
import {Investigation} from 'src/app/anomalies/model/investigation.model';

@Component({
    selector: 'sai-action-status',
    templateUrl: './action-status.component.html',
    styleUrls: ['./action-status.component.sass'],
})
export class ActionStatusComponent implements OnInit {
    @Input() action: Investigation;
    public actionStatus: ActionStatus;

    constructor() {
        this.actionStatus = new ActionStatus();
    }

    ngOnInit(): void {
    }

    getCssClassesForStatus(action: Investigation) {
        let badgeClass: string;
        if (action.statut === this.actionStatus.inProgress) {
            badgeClass = 'badge-primary';
        } else if (action.statut === this.actionStatus.done) {
            badgeClass = 'badge-success';
        } else if (action.statut === this.actionStatus.cancelled) {
            badgeClass = 'badge-warning';
        } else {
            badgeClass = 'badge-secondary';
        }

        return ['badge', badgeClass];
    }
}
