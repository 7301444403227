import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnomalyFilterService} from './services/anomaly-filter.service';
import {AnomalyFiltersComponent} from './anomaly-filters/anomaly-filters.component';
import {DropdownFilterComponent} from './dropdown-filter/dropdown-filter.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../shared/shared.module';
import {ModerationFilterService} from './services/moderation-filter.service';
import {ModerationFiltersComponent} from './moderation-filters/moderation-filters.component';

@NgModule({
    declarations: [AnomalyFiltersComponent, DropdownFilterComponent, ModerationFiltersComponent],
    imports: [CommonModule, TranslateModule, NgbDropdownModule, SharedModule],
    exports: [AnomalyFiltersComponent, ModerationFiltersComponent],
    providers: [AnomalyFilterService, ModerationFilterService],
})
export class FiltersModule {
}
