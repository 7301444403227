import {Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {LoadingService} from '../../services/loading.service';

@Component({
    selector: 'sai-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.sass'],
})
export class LoadingComponent implements OnInit, OnDestroy, AfterViewInit {
    loading = true;
    loadingSubscription: Subscription;

    constructor(private loadingService: LoadingService, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.loadingService._loading.subscribe((status: boolean) => {
            this.loading = status;
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.loadingSubscription.unsubscribe();
    }
}
