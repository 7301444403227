import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Endpoints} from 'src/app/shared/services/endpoint.constant';
import {PermanentFilter} from '../model/permanent-filter.model';
import {AnomalyFilters} from '../model/anomaly-filters.model';
import {RefeFiltersAnomaly} from '../model/refe-filters-anomaly.model';
import {Filter} from '../model/filter.model';

@Injectable()
export class AnomalyFilterService {
    private endpoints: Endpoints;

    private anomalyFiltersSource: BehaviorSubject<AnomalyFilters>;
    public anomalyFilters: Observable<AnomalyFilters>;

    constructor(public http: HttpClient) {
        this.endpoints = new Endpoints();
        this.initFiltersForAnomaly();
    }

    public filterHasBeenSelected(filters: any) {
        this.anomalyFiltersSource.next(filters);
    }

    public selectCarrierToFilter(transporteur: string) {
        const filters = this.anomalyFiltersSource.getValue();

        // On désélectionne tous les filtres
        filters.carrierFilters.forEach((carrier) => (carrier.selected = false));

        // Pour ne sélectionner que celui en paramètre
        filters.carrierFilters
            .filter((carrier) => carrier.value === transporteur)
            .forEach((carrier) => (carrier.selected = true));

        this.filterHasBeenSelected(filters);
    }

    public buildPermanentFiltersFromAnomalyFilters(anomalyFilters: AnomalyFilters): PermanentFilter {
        const carriers = anomalyFilters.carrierFilters
            .filter((filter) => filter.selected)
            .map((filter) => filter.value);
        const departureSites = anomalyFilters.departureSiteFilters
            .filter((filter) => filter.selected)
            .map((filter) => filter.value);
        const enseignes = anomalyFilters.enseigneFilters
            .filter((filter) => filter.selected)
            .map((filter) => filter.value);
        const departments = anomalyFilters.departmentFilters
            .filter((filter) => filter.selected)
            .map((filter) => filter.value);

        return {carriers, departureSites, enseignes, departments};
    }

    private async initFiltersForAnomaly() {
        this.anomalyFiltersSource = new BehaviorSubject<AnomalyFilters>(new AnomalyFilters());
        this.anomalyFilters = this.anomalyFiltersSource.asObservable();

        this.anomalyFiltersSource.next(await this.getFiltersForAnomaly());
    }

    public async getFiltersForAnomaly(): Promise<AnomalyFilters> {
        // Le back nous retourne toutes les valeurs de filtre possible par type
        const url = this.endpoints.REFE_FILTERS_ANOMALY;
        const refeFiltersAnomaly = await this.http.get<RefeFiltersAnomaly>(url).toPromise();

        // Que l'on convertie en un objet de filtre pour la vue
        const wantedFiltersAnomaly = new AnomalyFilters();
        refeFiltersAnomaly.carriers.forEach((carrier) => {
            wantedFiltersAnomaly.carrierFilters.push(this.buildFilterFromValue(carrier));
        });
        refeFiltersAnomaly.departments.forEach((department) => {
            wantedFiltersAnomaly.departmentFilters.push(this.buildFilterFromValue(department));
        });
        refeFiltersAnomaly.enseignes.forEach((enseigne) => {
            wantedFiltersAnomaly.enseigneFilters.push(this.buildFilterFromValue(enseigne));
        });
        refeFiltersAnomaly.departureSites.forEach((departureSite) => {
            wantedFiltersAnomaly.departureSiteFilters.push(this.buildFilterFromValue(departureSite));
        });

        return wantedFiltersAnomaly;
    }

    private buildFilterFromValue(filterValue: string): Filter {
        return {label: filterValue, nbRecord: 0, selected: false, value: filterValue};
    }
}
