export class PermissionDto {
    _id: string;
    code: string;
    labelEn: string;
    labelFr: string;
    descriptionEn: string;
    descriptionFr: string;
    columnOrder: number;

    public constructor() {
        this._id = '';
        this.code = '';
        this.labelEn = '';
        this.labelFr = '';
        this.descriptionEn = '';
        this.descriptionFr = '';
        this.columnOrder = 0;
    }
}
