<div class="container-fluid px-5">
    <div class="row">
        <div class="col">
            <sai-anomaly-filters [filterRights]="filterRights"></sai-anomaly-filters>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="jumbotron">
                <h1 class="display-4">{{ "dashboard.actions.resume" | translate }}</h1>
                <hr class="my-4"/>
                <div class="card-deck text-center mb-4">
                    <div (click)="goToAllReco('all')" class="card bg-primary text-white clickable">
                        <div class="card-body">
                            <h2 class="card-title">
                                <i class="fas fa-arrow-right fa-fw"></i>{{ actionReport.nbTotalInvestigations }}
                            </h2>
                            <p class="card-text lead">{{ "dashboard.actions.total" | translate }}</p>
                        </div>
                    </div>
                    <div (click)="goToAllReco('done')" class="card bg-success text-white clickable">
                        <div class="card-body">
                            <h2 class="card-title">
                                <i class="fas fa-check fa-fw"></i>{{ actionReport.nbDoneInvestigations }}
                            </h2>
                            <p class="card-text lead">{{ "dashboard.actions.done" | translate }}</p>
                        </div>
                    </div>
                    <div (click)="goToAllReco('cancelled')" class="card bg-warning text-white clickable">
                        <div class="card-body">
                            <h2 class="card-title">
                                <i class="fas fa-times fa-fw"></i>{{ actionReport.nbCancelledInvestigations }}
                            </h2>
                            <p class="card-text lead">{{ "dashboard.actions.cancelled" | translate }}</p>
                        </div>
                    </div>
                    <div (click)="goToAllReco('inProgress')" class="card bg-danger text-white clickable">
                        <div class="card-body">
                            <h2 class="card-title">
                                <i class="fas fa-exclamation-triangle"></i> {{ actionReport.nbOverdueInvestigations }}
                            </h2>
                            <p class="card-text lead">{{ "dashboard.actions.overdue" | translate }}</p>
                        </div>
                    </div>
                </div>
                <p>
                    <button
                        class="btn btn-outline-primary btn-lg"
                        *saiHasRight="'CAN_READ_ACTION'"
                        [routerLink]="['/actions-monitoring']"
                    >{{ "dashboard.actions.go to action" | translate }}</button
                    >
                </p>
            </div>
        </div>
    </div>
</div>
