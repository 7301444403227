import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {NavComponent} from './nav/nav.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {UserModule} from '../user/user.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [HeaderComponent, NavComponent],
    imports: [CommonModule, RouterModule, BsDropdownModule, FormsModule, TranslateModule, SharedModule, UserModule],
    exports: [HeaderComponent],
})
export class MenuModule {
}
