import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'track-management-main',
    templateUrl: './management-main.component.html',
    styleUrls: ['./management-main.component.scss']
})
export class ManagementMainComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    public goToUserManagement() {
        this.router.navigate(['/management/users']).then();
    }

    public goToRightManagement() {
        this.router.navigate(['/management/rights']).then();
    }
}
