import {Injectable} from '@angular/core';
import {RoleDto} from "@shared/models/RoleDto";
import {HttpClient} from "@angular/common/http";
import {Endpoints} from "@shared/services/endpoint.constant";
import {PermissionDto} from "@shared/models/PermissionDto";

@Injectable()
export class RightManagementService {
    private endpoints = new Endpoints();

    constructor(private http: HttpClient) {
    }

    public getAllRoles(): Promise<RoleDto[]> {
        return this.http.get<RoleDto[]>(this.endpoints.ROLES).toPromise();
    }

    public createRole(role: RoleDto): Promise<RoleDto> {
        return this.http.post<RoleDto>(this.endpoints.ROLES, role).toPromise();
    }

    public updateRole(role: RoleDto): Promise<RoleDto> {
        return this.http.put<RoleDto>(this.endpoints.ROLES, role).toPromise();
    }

    public getAllPermissions(): Promise<PermissionDto[]> {
        return this.http.get<PermissionDto[]>(this.endpoints.PERMISSIONS).toPromise();
    }

    public createPermission(permission: PermissionDto): Promise<PermissionDto> {
        return this.http.post<PermissionDto>(this.endpoints.PERMISSIONS, permission).toPromise();
    }

    public updatePermission(permission: PermissionDto): Promise<PermissionDto> {
        return this.http.put<PermissionDto>(this.endpoints.PERMISSIONS, permission).toPromise();
    }

    public addOrRemovePermissionToRole(permission: PermissionDto, role: RoleDto): Promise<PermissionDto> {
        return this.http.put<PermissionDto>(this.endpoints.PERMISSION_TO_ROLE, this.buildPermissionToRolePayload(permission, role)).toPromise();
    }

    private buildPermissionToRolePayload(permission: PermissionDto, role: RoleDto) {
        return {
            roleId: role._id,
            permissionCode: permission.code,
        };
    }
}
