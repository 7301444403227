import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from 'src/app/shared/services/endpoint.constant';
import {UserManagement} from '../model/user-management.model';
import {RoleDto} from "@shared/models/RoleDto";

@Injectable()
export class UserManagementService {
    endpoints = new Endpoints();

    constructor(private http: HttpClient) {
    }

    public getAllUsers(): Promise<UserManagement[]> {
        return this.http.get<UserManagement[]>(this.endpoints.USERS).toPromise();
    }

    public getUserByEmail(email: string): Observable<UserManagement> {
        const url = `${this.endpoints.USER_BY_EMAIL}/${email}`;
        return this.http.get<UserManagement>(url);
    }

    public updateUser(user: UserManagement): Observable<UserManagement> {
        return this.http.put<UserManagement>(this.endpoints.USERS, user);
    }

    public createUser(user: UserManagement): Observable<UserManagement> {
        return this.http.post<UserManagement>(this.endpoints.USERS, user);
    }

    public getUserById(id: string) {
        const url = `${this.endpoints.USER_BY_ID}/${id}`;
        return this.http.get<UserManagement>(url);
    }

    public deactivateUser(user: UserManagement): Observable<UserManagement> {
        const url = `${this.endpoints.USER_DEACTIVATE}/${user._id}`;
        return this.http.put<UserManagement>(url, null);
    }

    public reactivateUser(user: UserManagement): Observable<UserManagement> {
        const url = `${this.endpoints.USER_REACTIVATE}/${user._id}`;
        return this.http.put<UserManagement>(url, null);
    }

    public deleteUser(user: UserManagement): Observable<UserManagement> {
        const url = `${this.endpoints.USER_DELETE}/${user._id}`;
        return this.http.put<UserManagement>(url, null);
    }

    public findAttributableRoles(): Observable<RoleDto[]> {
        return this.http.get<RoleDto[]>(this.endpoints.ROLES_ATTRIBUTABLE);
    }

}
