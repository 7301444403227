import {Component, Inject, LOCALE_ID, NgModule, OnDestroy, OnInit} from '@angular/core';
import {RecommendationsService} from '../services/recommendations.service';
import {Pattern} from '../model/pattern.model';
import {Anomaly} from '../model/anomaly.model';
import {cloneDeep, filter, get, orderBy} from 'lodash';
import {AnomalyColumn} from '../model/anomaly-column.model';
import {formatPercent} from '@angular/common';
import {trigger, transition, style, animate} from '@angular/animations';
import {Period} from '../../shared/components/monther/period.model';
import {switchMap, scan} from 'rxjs/operators';
import {from, Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {MontherService} from '../../shared/services/monther.service';
import {AnomalyFilterService} from 'src/app/filters/services/anomaly-filter.service';
import {AnomalyFilters} from 'src/app/filters/model/anomaly-filters.model';
import {FilterConfig} from '../model/filter-config.model';
import {ActivatedRoute} from '@angular/router';
import {AnomalyColumns} from '../services/anomaly-column.constant';
import {Filter} from 'src/app/filters/model/filter.model';

@Component({
    selector: 'sai-recommendations-visualization',
    templateUrl: './reco-visu.component.html',
    styleUrls: ['./reco-visu.component.sass'],
    animations: [
        trigger('inAnimation', [
            transition(':enter', [
                style({height: 0, opacity: 0}),
                animate('.5s ease-out', style({height: '', opacity: 1})),
            ]),
        ]),
    ],
})
export class RecommendationsVisualizationComponent implements OnInit, OnDestroy {
    public patterns: Pattern[];
    public patternList: Pattern[];
    public docSortObj: any = {};
    public regles: any;
    public filters: AnomalyFilters;

    public filterConfig: FilterConfig;
    public carrier: string;

    public loading: boolean;

    private filterSubscription: Subscription;

    constructor(
        public recommendationService: RecommendationsService,
        @Inject(LOCALE_ID) public locale: string,
        public translateService: TranslateService,
        private montherService: MontherService,
        private filterService: AnomalyFilterService,
        private route: ActivatedRoute
    ) {
        this.patterns = [];
        this.filterConfig = new FilterConfig();
        this.filterConfig.carrier = true;

        this.filterSubscription = this.filterService.anomalyFilters.subscribe((anomalyFilters) => {
            this.filters = anomalyFilters;
            this.filterPatternList();
        });

        this.route.queryParams.subscribe((params: any) => {
            if (params && params.carrier) {
                this.filterService.selectCarrierToFilter(params.carrier);
            }
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.filterSubscription.unsubscribe();
    }

    public updatePeriod = (period: Period) => this.getRecommendedAnomalies(period);

    public getRecommendedAnomalies(period: Period) {
        this.loading = true;
        this.recommendationService
            .getRecommendedAnomaliesFor(period)
            .then((patterns) => {
                this.patterns = orderBy(patterns, (pattern) => pattern.regle.numRegle, 'asc');
                this.patterns.forEach((pattern) => {
                    // Toutes les colonnes initialisé à -1 pour le trie
                    const rnum = pattern.regle.numRegle;
                    this.docSortObj[rnum] = {};
                    pattern.regle.columns.forEach((col) => (this.docSortObj[rnum][col.designation] = -1));

                    // Sauf enjeu global qu'on met décroissant pâr défaut
                    const enjeuGlobalColumn = pattern.regle.columns.find(
                        (column) => column.designation === AnomalyColumns.ENJEU_GLOBAL
                    );
                    this.docSortObj[rnum][enjeuGlobalColumn.designation] = 1;

                    this.sortListBy(enjeuGlobalColumn, pattern);
                });
            })
            .then(() => this.filterPatternList())
            .finally(() => (this.loading = false));
    }

    public getAnomalyValue(anomaly: Anomaly, column: AnomalyColumn): any {
        let value = get(anomaly, column.designation);
        if (column.isPercent) {
            value = formatPercent(value, this.locale, '1.0-2');
        }
        return value;
    }

    public getAnomalyCssClasses(column: AnomalyColumn): string[] {
        if (column.isNumber) {
            return ['text-center'];
        }
    }

    public getSelectedPeriod() {
        return this.montherService.getSelectedPeriod();
    }

    sortListBy(field: AnomalyColumn, pattern: Pattern) {
        // resetting arrow icons
        const tmp =
            this.docSortObj[pattern.regle.numRegle][field.designation] === -1
                ? 1
                : this.docSortObj[pattern.regle.numRegle][field.designation] + 1;
        Object.keys(this.docSortObj[pattern.regle.numRegle]).map(
            (k) => (this.docSortObj[pattern.regle.numRegle][k] = -1)
        );
        this.docSortObj[pattern.regle.numRegle][field.designation] = tmp;

        pattern.anomalies = pattern.anomalies.sort((a, b) => {
            const tmpA = this.getAnomalyValue(a, field);
            const tmpB = this.getAnomalyValue(b, field);

            if (tmpA === tmpB) {
                return 0;
            }
            return this.docSortObj[pattern.regle.numRegle][field.designation] % 2
                ? tmpA < tmpB
                    ? -1
                    : 1
                : tmpA < tmpB
                    ? 1
                    : -1;
        });
    }

    public async removeAnomalyFromRecommended(anomalyToRemove: Anomaly, concernedPattern: Pattern, currentRow: any) {
        const swalModalTitle = await this.translateService
            .get('anomaly.modal_message.swal_modal_title')
            .toPromise()
            .then((translation) => translation);

        Swal.fire({
            title: '',
            text: swalModalTitle,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Retirer',
        }).then((result) => {
            // Remove from list.

            if (result.isConfirmed) {
                currentRow.loading = true;
                this.recommendationService
                    .removeFromRecommendation(anomalyToRemove.idanomalies)
                    .then(() => {
                        // On enlève l'anomalie de l'affichage
                        concernedPattern.anomalies = concernedPattern.anomalies.filter(
                            (anomaly) => anomaly !== anomalyToRemove
                        );
                    })
                    .finally(() => (currentRow.loading = false));
            }
        });
    }

    public async getRegles() {
        this.regles = await this.recommendationService
            .getRegles()
            .pipe(
                switchMap((val) => from(val)),
                scan((acc, obj) => {
                    const nr = obj['num_regle'];
                    acc[nr] = obj['desc_regle'];
                    return acc;
                }, {})
            )
            .toPromise();
    }

    public filterPatternList() {
        this.patternList = cloneDeep(this.patterns);

        // Filtre en lui même
        const carrierToFilter = this.filters.carrierFilters
            .filter((carrierFilter) => carrierFilter.selected)
            .map((carrierFilter) => carrierFilter.value);

        if (carrierToFilter.length > 0) {
            this.patternList.forEach((anomaly) => {
                anomaly.anomalies = filter(anomaly.anomalies, (anomalies) => {
                    return carrierToFilter.includes(anomalies.transporteurs_nom);
                });
            });
        }

        // Met à jour les pastilles
        this.filters.carrierFilters.forEach((carrier: Filter) => (carrier.nbRecord = 0));

        this.patternList.forEach((pattern: Pattern) => {
            pattern.anomalies.forEach((anomaly: Anomaly) => {
                this.filters.carrierFilters.forEach((carrier: Filter) => {
                    if (carrier.value.includes(anomaly.transporteurs_nom)) {
                        carrier.nbRecord++;
                    }
                });
            });
        });
    }
}
