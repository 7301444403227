import {Summary} from '@angular/compiler';
import {Component, Input, AfterContentInit, OnInit, OnChanges,} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {AnomalySummary} from '../model/anomalySummary.model';

// import { Label } from 'ng2-charts';

@Component({
    selector: 'sai-criticality-bar',
    templateUrl: './criticality-bar.component.html',
    styleUrls: ['./criticality-bar.component.sass']
})
export class CriticalityBarComponent implements OnChanges {
    @Input() summary: AnomalySummary;
    private highCriticalPercentage: number;
    private lowCriticalPercentage: number;
    private mediumCriticalPercentage: number;
    public barChartOptions: ChartOptions = {
        scales: {
            xAxes: [
                {
                    display: false
                }
            ],
            yAxes: [
                {
                    display: false
                }
            ],
            gridLines: {
                display: false

            }
        },
        responsive: true,
        tooltips: {
            enabled: false

        },
        maintainAspectRatio: false
    };

    public barChartColors: Color[] = [
        {backgroundColor: '#90be6d'},
        {backgroundColor: '#f77f00'},
        {backgroundColor: '#f94144'}

    ];
    public barChartType: ChartType = 'horizontalBar';
    public barChartData: ChartDataSets[];
    public barChartLegend = false;
    public barChartLabels: Label[] = ['Criticité'];

    ngOnChanges(): void {
        this.percentageCrticality()
        if (this.summary) {
            this.barChartData = [{data: [this.lowCriticalPercentage], label: 'Low', stack: 'a'},
                {data: [this.mediumCriticalPercentage], label: 'Medium', stack: 'a'},
                {data: [this.highCriticalPercentage], label: 'High', stack: 'a'}

            ];
        }

    }


    constructor() {
    }

    private percentageCrticality() {
        let sum = this.summary.nbLowCriticalPattern + this.summary.nbMediumCriticalPattern + this.summary.nbHighCriticalPattern;
        this.lowCriticalPercentage = Math.floor((this.summary.nbLowCriticalPattern / sum) * 100);
        this.mediumCriticalPercentage = Math.floor((this.summary.nbMediumCriticalPattern / sum) * 100);
        this.highCriticalPercentage = Math.floor((this.summary.nbHighCriticalPattern / sum) * 100);
    }


}
