import {Injectable} from '@angular/core';
import {getMonth, getYear} from 'date-fns';
import {Period} from '../components/monther/period.model';
import {StorageService} from './storage.service';

@Injectable()
export class MontherService {
    private montherValueKey = 'monther-value';
    public selectedPeriod: Period;

    constructor(private storageService: StorageService) {
        this.initiateMontherValue();
    }

    public updateSelectedDate(period: Period) {
        this.selectedPeriod = period;
        this.storageService.set(this.montherValueKey, period, true);
    }

    public getSelectedPeriod(): Period {
        return this.selectedPeriod;
    }

    private initiateMontherValue() {
        const montherValue = this.storageService.get(this.montherValueKey, true);

        if (montherValue) {
            this.selectedPeriod = montherValue;
        } else {
            // Pas de valeur sauvegardée, on prend par défaut le mois précédent
            const today = new Date();
            this.selectedPeriod = {month: getMonth(today) - 1, year: getYear(today)};
        }
    }
}
