import {Component, Input, OnInit} from '@angular/core';
import {AnomalySummary} from '../model/anomalySummary.model';
import {Router} from '@angular/router';
import {AnomalyFilterService} from 'src/app/filters/services/anomaly-filter.service';
import {AnomalyFilters} from 'src/app/filters/model/anomaly-filters.model';

@Component({
    selector: 'sai-table-summary',
    templateUrl: './table-summary.component.html',
    styleUrls: ['./table-summary.component.sass'],
})
export class TableSummaryComponent implements OnInit {
    @Input() anomalyMonthlySummary: AnomalySummary[];
    public carrier: string;
    public filters: AnomalyFilters;

    constructor(private router: Router, private filterService: AnomalyFilterService) {
    }

    ngOnInit(): void {
        this.filterService.anomalyFilters.subscribe((anomalyFilters) => (this.filters = anomalyFilters));
    }

    public goToReco(carrier: string) {
        this.router.navigate(['/recommendations-visualization'], {queryParams: {carrier}});
    }
}
