export abstract class AnomalyColumns {
    public static NUM_REGLE = 'num_regle';
    public static ANOMALIE_PATTERN = 'anomalie_pattern';
    public static STATUT = 'statut';
    public static AUSSI_PRESENT = 'aussi_present';
    public static TRANSPORTEURS_NOM = 'transporteurs_nom';
    public static SITE_DE_DEPART_ANALYSE = 'site_de_depart_analyse';
    public static JOUR_DE_DEPART = 'jour_de_depart';
    public static DATE_DE_DEPART = 'date_de_depart';
    public static LIVRAISON_TRANCH_DISTANCE = 'livraison_tranch_distance';
    public static AUGMENTATION_VOLUME = 'augmentation_volume';
    public static JOURSFERIER_ENTRE_EXP_LIV = 'joursferier_entre_exp_liv';
    public static JOUR_DE_LIVRAISON_THEORIQUE = 'jour_de_livraison_theorique';
    public static DEPARTEMENT_DE_DESTINATION = 'departement_de_destination';
    public static VILLE_ARRIVEE = 'ville_arrivee';
    public static ENSEIGNE_LIBELLE = 'enseigne_libelle';
    public static LIBELLE_CLIENT = 'libelle_client';
    public static CODE_CLIENT = 'code_client';
    public static EXP_EN_RETARD = 'exp_en_retard';
    public static EXP_A_LHEURE = 'exp_a_lheure';
    public static TAUX_RETARD = 'taux_retard';
    public static ENJEU_TRANSPORTEUR = 'enjeu_transporteur';
    public static ENJEU_GLOBAL = 'enjeu_global';
    public static RST = 'rst';
    public static EXP_EN_RETARD_HORS = 'exp_en_retard_hors';
    public static EXP_A_LHEURE_HORS = 'exp_a_lheure_hors';
    public static TAUX_RETARD_HORS = 'taux_retard_hors';
    public static DEFF_TAUX_RETARD_PATTERN_TAUX_RETARD_HORS_PATTERN =
        'deff_taux_retard_pattern_taux_retard_hors_pattern';
}
