import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnomalyFilterService} from 'src/app/filters/services/anomaly-filter.service';
import {Filter} from '../model/filter.model';
import {AnomalyFilters} from '../model/anomaly-filters.model';
import {FilterConfig} from 'src/app/anomalies/model/filter-config.model';

@Component({
    selector: 'sai-anomaly-filters',
    templateUrl: './anomaly-filters.component.html',
    styleUrls: ['./anomaly-filters.component.sass'],
})
export class AnomalyFiltersComponent implements OnInit {
    public filters: AnomalyFilters;
    @Input() filterRights: FilterConfig;

    constructor(private filterService: AnomalyFilterService) {
        this.filterRights = new FilterConfig();
    }

    ngOnInit(): void {
        this.filterService.anomalyFilters.subscribe((anomalyFilters) => (this.filters = anomalyFilters));
    }

    filterHasBeenSelected(filters: Filter[]) {
        this.filterService.filterHasBeenSelected(this.filters);
    }
}
