import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {CalendarValue, ISelectionEvent} from 'ng2-date-picker';
import {InitDate} from './init-date.model';


@Component({
    selector: 'sai-actions-date-filter',
    templateUrl: './actions-date-filter.component.html',
    styleUrls: ['./actions-date-filter.component.sass']
})
export class ActionsDateFilterComponent implements OnInit {

    private initiationDateSource: string;
    private initTemporalitySource: number;
    public dpSelectedDate: Date;


    @Output() selectedDateUpdated = new EventEmitter<InitDate>();
    @Input() dateFilterCode: string;


    constructor(private route: ActivatedRoute) {
        this.dpSelectedDate = null;
    }

    ngOnInit(): void {
        // Retrieve value from query params
        this.route.queryParams.subscribe((params: any) => {
            if (params.status_inProgress) {
                if (this.dateFilterCode && this.dateFilterCode === 'deadline') {
                    this.onSelectedDateChange(new Date());
                    this.initTemporality = -1;
                }
            }
        });
    }


    updateInitDate() {
        const initDate = {} as InitDate;
        initDate.selectedDate = this.dpSelectedDate;
        initDate.temporality = this.initTemporality;
        this.selectedDateUpdated.emit(initDate);

    }


    get initTemporality(): number {
        return this.initTemporalitySource;
    }

    set initTemporality(value: number) {
        this.initTemporalitySource = value;
        this.updateInitDate();
    }

    onSelectedDateChange(value: Date): void {
        this.dpSelectedDate = value;
        this.updateInitDate();
    }

}
