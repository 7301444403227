import {Component, ElementRef, OnInit, ViewChild, Input} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SoldeDeCause} from '../model/solde-de-cause.model';
import {AnomalyService} from '../services/anomaly.service';
import {NgForm} from '@angular/forms';
import {Anomaly} from '../model/anomaly.model';
import {AnomalyToSolde} from '../model/anomaly-to-solde.model';

@Component({
    selector: 'sai-solder-anomalie',
    templateUrl: './solder-anomalie.component.html',
    styleUrls: ['./solder-anomalie.component.sass'],
})
export class SolderAnomalieComponent implements OnInit {
    @Input() anomaly: Anomaly;

    private solderAnomalieModal: NgbActiveModal;
    public soldeDeCauses: SoldeDeCause[];
    public causeDeSolde: SoldeDeCause;
    public autreCause: string;

    @ViewChild('modalSolderAnomalie', {static: true}) solderAnomaly: ElementRef;

    constructor(public modalService: NgbModal, public anomalyService: AnomalyService) {
        this.causeDeSolde = {} as SoldeDeCause;
    }

    ngOnInit(): void {
        this.anomalyService.getSoldeDeCauses().then((soldeDeCauses) => {
            this.soldeDeCauses = soldeDeCauses;
        });
    }

    onSubmit(form: NgForm) {
    }

    updateSolderAnomaly(): void {
        if (this.causeDeSolde.idanomalie_solde_cause_list === 3) {
            this.causeDeSolde.cause = this.autreCause;
        }

        const anomalyToSolde: AnomalyToSolde = {anomaly: this.anomaly, soldeDeCause: this.causeDeSolde};

        this.anomalyService.solderAnomaly(anomalyToSolde).then(() => {
            this.anomaly.statut = 'Soldée';
        });
        this.solderAnomalieModal.close();
    }

    openModalSolderAnomalie() {
        this.solderAnomalieModal = this.modalService.open(this.solderAnomaly, {
            size: 'lg',
            backdropClass: 'light-dialog-backdrop',
            windowClass: 'dark-dialog',
        });
    }

    closeModalSolderAnomalie() {
        this.solderAnomalieModal.close();
    }
}
