import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Investigation} from 'src/app/anomalies/model/investigation.model';
import {ActionRecapService} from 'src/app/shared/services/action-recap.service';
import {ActionService} from '../services/action.service';

@Component({
    selector: 'sai-add-response-dialog',
    templateUrl: './add-response-dialog.component.html',
    styleUrls: ['./add-response-dialog.component.sass'],
})
export class AddResponseDialogComponent {
    public isSaving = false;

    @Output() addResponseCompleted = new EventEmitter<Investigation>();

    // Le template à ouvrir comme une modal
    @ViewChild('addResponseBlock', {static: true}) addResponseBlock: ElementRef;

    // La modal actuellement ouverte
    private currentModal: NgbActiveModal;

    // L'action sur laquelle on va ajouter une réponse
    public actionToAddResponse: Investigation;

    constructor(private modalService: NgbModal, private actionRecapService: ActionRecapService) {
    }

    openDialogFor(action: Investigation) {
        this.actionToAddResponse = action;
        this.currentModal = this.modalService.open(this.addResponseBlock, {
            size: 'lg',
            backdropClass: 'light-dialog-backdrop',
            windowClass: 'dark-dialog',
        });
    }

    closeDialog() {
        this.currentModal.close();
    }

    async saveResponse() {
        this.isSaving = true;
        this.actionRecapService
            .addResponseToInvestigation(this.actionToAddResponse)
            .then((investigation) => {
                this.addResponseCompleted.emit(investigation);
                this.actionRecapService.completedOverdueActions(investigation);
                this.closeDialog();
            })
            .finally(() => {
                this.isSaving = false;
            });
    }
}
