<ng-template #detailBlock>
    <div class="modal-header">
        <h4 class="modal-title">{{ "actions.dialog.anomaly detail.title" | translate }}</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <table class="col table table-sm table-dark table-bordered table-striped">
                    <thead class="">
                    <tr>
                        <th>{{ "anomaly.label.period" | translate }}</th>
                        <ng-container *ngFor="let characteristic of actionMonitoring.anomalie_characteristics">
                            <th>{{ "anomaly.label." + characteristic.label | translate }}</th>
                        </ng-container>
                        <th class="text-right">{{ "anomaly.label.exp_en_retard" | translate }}</th>
                        <th class="text-right">{{ "anomaly.label.taux_retard" | translate }}</th>
                        <th class="text-right">{{ "anomaly.label.enjeu_global" | translate }}</th>
                        <th class="text-right">{{ "anomaly.label.enjeu_transporteur" | translate }}</th>
                        <th class="bg-info text-white text-right">
                            {{ "anomaly.label.exp_en_retard_hors" | translate }}
                        </th>
                        <th class="bg-info text-white text-right">
                            {{ "anomaly.label.taux_retard_hors" | translate }}
                        </th>
                        <th class="bg-info text-white text-right">
                            {{ "anomaly.label.deff_taux_retard_pattern_taux_retard_hors_pattern" | translate }}
                        </th>
                        <th *saiHasRight="'CAN_READ_SHIPPING_NUMBERS'">{{ "anomaly.label.shipping_number" | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let anomaly of actionMonitoring.anomalies">
                        <tr>
                            <td>{{ getPeriodAsDateFor(anomaly) | date: "MMM yyyy" }}</td>
                            <ng-container *ngFor="let characteristic of anomaly.anomalyCharacteristics">
                                <td class="">{{ characteristic.value }}</td>
                            </ng-container>
                            <td class="text-right">{{ anomaly.exp_en_retard }}</td>
                            <td class="text-right">{{ anomaly.taux_retard | percent }}</td>
                            <td class="text-right">{{ anomaly.enjeu_global | percent: "1.0-2" }}</td>
                            <td class="text-right">{{ anomaly.enjeu_transporteur | percent: "1.0-2" }}</td>
                            <td class="text-right">{{ anomaly.exp_en_retard_hors }}</td>
                            <td class="text-right">{{ anomaly.taux_retard_hors | percent }}</td>
                            <td class="text-right">
                                {{ anomaly.deff_taux_retard_pattern_taux_retard_hors_pattern | percent }}
                            </td>
                            <td *saiHasRight="'CAN_READ_SHIPPING_NUMBERS'" class="text-center">
                                <button class="btn btn-info btn-sm" [swal]="shippingNumber">Afficher</button>
                                <swal #shippingNumber>
                                    <sai-view-shipping-numbers
                                        *swalPortal
                                        [anomaly]="anomaly"
                                    ></sai-view-shipping-numbers>
                                </swal>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>

        <button type="button" class="btn btn-light" (click)="closeDialog()">
            {{ "actions.dialog.anomaly detail.close" | translate }}
        </button>
    </div>
</ng-template>
