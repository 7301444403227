import {Injectable} from '@angular/core';
import {Pattern} from '../model/pattern.model';
import {Anomaly} from '../model/anomaly.model';
import {groupBy, orderBy} from 'lodash';
import {Regle} from '../model/regle.model';
import {AnomalyColumns} from './anomaly-column.constant';
import {AnomalyColumn} from '../model/anomaly-column.model';

@Injectable()
export class PatternService {
    constructor() {
    }

    public buildPatternListFromAnomalies(anomalies: Anomaly[]): Pattern[] {
        const patterns = [];
        const groupedByRegle = groupBy(anomalies, (anomaly) => anomaly.num_regle);
        for (const key of Object.keys(groupedByRegle)) {
            patterns.push(this.buildPattern(this.getRegleFor(key), groupedByRegle[key]));
        }

        return patterns;
    }

    private buildPattern(regle: Regle, anomalies: Anomaly[]): Pattern {
        return {regle, anomalies};
    }

    private getRegleFor(numRegle: string): Regle {
        let regle: Regle;
        switch (numRegle) {
            case 'R5':
                regle = this.buildR5();
                break;
            case 'R6':
                regle = this.buildR6();
                break;
            case 'R7':
                regle = this.buildR7();
                break;
            case 'R8':
                regle = this.buildR8();
                break;
            case 'R9':
                regle = this.buildR9();
                break;
            case 'R12':
                regle = this.buildR12();
                break;
            case 'R13':
                regle = this.buildR13();
                break;
            case 'R14':
                regle = this.buildR14();
                break;
            case 'R15':
                regle = this.buildR15();
                break;
            default:
                // cas par défaut : les colonnes minimales avec la num_regle
                regle = this.buildDefault(numRegle);
        }
        return regle;
    }

    private buildDefault(numRegle: string) {
        return this.buildRegle(
            numRegle,
            [
                this.buildAnomalyColumn(AnomalyColumns.NUM_REGLE),
                this.buildAnomalyColumn(AnomalyColumns.STATUT),
                this.buildAnomalyColumn(AnomalyColumns.AUSSI_PRESENT),
                this.buildAnomalyColumn(AnomalyColumns.SITE_DE_DEPART_ANALYSE),
            ].concat(this.getRateCommonColumns())
        );
    }

    private getStatutCommonColumns(): AnomalyColumn[] {
        return [this.buildAnomalyColumn(AnomalyColumns.STATUT)];
    }

    private getRateCommonColumns(): AnomalyColumn[] {
        return [
            this.buildAnomalyColumn(AnomalyColumns.EXP_EN_RETARD, ['text-right'], true),
            this.buildAnomalyColumn(AnomalyColumns.TAUX_RETARD, ['text-right'], true, true),
            this.buildAnomalyColumn(AnomalyColumns.ENJEU_GLOBAL, ['text-right'], true, true),
            this.buildAnomalyColumn(AnomalyColumns.ENJEU_TRANSPORTEUR, ['text-right'], true, true),
            this.buildAnomalyColumn(
                AnomalyColumns.EXP_EN_RETARD_HORS,
                ['text-right', 'bg-secondary', 'text-white', 'border-left'],
                true
            ),
            this.buildAnomalyColumn(
                AnomalyColumns.TAUX_RETARD_HORS,
                ['text-right', 'bg-secondary', 'text-white'],
                true,
                true
            ),
            this.buildAnomalyColumn(
                AnomalyColumns.DEFF_TAUX_RETARD_PATTERN_TAUX_RETARD_HORS_PATTERN,
                ['text-right', 'bg-secondary', 'text-white', 'border-right'],
                true,
                true
            ),
        ];
    }

    private buildR5() {
        return this.buildRegle(
            'R5',
            this.getStatutCommonColumns()
                .concat([
                    this.buildAnomalyColumn(AnomalyColumns.TRANSPORTEURS_NOM),
                    this.buildAnomalyColumn(AnomalyColumns.SITE_DE_DEPART_ANALYSE),
                    this.buildAnomalyColumn(AnomalyColumns.JOUR_DE_DEPART),
                    this.buildAnomalyColumn(AnomalyColumns.LIVRAISON_TRANCH_DISTANCE),
                ])
                .concat(this.getRateCommonColumns())
        );
    }

    private buildR6() {
        return this.buildRegle(
            'R6',
            this.getStatutCommonColumns()
                .concat([
                    this.buildAnomalyColumn(AnomalyColumns.SITE_DE_DEPART_ANALYSE),
                    this.buildAnomalyColumn(AnomalyColumns.JOUR_DE_DEPART),
                    this.buildAnomalyColumn(AnomalyColumns.DATE_DE_DEPART),
                    this.buildAnomalyColumn(AnomalyColumns.LIVRAISON_TRANCH_DISTANCE),
                ])
                .concat(this.getRateCommonColumns())
        );
    }

    private buildR7() {
        return this.buildRegle(
            'R7',
            this.getStatutCommonColumns()
                .concat([
                    this.buildAnomalyColumn(AnomalyColumns.TRANSPORTEURS_NOM),
                    this.buildAnomalyColumn(AnomalyColumns.SITE_DE_DEPART_ANALYSE),
                    this.buildAnomalyColumn(AnomalyColumns.JOUR_DE_DEPART),
                    this.buildAnomalyColumn(AnomalyColumns.DATE_DE_DEPART),
                    this.buildAnomalyColumn(AnomalyColumns.LIVRAISON_TRANCH_DISTANCE),
                    this.buildAnomalyColumn(AnomalyColumns.AUGMENTATION_VOLUME, [], true, true),
                ])
                .concat(this.getRateCommonColumns())
        );
    }

    private buildR8() {
        return this.buildRegle(
            'R8',
            this.getStatutCommonColumns()
                .concat([
                    this.buildAnomalyColumn(AnomalyColumns.TRANSPORTEURS_NOM),
                    this.buildAnomalyColumn(AnomalyColumns.SITE_DE_DEPART_ANALYSE),
                    this.buildAnomalyColumn(AnomalyColumns.JOUR_DE_DEPART),
                    this.buildAnomalyColumn(AnomalyColumns.DATE_DE_DEPART),
                    this.buildAnomalyColumn(AnomalyColumns.LIVRAISON_TRANCH_DISTANCE),
                ])
                .concat(this.getRateCommonColumns())
        );
    }

    private buildR9() {
        return this.buildRegle(
            'R9',
            this.getStatutCommonColumns()
                .concat([
                    this.buildAnomalyColumn(AnomalyColumns.TRANSPORTEURS_NOM),
                    this.buildAnomalyColumn(AnomalyColumns.SITE_DE_DEPART_ANALYSE),
                    this.buildAnomalyColumn(AnomalyColumns.JOURSFERIER_ENTRE_EXP_LIV),
                    this.buildAnomalyColumn(AnomalyColumns.DEPARTEMENT_DE_DESTINATION, ['text-right'], true),
                ])
                .concat(this.getRateCommonColumns())
        );
    }

    private buildR12() {
        return this.buildRegle(
            'R12',
            this.getStatutCommonColumns()
                .concat([
                    this.buildAnomalyColumn(AnomalyColumns.TRANSPORTEURS_NOM),
                    this.buildAnomalyColumn(AnomalyColumns.SITE_DE_DEPART_ANALYSE),
                    this.buildAnomalyColumn(AnomalyColumns.DEPARTEMENT_DE_DESTINATION, ['text-right'], true),
                ])
                .concat(this.getRateCommonColumns())
        );
    }

    private buildR13() {
        return this.buildRegle(
            'R13',
            this.getStatutCommonColumns()
                .concat([
                    this.buildAnomalyColumn(AnomalyColumns.TRANSPORTEURS_NOM),
                    this.buildAnomalyColumn(AnomalyColumns.SITE_DE_DEPART_ANALYSE),
                    this.buildAnomalyColumn(AnomalyColumns.DEPARTEMENT_DE_DESTINATION, ['text-right'], true),
                    this.buildAnomalyColumn(AnomalyColumns.VILLE_ARRIVEE),
                ])
                .concat(this.getRateCommonColumns())
        );
    }

    private buildR14() {
        return this.buildRegle(
            'R14',
            this.getStatutCommonColumns()
                .concat([
                    this.buildAnomalyColumn(AnomalyColumns.TRANSPORTEURS_NOM),
                    this.buildAnomalyColumn(AnomalyColumns.SITE_DE_DEPART_ANALYSE),
                    this.buildAnomalyColumn(AnomalyColumns.DEPARTEMENT_DE_DESTINATION, ['text-right'], true),
                    this.buildAnomalyColumn(AnomalyColumns.VILLE_ARRIVEE),
                    this.buildAnomalyColumn(AnomalyColumns.ENSEIGNE_LIBELLE),
                    this.buildAnomalyColumn(AnomalyColumns.LIBELLE_CLIENT),
                    this.buildAnomalyColumn(AnomalyColumns.CODE_CLIENT),
                ])
                .concat(this.getRateCommonColumns())
        );
    }

    private buildR15() {
        return this.buildRegle(
            'R15',
            this.getStatutCommonColumns()
                .concat([
                    this.buildAnomalyColumn(AnomalyColumns.TRANSPORTEURS_NOM),
                    this.buildAnomalyColumn(AnomalyColumns.SITE_DE_DEPART_ANALYSE),
                    this.buildAnomalyColumn(AnomalyColumns.JOUR_DE_LIVRAISON_THEORIQUE),
                    this.buildAnomalyColumn(AnomalyColumns.DEPARTEMENT_DE_DESTINATION, ['text-right'], true),
                    this.buildAnomalyColumn(AnomalyColumns.VILLE_ARRIVEE),
                    this.buildAnomalyColumn(AnomalyColumns.ENSEIGNE_LIBELLE),
                    this.buildAnomalyColumn(AnomalyColumns.LIBELLE_CLIENT),
                    this.buildAnomalyColumn(AnomalyColumns.CODE_CLIENT),
                ])
                .concat(this.getRateCommonColumns())
        );
    }

    private buildAnomalyColumn(
        designation: string,
        theadClasses: string[] = [],
        isNumber: boolean = false,
        isPercent: boolean = false
    ): AnomalyColumn {
        return {designation, theadClasses, isNumber, isPercent};
    }

    private buildRegle(numRegle: string, columns: AnomalyColumn[]): Regle {
        return {numRegle, columns};
    }
}
