<sai-page-title title="{{ 'recommendations.title.selection' | translate }}"></sai-page-title>
<sai-monther (periodSelected)="updatePeriod($event)"></sai-monther>
<div class="container-fluid px-5 mt-2">
    <div class="row">
        <div class="col">
            <sai-moderation-filters></sai-moderation-filters>
        </div>
    </div>

    <sai-loading-circle [loading]="loading"></sai-loading-circle>

    <ng-container *ngIf="!loading">
        <ng-container *ngIf="filteredRecommendations?.length > 0">
            <div class="row table-responsive-xl">
                <div class="col">
                    <table class="table table-hover table-sm table-sticky" id="table-moderation">
                        <thead class="thead-light">
                        <tr>
                            <th (click)="sortTable('num_regle')">
                                {{ "anomaly.label.num_regle" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort': cols['num_regle'] == -1,
                                            'fa-sort-up': cols['num_regle'] % 2 == 1,
                                            'fa-sort-down': cols['num_regle'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('statut')">
                                {{ "anomaly.label.statut" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['statut'] % 2 == 1,
                                            'fa-sort-down': cols['statut'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('aussi_present')">
                                {{ "anomaly.label.aussi_present" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['aussi_present'] % 2 == 1,
                                            'fa-sort-down': cols['aussi_present'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('transporteurs_nom')">
                                {{ "anomaly.label.transporteurs_nom" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['transporteurs_nom'] % 2 == 1,
                                            'fa-sort-down': cols['transporteurs_nom'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('site_de_depart_analyse')">
                                {{ "anomaly.label.site_de_depart_analyse" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['site_de_depart_analyse'] % 2 == 1,
                                            'fa-sort-down': cols['site_de_depart_analyse'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('jour_de_depart')">
                                {{ "anomaly.label.jour_de_depart" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['jour_de_depart'] % 2 == 1,
                                            'fa-sort-down': cols['jour_de_depart'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('date_de_depart')">
                                {{ "anomaly.label.date_de_depart" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['date_de_depart'] % 2 == 1,
                                            'fa-sort-down': cols['date_de_depart'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('livraison_tranch_distance')">
                                {{ "anomaly.label.livraison_tranch_distance" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['livraison_tranch_distance'] % 2 == 1,
                                            'fa-sort-down': cols['livraison_tranch_distance'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('augmentation_volume')" class="text-right">
                                {{ "anomaly.label.augmentation_volume" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['augmentation_volume'] % 2 == 1,
                                            'fa-sort-down': cols['augmentation_volume'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('joursferier_entre_exp_liv')">
                                {{ "anomaly.label.joursferier_entre_exp_liv" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['joursferier_entre_exp_liv'] % 2 == 1,
                                            'fa-sort-down': cols['joursferier_entre_exp_liv'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('jour_de_livraison_theorique')">
                                {{ "anomaly.label.jour_de_livraison_theorique" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['jour_de_livraison_theorique'] % 2 == 1,
                                            'fa-sort-down': cols['jour_de_livraison_theorique'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('departement_de_destination')">
                                {{ "anomaly.label.departement_de_destination" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['departement_de_destination'] % 2 == 1,
                                            'fa-sort-down': cols['departement_de_destination'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('ville_arrivee')">
                                {{ "anomaly.label.ville_arrivee" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['ville_arrivee'] % 2 == 1,
                                            'fa-sort-down': cols['ville_arrivee'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('enseigne_libelle')">
                                {{ "anomaly.label.enseigne_libelle" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['enseigne_libelle'] % 2 == 1,
                                            'fa-sort-down': cols['enseigne_libelle'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('libelle_client')">
                                {{ "anomaly.label.libelle_client" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['libelle_client'] % 2 == 1,
                                            'fa-sort-down': cols['libelle_client'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('code_client')">
                                {{ "anomaly.label.code_client" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['code_client'] % 2 == 1,
                                            'fa-sort-down': cols['code_client'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('enjeu_global')" class="text-right">
                                {{ "anomaly.label.enjeu_global" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['enjeu_global'] % 2 == 1,
                                            'fa-sort-down': cols['enjeu_global'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('exp_en_retard')" class="text-right">
                                {{ "anomaly.label.exp_en_retard" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['exp_en_retard'] % 2 == 1,
                                            'fa-sort-down': cols['exp_en_retard'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('taux_retard')" class="text-right">
                                {{ "anomaly.label.taux_retard" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['taux_retard'] % 2 == 1,
                                            'fa-sort-down': cols['taux_retard'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('rst')" class="text-right">
                                {{ "anomaly.label.rst" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['rst'] % 2 == 1,
                                            'fa-sort-down': cols['rst'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th
                                (click)="sortTable('exp_en_retard_hors')"
                                class="bg-secondary text-white border-left text-right"
                            >
                                {{ "anomaly.label.exp_en_retard_hors" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['exp_en_retard_hors'] % 2 == 1,
                                            'fa-sort-down': cols['exp_en_retard_hors'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th (click)="sortTable('taux_retard_hors')" class="bg-secondary text-white text-right">
                                {{ "anomaly.label.taux_retard_hors" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up': cols['taux_retard_hors'] % 2 == 1,
                                            'fa-sort-down': cols['taux_retard_hors'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th
                                (click)="sortTable('deff_taux_retard_pattern_taux_retard_hors_pattern')"
                                class="bg-secondary text-white border-right text-right"
                            >
                                {{ "anomaly.label.deff_taux_retard_pattern_taux_retard_hors_pattern" | translate }}
                                <i
                                    [ngClass]="{
                                            'fa-sort-up':
                                                cols['deff_taux_retard_pattern_taux_retard_hors_pattern'] % 2 == 1,
                                            'fa-sort-down':
                                                cols['deff_taux_retard_pattern_taux_retard_hors_pattern'] % 2 == 0
                                        }"
                                    class="fa"
                                ></i>
                            </th>
                            <th class="text-center">{{ "recommendations.table.visible" | translate }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            *ngFor="let reco of filteredRecommendations"
                            [ngClass]="{ 'table-primary': reco.recommended, 'table-active': currentRow.loading }"
                            (click)="changeVisibilityFor(reco, currentRow)"
                            #currentRow
                        >
                            <td>{{ reco.anomaly.num_regle }}</td>
                            <td>{{ reco.anomaly.statut }}</td>
                            <td>{{ reco.anomaly.aussi_present }}</td>
                            <td>{{ reco.anomaly.transporteurs_nom }}</td>
                            <td>{{ reco.anomaly.site_de_depart_analyse }}</td>
                            <td>{{ reco.anomaly.jour_de_depart }}</td>
                            <td>{{ reco.anomaly.date_de_depart }}</td>
                            <td>{{ reco.anomaly.livraison_tranch_distance }}</td>
                            <td>{{ reco.anomaly.augmentation_volume | percent }}</td>
                            <td>{{ reco.anomaly.joursferier_entre_exp_liv }}</td>
                            <td>{{ reco.anomaly.jour_de_livraison_theorique }}</td>
                            <td>{{ reco.anomaly.departement_de_destination }}</td>
                            <td>{{ reco.anomaly.ville_arrivee }}</td>
                            <td>{{ reco.anomaly.enseigne_libelle }}</td>
                            <td>{{ reco.anomaly.libelle_client }}</td>
                            <td>{{ reco.anomaly.code_client }}</td>
                            <td class="text-right">{{ reco.anomaly.enjeu_global | percent: "1.0-2" }}</td>
                            <td class="text-right">{{ reco.anomaly.exp_en_retard }}</td>
                            <td class="text-right">{{ reco.anomaly.taux_retard | percent }}</td>
                            <td class="text-right">{{ reco.anomaly.rst }}</td>
                            <td class="text-right border-left">{{ reco.anomaly.exp_en_retard_hors }}</td>
                            <td class="text-right">{{ reco.anomaly.taux_retard_hors | percent }}</td>
                            <td class="text-right border-right">
                                {{ reco.anomaly.deff_taux_retard_pattern_taux_retard_hors_pattern | percent }}
                            </td>
                            <td class="text-center">
                                    <span
                                        [hidden]="currentRow.loading"
                                        [ngClass]="{
                                            badge: true,
                                            'badge-danger': !reco.recommended,
                                            'badge-primary': reco.recommended
                                        }"
                                    >{{ "recommendations.table.recommended." + reco.recommended | translate }}
                                        <i
                                            [ngClass]="{
                                                'fas fa-fw': true,
                                                'fa-check': reco.recommended,
                                                'fa-times': !reco.recommended
                                            }"
                                        ></i>
                                    </span>
                                <i [hidden]="!currentRow.loading" class="fas fa-sync fa-spin text-dark"></i>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="filteredRecommendations?.length === 0">
            <div class="col alert alert-info">{{ "alert.no_anomalies_available" | translate }}</div>
        </ng-container>
    </ng-container>
</div>
