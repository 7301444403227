import {Component, OnInit} from '@angular/core';
import {ActionReport} from 'src/app/actions/model/action-report.model';
import {ActionRecapService} from '../../services/action-recap.service';

@Component({
    selector: 'sai-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.sass']
})
export class BadgeComponent implements OnInit {
    public overdueAction: number;

    constructor(private actionRecapService: ActionRecapService) {
        this.actionRecapService.actionReport.subscribe(action => {
            this.overdueAction = action.nbOverdueInvestigations;
        });
    }

    ngOnInit(): void {

    }
}
