<div class="admin-container">

    <sai-management-title [titleText]="'management.user.title' | translate"></sai-management-title>

    <ng-container *ngIf="user">

        <div class="row mb-3">

            <div class="form-group col-12">
                <label>{{ "management.user.table.email" | translate }} :</label>
                <input type="email"
                       class="form-control"
                       [disabled]="!isCreationMode"
                       [(ngModel)]="user.email"/>
                <ng-container
                    *ngIf="(user.email && !isAValidEmail(user.email))">
                    <span class="text-danger">{{ "management.user.creation.email not valid" | translate }}</span></ng-container>
            </div>

            <div class="form-group col-12">
                <label>{{ "management.user.table.firstName" | translate }} :</label>
                <input type="email"
                       class="form-control"
                       [(ngModel)]="user.firstname"/>
            </div>

            <div class="form-group col-12">
                <label>{{ "management.user.table.lastName" | translate }} :</label>
                <input type="email"
                       class="form-control"
                       [(ngModel)]="user.lastname"/>
            </div>

            <div class="form-group col-12">
                <label>{{ "management.user.table.role" | translate }} :</label>
                <ng-select
                    [items]="roles"
                    bindLabel="name"
                    bindValue="name"
                    [(ngModel)]="user.role"
                >
                </ng-select>
            </div>

            <div class="form-group col-12">
                <label>{{ "management.user.table.company code" | translate }} :</label>
                <ng-select
                    [items]="companyCodes"
                    bindLabel="companyLabel"
                    bindValue="companyCode"
                    placeholder="{{ 'management.user.detail.select' | translate }}"
                    id="companyCodes"
                    [(ngModel)]="user.companyCode"
                >
                </ng-select>
            </div>

            <div class="form-group col-12">
                <label>{{ "management.user.table.accessible carriers" | translate }} :</label>
                <ng-select
                    [items]="carriers"
                    bindLabel="transporteurs_nom"
                    bindValue="transporteurs_nom"
                    placeholder="{{ 'management.user.detail.all' | translate }}"
                    multiple="true"
                    id="carriers"
                    [(ngModel)]="user.visibleCarriers"
                >
                </ng-select>
            </div>

            <div class="form-group col-auto">
                <button class="btn btn-success" (click)="saveUser()" [ngClass]="{ disabled: isSavingUser }">
                    <span [hidden]="isSavingUser">
                        <span *ngIf="isCreationMode; else btnEdition">
                        <i class="fas fa-user-plus fa-fw"></i>
                            {{ "management.user.creation.add user" | translate }}</span>
                        <ng-template #btnEdition>{{ "management.user.detail.save" | translate }}</ng-template>
                    </span>
                    <i [hidden]="!isSavingUser" class="fas fa-sync fa-spin"></i>
                </button>
            </div>
        </div>
    </ng-container>
</div>
