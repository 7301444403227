<div ngbDropdown>
    <div ngbDropdownToggle class="dropdown-toggle">
        <input
            placeholder="filtre"
            [ngModel]="['constant.temporality.' + initTemporality | translate] + ' ' + [dpSelectedDate | date: 'mediumDate']"
            class="form-control form-control-sm dropdown-toggle"
            data-toggle="dropdown"
            type=""
        />
    </div>

    <div ngbDropdownMenu>
        <div class="px-3 py-3">
            <select class="custom-select" [(ngModel)]="initTemporality">
                <option [ngValue]="-1">{{ "constant.temporality." + -1 | translate }}</option>
                <option [ngValue]="0">{{ "constant.temporality." + 0 | translate }}</option>
                <option [ngValue]="1">{{ "constant.temporality." + 1 | translate }}</option>
            </select>
        </div>

        <div class="px-3 py-2">
            <bs-datepicker-inline (bsValueChange)="onSelectedDateChange($event)"></bs-datepicker-inline>
        </div>
    </div>
</div>
