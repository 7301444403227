import {Filter} from './filter.model';

export class AnomalyFilters {
    carrierFilters: Filter[];
    enseigneFilters: Filter[];
    departureSiteFilters: Filter[];
    departmentFilters: Filter[];

    constructor() {
        this.carrierFilters = [];
        this.enseigneFilters = [];
        this.departureSiteFilters = [];
        this.departmentFilters = [];
    }
}
