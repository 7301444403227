import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Anomaly} from '../model/anomaly.model';
import {Investigation} from '../model/investigation.model';
import {AnomalyService} from '../services/anomaly.service';
import {Action} from '../model/action.model';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {KnownAccountableService} from '../services/known-accountable.service';

@Component({
    selector: 'sai-actions-detail',
    templateUrl: './actions-detail.component.html',
    styleUrls: ['./actions-detail.component.sass'],
})
export class ActionsDetailComponent implements OnInit {
    @Input() anomaly: Anomaly;
    @Input() showInvestigationList: boolean;

    @Output() addInvestigationCompleted = new EventEmitter<Investigation>();

    public investigation: Investigation;
    public investigationReleaseDate: string;
    public investigationDeadline: string;
    public loadingInvestigations: boolean;
    public isAddingInvestigation: boolean;

    public investigations: Investigation[];
    public possibleActions: Action[];
    public investigationForm: FormGroup;

    constructor(
        public anomalyService: AnomalyService,
        public datepickerFormatter: NgbDateParserFormatter,
        public fBuilder: FormBuilder,
        private knownAccountableService: KnownAccountableService
    ) {
        this.initInvestigationForm();
    }

    ngOnInit(): void {
        this.investigation = {} as Investigation;
        this.loadingInvestigations = true;
        this.anomalyService
            .getInvestigationsFor(this.anomaly)
            .then((investigations) => (this.investigations = investigations))
            .finally(() => (this.loadingInvestigations = false));
        this.anomalyService.getPossibleActionsFor(this.anomaly).then((actions) => {
            this.possibleActions = actions;
        });

        this.investigationReleaseDate = moment().format('YYYY-MM-DD');
    }

    public initInvestigationForm(): void {
        this.investigationForm = this.fBuilder.group(
            {
                action: ['', Validators.required],
                investigationReleaseDate: ['', Validators.required],
                investigationDeadline: ['', Validators.required],
                responsable: [''],
                commentaire_action_libre: [''],
                responsable_transporteur_service: [''],
                responsable_transporteur_nom: [''],
            },
            {
                validators: [this.checkDate, this.validationConditionalOption],
            }
        );
    }

    public checkDate(group: FormGroup) {
        const releaseDate = group.get('investigationReleaseDate').value;
        const deadlineDate = group.get('investigationDeadline').value;
        const dld = moment(deadlineDate);
        const rld = moment(releaseDate);
        return dld.isBefore(rld) ? {dateError: true} : null;
    }

    public validationConditionalOption(group: FormGroup) {
        const commentAction = group.get('commentaire_action_libre').value;
        const idAction = group.get('action').value;
        return idAction === 0 && !commentAction ? {commentError: true} : null;
    }

    public addInvestigation(): void {
        this.isAddingInvestigation = true;
        this.investigation.date_lancement = this.investigationReleaseDate;
        this.investigation.date_deadline = this.investigationDeadline;
        this.investigation.anomalie_pattern = this.anomaly.anomalie_pattern;
        this.investigation.idaction_list = this.investigationForm.get('action').value;

        // Renseignement de l'action choisie pour la retrouver après le retour du serveur
        const chosenAction = this.possibleActions.find((action) => {
            return action.idactions_list.toString() === this.investigation.idaction_list.toString();
        });

        // On ne rend pas le responsable obligatoire, mais on le met par défaut au transporteur
        if (!this.investigation.responsable) {
            this.investigation.responsable = this.knownAccountableService.CARRIER;
        }

        this.anomalyService
            .addInvestigation(this.investigation)
            .then((investigation) => {
                if (chosenAction) {
                    investigation.action_desc = chosenAction.action_desc;
                }
                this.investigations.splice(0, 0, investigation);

                this.addInvestigationCompleted.emit(investigation);
            })
            .finally(() => (this.isAddingInvestigation = false));
    }
}
