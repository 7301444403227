import {Injectable} from '@angular/core';

@Injectable()
export class KnownAccountableService {
    constructor() {
    }

    public readonly CARRIER = 'carrier';
    public readonly TRANSPORT_MANAGER = 'transportManager';
    public readonly SALES = 'sales';
    public readonly QA = 'QA';
    public readonly TRANSPORT_PLAN_MANAGER = 'transportPlanManager';
    public readonly LOCAL_DIRECTOR = 'localDirector';

    public isKnownAccountable(accountable: string) {
        return this.getAllKnownAccountables().includes(accountable);
    }

    public getAllKnownAccountables(): string[] {
        return [
            this.CARRIER,
            this.TRANSPORT_MANAGER,
            this.SALES,
            this.QA,
            this.TRANSPORT_PLAN_MANAGER,
            this.LOCAL_DIRECTOR,
        ];
    }
}
