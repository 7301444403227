<div class="row justify-content-center">
    <div class="col-auto mx-3 legend1">
        {{ "home.low" | translate }}
    </div>

    <div class="col-auto mx-3 legend2">
        {{ "home.medium" | translate }}
    </div>

    <div class="col-auto mx-3 legend3">
        {{ "home.high" | translate }}
    </div>
</div>
