export class Endpoints {
    public base = '';
    public ME = this.base + '/api/me';
    public LOGOUT = this.base + '/api/logout';
    public RECOMMENDATIONS = this.base + '/api/recommendations';
    public RECOMMENDED = this.base + '/api/recommended';
    public INVESTIGATIONS = this.base + '/api/investigations';
    public INVESTIGATIONS_FOR_PATTERN = this.base + '/api/investigations/pattern';
    public REGLES = this.base + `/api/regles`;
    public ACTIONS_FOR_REGLE = this.base + '/api/actions/regle';
    public ACTIONS = this.base + '/api/actions';
    public ACTION_ADD_RESPONSE = this.base + '/api/actions/add-response';
    public ACTION_DETAILS = this.base + '/api/actions/details';
    public ANOMALY_SHIPPING_NUMBER = this.base + '/api/anomalies/shipping-numbers';
    public ANOMALY_SOLDE_DE_CAUSE = this.base + '/api/anomalies/solde-cause';
    public EVENTS_R1 = this.base + '/api/events/r1';
    public EVENTS_R3 = this.base + '/api/events/r3';
    public SOLDER_ANOMALY = this.base + '/api/anomalies/solde';
    public REPORT_ACTIONS = this.base + '/api/report/actions';
    public ANOMALY_MONTHLY_SUMMARY = this.base + '/api/anomalies/summary';
    public REFE_FILTERS_ANOMALY = this.base + '/api/refe/filters/anomaly';
    public REFE_CARRIERS = this.base + '/api/refe/carriers';
    public REFE_COMPANY_CODES = this.base + '/api/refe/company-codes';
    public USERS = this.base + '/api/users';
    public USER_BY_EMAIL = this.base + '/api/users/q/email';
    public USER_BY_ID = this.base + '/api/users/q/id';
    public USER_DEACTIVATE = this.base + '/api/users/deactivate';
    public USER_REACTIVATE = this.base + '/api/users/reactivate';
    public USER_DELETE = this.base + '/api/users/delete';
    public ROLES = this.base + '/api/rights/roles';
    public ROLES_ATTRIBUTABLE = this.base + '/api/rights/roles/attributable';
    public PERMISSIONS = this.base + '/api/rights/permissions';
    public PERMISSION_TO_ROLE = this.base + '/api/rights/permission-to-role';
}
