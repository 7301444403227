import {Component, Input, OnInit} from '@angular/core';
import {Event} from 'src/app/anomalies/model/event.model';
import {Period} from '../monther/period.model';


@Component({
    selector: 'sai-table-r1',
    templateUrl: './table-r1.component.html',
    styleUrls: ['./table-r1.component.sass'],
})
export class TableR1Component implements OnInit {
    @Input() eventR1: Event[];
    @Input() periodSelected: Period;


    constructor() {
    }

    ngOnInit(): void {
    }
}
