import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {UserService} from 'src/app/user/user.service';
import {EnvironmentService} from "../services/environment.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(public userService: UserService, private env: EnvironmentService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                // Le back retourne spécifiquement une 401
                if (error.status === 401) {
                    const redirectUrl = `${error.headers.get('sightness-login')}`;

                    // Si l'objet est définis on est sûr qu'il s'agit d'un refus du back
                    if (redirectUrl) {
                        this.userService.removeConnectedUser();
                        const redirectUri = encodeURIComponent(`${location.origin}${location.pathname}`);
                        document.location.href = `${redirectUrl}?redirectUri=${redirectUri}&m=${this.env.tokenReturnMethod}`;
                    }
                }

                return throwError(error);
            })
        );
    }
}
