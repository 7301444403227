import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {RefeCarrier} from 'src/app/shared/referential/refe-carrier.model';
import {RefeRegle} from 'src/app/shared/referential/refe-regle.model';
import {Endpoints} from 'src/app/shared/services/endpoint.constant';
import {ModerationFilters} from '../model/moderation-filters.model';

@Injectable()
export class ModerationFilterService {
    private endpoints: Endpoints;

    private moderationFiltersSource: BehaviorSubject<ModerationFilters>;
    public moderationFilters: Observable<ModerationFilters>;

    constructor(private http: HttpClient) {
        this.endpoints = new Endpoints();
        this.initFilterForRegle();
    }

    private async initFilterForRegle() {
        this.moderationFiltersSource = new BehaviorSubject<ModerationFilters>(new ModerationFilters());
        this.moderationFilters = this.moderationFiltersSource.asObservable();

        this.moderationFiltersSource.next(await this.getFiltersForRegle());
    }

    public filterHasBeenSelected(filters: ModerationFilters) {
        this.moderationFiltersSource.next(filters);
    }

    public async getFiltersForRegle(): Promise<ModerationFilters> {
        const wantedModerationFilters = new ModerationFilters();

        wantedModerationFilters.regleFilters = await this.http
            .get<RefeRegle[]>(this.endpoints.REGLES)
            .toPromise()
            .then((refeRegle: RefeRegle[]) => {
                return refeRegle
                    .sort(
                        (a, b) =>
                            this.getSafeNumRegleAsIntFrom(a.num_regle) - this.getSafeNumRegleAsIntFrom(b.num_regle)
                    )
                    .map((regle) => ({
                        label: regle.num_regle,
                        nbRecord: 0,
                        selected: false,
                        value: regle.num_regle,
                    }));
            });

        wantedModerationFilters.carrierFilters = await this.http
            .get<RefeCarrier[]>(this.endpoints.REFE_CARRIERS)
            .toPromise()
            .then((refeCarrier: RefeCarrier[]) => {
                return refeCarrier.map((carrier) => ({
                    label: carrier.transporteurs_nom,
                    nbRecord: 0,
                    selected: false,
                    value: carrier.transporteurs_nom,
                }));
            });

        return wantedModerationFilters;
    }

    private getSafeNumRegleAsIntFrom(numRegleWithPrefix: string): number {
        if (!isNaN(Number(numRegleWithPrefix.substring(1)))) {
            return Number(numRegleWithPrefix.substring(1));
        } else {
            return 0;
        }
    }
}
