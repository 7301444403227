import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home/home.component';
import {RecommendationsSelectionComponent} from './anomalies/recommendations-selection/reco-selection.component';
import {RecommendationsVisualizationComponent} from './anomalies/recommendations-visualization/reco-visu.component';
import {ActionsMonitoringComponent} from './actions/actions-monitoring/actions-monitoring.component';
import {DashboardComponent} from './actions/dashboard/dashboard.component';
import {EventsComponent} from './anomalies/events/events.component';
import {UserDetailComponent} from '@app/management/user-detail/user-detail.component';
import {LexiconComponent} from './anomalies/lexicon/lexicon.component';
import {ManagementMainComponent} from "@app/management/management-main/management-main.component";
import {UsersManagementComponent} from "@app/management/users-management/users-management.component";
import {RightManagementComponent} from "@app/management/right-management/right-management.component";

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: 'home', component: HomeComponent},
    {path: 'recommendations-selection', component: RecommendationsSelectionComponent},
    {path: 'recommendations-visualization', component: RecommendationsVisualizationComponent},
    {path: 'lexicon', component: LexiconComponent},
    {path: 'actions-monitoring', component: ActionsMonitoringComponent},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'events', component: EventsComponent},
    {path: 'management', component: ManagementMainComponent},
    {path: 'management/users', component: UsersManagementComponent},
    {path: 'management/user', component: UserDetailComponent},
    {path: 'management/rights', component: RightManagementComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
