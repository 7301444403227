<nav class="navbar navbar-dark bg-dark bg-darken shadow-lg m-6">
    <a class="navbar-brand mb-0 h1 p-0" height="30" [routerLink]="['/home']">
        <img class="align-top mr-3" src="./assets/images/brand/logo-right.png" alt="" height="30"/>
        {{ "header.satisfai" | translate }}
    </a>

    <div dropdown class="dropdown pt-1">
        <span dropdownToggle class="dropdown-toggle">
            <i class="header-icon icon-user-circle" style="font-size: 1.8rem;"></i>
        </span>

        <div *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <h6 class="dropdown-header">{{ "header.personn and role" | translate: user | titlecase}}</h6>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()">{{ "header.logout" | translate }}</a>
        </div>
    </div>

</nav>
<sai-nav></sai-nav>
