<div class="row">
    <h4 class="col-12 text-muted">
        {{ "recommendations.actions-detail.title.add investigation" | translate }}
    </h4>
    <form class="col-12" [formGroup]="investigationForm">
        <div class="form-row">
            <div
                class="form-group"
                [ngClass]="{
                    'col-2': investigationForm.controls['action'].value === '0',
                    col: investigationForm.controls['action'].value !== '0'
                }"
            >
                <label>{{ "recommendations.actions-detail.form.action" | translate }}</label>
                <select class="custom-select" formControlName="action">
                    <option *ngFor="let possibleAction of possibleActions" [value]="possibleAction.idactions_list">
                        {{ possibleAction.action_desc }}
                    </option>
                    <option [value]="0" class="font-italic">
                        {{ "recommendations.actions-detail.form.free input" | translate }}
                    </option>
                </select>
            </div>

            <ng-container *ngIf="investigationForm.controls['action'].value === '0'">
                <div class="form-group col mt-auto">
                    <input
                        class="form-control"
                        formControlName="commentaire_action_libre"
                        placeholder="{{ 'recommendations.actions-detail.form.enter action' | translate }}"
                        [(ngModel)]="investigation.commentaire_action_libre"
                        [ngClass]="{
                            'is-invalid':
                                investigationForm.hasError('commentError') &&
                                investigationForm.controls['commentaire_action_libre'].touched
                        }"
                    />
                </div>
            </ng-container>
            <div class="form-group col-auto">
                <label>{{ "recommendations.actions-detail.table.release date" | translate }}</label>
                <input
                    class="form-control"
                    type="date"
                    formControlName="investigationReleaseDate"
                    [(ngModel)]="investigationReleaseDate"
                />
            </div>
            <div class="form-group col-auto">
                <label>{{ "recommendations.actions-detail.table.deadline" | translate }}</label>
                <input
                    class="form-control"
                    type="date"
                    formControlName="investigationDeadline"
                    [(ngModel)]="investigationDeadline"
                    [ngClass]="{ 'is-invalid': investigationForm.hasError('dateError') }"
                />
            </div>
            <div *saiHasRight="'CAN_READ_SHIPPER_DATA'" class="form-group col-auto">
                <label>{{ "recommendations.actions-detail.table.accountable.label" | translate }}</label>
                <select
                    class="custom-select form-control"
                    formControlName="responsable"
                    [(ngModel)]="investigation.responsable"
                >
                    <option value="transportManager">
                        {{ "recommendations.actions-detail.table.accountable.transportManager" | translate }}
                    </option>
                    <option value="sales">
                        {{ "recommendations.actions-detail.table.accountable.sales" | translate }}
                    </option>
                    <option value="carrier">
                        {{ "recommendations.actions-detail.table.accountable.carrier" | translate }}
                    </option>
                </select>
            </div>
            <div *saiHasRight="'CAN_READ_CARRIER_DATA'" class="form-group col-auto">
                <label>{{ "recommendations.actions-detail.table.accountability.label" | translate }}</label>
                <select
                    class="custom-select form-control"
                    formControlName="responsable_transporteur_service"
                    [(ngModel)]="investigation.responsable_transporteur_service"
                >
                    <option value="QA">
                        {{ "recommendations.actions-detail.table.accountability.QA" | translate }}
                    </option>
                    <option value="transportPlanManager">
                        {{ "recommendations.actions-detail.table.accountability.transportPlanManager" | translate }}
                    </option>
                    <option value="localDirector">
                        {{ "recommendations.actions-detail.table.accountability.localDirector" | translate }}
                    </option>
                </select>
            </div>
            <div *saiHasRight="'CAN_READ_CARRIER_DATA'" class="form-group col-auto">
                <label>{{ "recommendations.actions-detail.table.name" | translate }}</label>
                <input
                    formControlName="responsable_transporteur_nom"
                    class="form-control"
                    [(ngModel)]="investigation.responsable_transporteur_nom"
                />
            </div>
            <div class="form-group col-auto mt-auto">
                <button
                    class="btn btn-success"
                    [disabled]="investigationForm.invalid"
                    (click)="addInvestigation()"
                    [ngClass]="{ disabled: isAddingInvestigation }"
                >
                    <span [hidden]="isAddingInvestigation">{{
                        "recommendations.actions-detail.form.add" | translate
                        }}</span>
                    <i [hidden]="!isAddingInvestigation" class="fas fa-sync fa-spin"></i>
                </button>
            </div>
        </div>
    </form>
</div>

<ng-container *ngIf="showInvestigationList">
    <div class="row">
        <h4 class="col-12">
            <span class="text-muted">{{ "recommendations.actions-detail.title.investigation list" | translate }}</span>
            <i *ngIf="loadingInvestigations" class="fas fa-fw fa-sync fa-spin text-info"></i>
        </h4>

        <ng-container *ngIf="investigations">
            <ng-container *ngIf="investigations.length > 0; else noRowBlock">
                <div class="col-12">
                    <table class="table table-sm table-dark table-bordered table-striped mb-0">
                        <thead>
                        <tr class="">
                            <th>{{ "recommendations.actions-detail.table.action" | translate }}</th>
                            <th>{{ "recommendations.actions-detail.table.release date" | translate }}</th>
                            <th>{{ "recommendations.actions-detail.table.deadline" | translate }}</th>
                            <th>{{ "recommendations.actions-detail.table.accountable.label" | translate }}</th>
                            <th>{{ "recommendations.actions-detail.table.status" | translate }}</th>
                            <th>{{ "recommendations.actions-detail.table.response" | translate }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let investigation of investigations">
                            <td>
                                <sai-action-to-do [action]="investigation"></sai-action-to-do>
                            </td>
                            <td>{{ investigation.date_lancement | date: "mediumDate" }}</td>
                            <td>
                                <sai-action-deadline [action]="investigation"></sai-action-deadline>
                            </td>
                            <td>
                                <sai-action-accountable
                                    [accountable]="investigation.responsable"
                                ></sai-action-accountable>
                            </td>
                            <td>
                                <sai-action-status [action]="investigation"></sai-action-status>
                            </td>
                            <td>
                                <sai-action-response
                                    [action]="investigation"
                                    [showAddResponseButton]="false"
                                ></sai-action-response>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div
                >
            </ng-container>
            <ng-template #noRowBlock>
                <div class="col-12">
                    <p class="alert alert-info">
                        {{ "recommendations.actions-detail.no investigation" | translate }}
                    </p>
                </div>
            </ng-template>
        </ng-container>
    </div>
</ng-container>
