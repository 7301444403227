<div class="admin-container">

    <sai-management-title [titleText]="'management.user.title' | translate"
                          [isLoadingSomething]="isLoadingUsers"></sai-management-title>

    <div class="row mb-3">
        <div class="col-12">
            <button class="btn btn-primary" type="button" (click)="goToUserCreation()"><i
                class="fas fa-user-plus fa-fw"></i> {{ "management.user.creation.add an user" | translate }}
            </button>
        </div>
    </div>

    <ng-container *ngIf="!isLoadingUsers">

        <div class="row mb-3">
            <div class="col">
                <input type="text" [(ngModel)]="userFilter" [placeholder]="'management.user.table.toFilter' | translate"
                       class="form-control"/>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col table-responsive">
                <table class="table table-striped table-hover mb-0">
                    <thead>
                    <tr>
                        <th class="col-auto">
                            {{ "management.user.table.firstName" | translate }}
                        </th>
                        <th class="col-auto">
                            {{ "management.user.table.lastName" | translate }}
                        </th>
                        <th class="col-auto">
                            {{ "management.user.table.email" | translate }}
                        </th>
                        <th class="col-auto">
                            {{ "management.user.table.role" | translate }}
                        </th>
                        <th class="col-auto">
                            {{ "management.user.table.company code" | translate }}
                        </th>
                        <th class="col-auto">
                            {{ "management.user.table.accessible carriers" | translate }}
                        </th>
                        <th class="col-auto text-center">
                            {{ "management.user.table.set up" | translate }}
                        </th>
                        <th class="col-auto text-center">
                            {{ "management.user.table.deactivate" | translate }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let user of findUsers()">
                        <tr>
                            <td>{{ user.firstname }}</td>
                            <td>{{ user.lastname }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.role }}</td>
                            <td>{{ getCorrespondingCompanyLabel(user.companyCode) }}</td>
                            <td>
                                <ng-container *ngIf="user.visibleCarriers.length === 0">{{
                                    "management.user.detail.all" | translate
                                    }}</ng-container>
                                {{ user.visibleCarriers.join(", ") }}
                            </td>
                            <td class="text-center">
                                <ng-container *ngIf="canUpdate(user)">
                                    <button *ngIf="!user.deactivated" class="btn btn-outline-primary btn-sm"
                                            (click)="goToUserDetail(user)">
                                        <i class="fas fa-user-edit"></i>
                                    </button>
                                </ng-container>
                            </td>
                            <td class="text-center">
                                <ng-container *ngIf="canUpdate(user)">
                                    <button class="btn btn-sm" [ngClass]="user.deactivated ? 'btn-info' : 'btn-warning'"
                                            (click)="deactivateUser(user)">
                                        <i class="fas fa-user-lock"></i>
                                    </button>
                                    <ng-container *ngIf="user.deactivated">
                                        <button class="btn btn-sm btn-danger ml-1"
                                                (click)="deleteUser(user)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </ng-container>
                                </ng-container>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
</div>
