import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Anomaly} from 'src/app/anomalies/model/anomaly.model';
import {AnomalyService} from 'src/app/anomalies/services/anomaly.service';
import {ActionMonitoring} from '../model/action-monitoring.model';
import {SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2';

@Component({
    selector: 'sai-anomaly-detail-dialog',
    templateUrl: './anomaly-detail-dialog.component.html',
    styleUrls: ['./anomaly-detail-dialog.component.sass'],
})
export class AnomalyDetailDialogComponent implements OnInit {
    public loadingShippingNumbers = false;
    public actionMonitoring: ActionMonitoring;

    // Le template à ouvrir comme une modal
    @ViewChild('detailBlock', {static: true}) detailBlock: ElementRef;

    // La modal actuellement ouverte
    private currentModal: NgbActiveModal;

    constructor(
        private modalService: NgbModal,
        private anomalyService: AnomalyService,
        public readonly swalTargets: SwalPortalTargets
    ) {
    }

    ngOnInit(): void {
    }

    openDialogFor(action: ActionMonitoring) {
        this.actionMonitoring = action;

        this.fulfillShippingNumbers();
        this.sortAnomalies();
        this.openDialog();
    }

    sortAnomalies() {
        // On trie par année mois
        this.actionMonitoring.anomalies.sort((a, b) => {
            return b.year_anomaly + b.month_anomaly - (a.year_anomaly + a.month_anomaly);
        });
    }

    openDialog() {
        this.currentModal = this.modalService.open(this.detailBlock, {
            size: 'xl',
            backdropClass: 'light-dialog-backdrop',
            windowClass: 'dark-dialog dialog-xl',
        });
    }

    closeDialog() {
        this.currentModal.close();
    }

    fulfillShippingNumbers() {
        this.actionMonitoring.anomalies.forEach((anomaly) => {
            this.anomalyService
                .findShippingNumbersFor(anomaly)
                .toPromise()
                .then((shippingNumber) => {
                    anomaly.shippingNumbers = shippingNumber;
                });
        });
    }

    getPeriodAsDateFor(anomaly: Anomaly): Date {
        return new Date(anomaly.year_anomaly, anomaly.month_anomaly, 1);
    }
}
