import {Component, OnInit} from '@angular/core';
import {PermanentFilter} from 'src/app/filters/model/permanent-filter.model';
import {AnomalyFilterService} from 'src/app/filters/services/anomaly-filter.service';
import {ActionRecapService} from 'src/app/shared/services/action-recap.service';
import {ActionReport} from '../model/action-report.model';
import {Router} from '@angular/router';
import {FilterConfig} from 'src/app/anomalies/model/filter-config.model';

@Component({
    selector: 'sai-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.sass'],
})
export class DashboardComponent implements OnInit {
    public actionReport: ActionReport;
    public filterRights: FilterConfig;

    constructor(
        private filterService: AnomalyFilterService,
        private sharedActionService: ActionRecapService,
        private router: Router
    ) {
        this.actionReport = {} as ActionReport;
        this.filterRights = new FilterConfig();
    }

    ngOnInit(): void {
        this.filterService.anomalyFilters.subscribe((anomalyFilters) => {
            const permanentFilters = this.filterService.buildPermanentFiltersFromAnomalyFilters(anomalyFilters);
            this.loadActionReportFor(permanentFilters);
        });

        this.filterRights.carrier = true;
        this.filterRights.department = true;
        this.filterRights.departureSite = true;
        this.filterRights.enseigne = true;
    }

    private loadActionReportFor(permanentFilter: PermanentFilter) {
        this.sharedActionService
            .getActionReportFor(permanentFilter)
            .then((actionReport) => (this.actionReport = actionReport));
    }

    public goToAllReco(status: string) {
        if (status === 'done') {
            this.router.navigate(['/actions-monitoring'], {
                queryParams: {status_done: 'done'},
            });
        } else if (status === 'cancelled') {
            this.router.navigate(['/actions-monitoring'], {
                queryParams: {status_cancelled: 'cancelled'},
            });
        } else if (status === 'inProgress') {
            this.router.navigate(['/actions-monitoring'], {
                queryParams: {status_inProgress: 'inProgress'},
            });
        } else {
            this.router.navigate(['/actions-monitoring'], {
                queryParams: {status_all: 'all'},
            });
        }
    }
}
