import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep, filter, groupBy, isEqual, sortBy} from 'lodash';
import {isAfter, isBefore, isSameDay} from 'ngx-bootstrap/chronos';
import {BehaviorSubject, Observable} from 'rxjs';
import {Investigation} from 'src/app/anomalies/model/investigation.model';
import {AnomalyFilters} from 'src/app/filters/model/anomaly-filters.model';
import {InitDate} from '../actions-date-filter/init-date.model';
import {ActionsMonitoringFilter} from '../actions-monitoring/actions-monitoring.filter';
import {ActionMonitoring} from '../model/action-monitoring.model';
import {ActionStatus} from '../model/action-status.constant';
import {ActionService} from './action.service';

@Injectable()
export class ActionsMonitoringService {
    public actionStatus: ActionStatus;

    constructor(private actionService: ActionService, private translateService: TranslateService) {
        this.actionStatus = new ActionStatus();
    }

    public async findAllActionsMonitoring(): Promise<ActionMonitoring[]> {
        return this.actionService.getAllInvestigations().then((investigations) => {
            return this.buildActionsMonitoringList(investigations);
        });
    }

    private async buildActionsMonitoringList(investigations: Investigation[]): Promise<ActionMonitoring[]> {
        let wantedActionsMonitoring = [];

        // On groupe les actions par pattern
        const actionsByAnomaly = groupBy(investigations, (action) => action.anomalie_pattern);

        for (const key of Object.keys(actionsByAnomaly)) {
            // On part du principe qu'il y a forcément une investigation sinon y'a pas de groupe possible
            const firstInvestigation = actionsByAnomaly[key][0];
            const numRegle = firstInvestigation.num_regle;
            const anomalieDesc = await this.translateService
                .get('regles.' + numRegle)
                .toPromise()
                .then((translation) => translation);

            const latestAnomaly = firstInvestigation.anomalies.sort(
                (a, b) => b.year_anomaly + b.month_anomaly - (a.year_anomaly + a.month_anomaly)
            )[0];

            const actionMonitoring: ActionMonitoring = {
                num_regle: numRegle,
                investigations: actionsByAnomaly[key],
                latest_anomaly: latestAnomaly,
                anomalie_desc: anomalieDesc,
                anomalie_pattern: firstInvestigation.anomalie_pattern,
                anomalies: firstInvestigation.anomalies,
                anomalie_characteristics: firstInvestigation.anomalies[0].anomalyCharacteristics,
            };

            wantedActionsMonitoring.push(actionMonitoring);
        }

        // Tri par numéro de règle pour les avoir dans l'ordre croissant
        wantedActionsMonitoring = sortBy(wantedActionsMonitoring, (action) => Number(action.num_regle.substring(1)));

        return wantedActionsMonitoring;
    }

    /**
     * Les filtres
     */
    public async filterActionsMonitoringFor(
        actionsMonitoringToFilter: ActionMonitoring[],
        anomalyFilters: AnomalyFilters,
        tableFilter: ActionsMonitoringFilter
    ): Promise<ActionMonitoring[]> {
        // On clone la collection de base pour ne pas modifier la collection ainsi que ses collections filles avec les filters
        let wantedActionsMonitoring = cloneDeep(actionsMonitoringToFilter || []);

        // Filtre avec les filters du haut
        const carriersToFilter = anomalyFilters.carrierFilters
            .filter((carrierFilter) => carrierFilter.selected)
            .map((carrierFilter) => carrierFilter.value);

        if (carriersToFilter.length > 0) {
            wantedActionsMonitoring = wantedActionsMonitoring.filter((action) => {
                return carriersToFilter.includes(action.latest_anomaly.transporteurs_nom);
            });
        }

        const clientsToFilter = anomalyFilters.enseigneFilters
            .filter((clientFilter) => clientFilter.selected)
            .map((clientFilter) => clientFilter.value);

        if (clientsToFilter.length > 0) {
            wantedActionsMonitoring = wantedActionsMonitoring.filter((action) => {
                return clientsToFilter.includes(action.latest_anomaly.enseigne_libelle);
            });
        }

        const departureSitesToFilter = anomalyFilters.departureSiteFilters
            .filter((departureSiteFilter) => departureSiteFilter.selected)
            .map((departureSiteFilter) => departureSiteFilter.value);

        if (departureSitesToFilter.length > 0) {
            wantedActionsMonitoring = wantedActionsMonitoring.filter((action) => {
                return departureSitesToFilter.includes(action.latest_anomaly.site_de_depart_analyse);
            });
        }

        const departmentsToFilter = anomalyFilters.departmentFilters
            .filter((departmentFilter) => departmentFilter.selected)
            .map((departmentFilter) => departmentFilter.value);

        if (departmentsToFilter.length > 0) {
            wantedActionsMonitoring = wantedActionsMonitoring.filter((action) => {
                return departmentsToFilter.includes(action.latest_anomaly.departement_de_destination);
            });
        }

        wantedActionsMonitoring = wantedActionsMonitoring.filter((action) => {
            return this.includesIgnoreCase(action.anomalie_desc, tableFilter.anomaly);
        });

        // Puis les actions de ces anomalies
        wantedActionsMonitoring.forEach((actionMonitoring) => {
            actionMonitoring.investigations = filter(actionMonitoring.investigations, (investigation) => {
                return (
                    this.includesIgnoreCase(investigation.action_desc, tableFilter.action) &&
                    this.includesIgnoreCase(investigation.responsable, tableFilter.accountable) &&
                    (tableFilter.status === this.actionStatus.all || tableFilter.status === investigation.statut) &&
                    this.isDateInSelection(new Date(investigation.date_lancement), tableFilter.initDate) &&
                    this.isDateInSelection(new Date(investigation.date_deadline), tableFilter.deadline)
                );
            });
        });

        return wantedActionsMonitoring;
    }

    public updateNbRecordOfFiltersForList(filters: AnomalyFilters, list: ActionMonitoring[]) {
        if (filters && list) {
            filters.carrierFilters.forEach((carrier) => (carrier.nbRecord = 0));
            filters.enseigneFilters.forEach((enseigne) => (enseigne.nbRecord = 0));
            filters.departmentFilters.forEach((department) => (department.nbRecord = 0));
            filters.departureSiteFilters.forEach((departureSite) => (departureSite.nbRecord = 0));

            list.forEach((actionMonitoring) => {
                filters.carrierFilters.forEach((carrier) => {
                    if (carrier.value.includes(actionMonitoring.latest_anomaly.transporteurs_nom)) {
                        carrier.nbRecord += actionMonitoring.investigations.length;
                    }
                });

                filters.departmentFilters.forEach((department) => {
                    if (department.value.includes(actionMonitoring.latest_anomaly.departement_de_destination)) {
                        department.nbRecord += actionMonitoring.investigations.length;
                    }
                });

                filters.enseigneFilters.forEach((enseigne) => {
                    if (enseigne.value.includes(actionMonitoring.latest_anomaly.enseigne_libelle)) {
                        enseigne.nbRecord += actionMonitoring.investigations.length;
                    }
                });

                filters.departureSiteFilters.forEach((departureSite) => {
                    if (departureSite.value.includes(actionMonitoring.latest_anomaly.site_de_depart_analyse)) {
                        departureSite.nbRecord += actionMonitoring.investigations.length;
                    }
                });
            });
        }
    }

    private includesIgnoreCase(stringToLookInto: string, stringToFind: string) {
        if (stringToFind === '' || stringToFind === undefined) {
            return true;
        }
        if (stringToLookInto === undefined && stringToFind === undefined) {
            return true;
        }
        if (stringToLookInto === undefined) {
            return false;
        }
        return stringToLookInto.toUpperCase().includes(stringToFind.toUpperCase());
    }

    // Conditions to filter table with temporality and date
    private isDateInSelection(selectedDate: Date, initDate: InitDate) {
        if (initDate.temporality !== null && initDate.selectedDate) {
            if (initDate.temporality === -1 && isBefore(new Date(selectedDate), initDate.selectedDate)) {
                return true;
            } else if (initDate.temporality !== null && isSameDay(new Date(selectedDate), initDate.selectedDate)) {
                return true;
            } else if (initDate.temporality === 1 && isAfter(new Date(selectedDate), initDate.selectedDate)) {
                return true;
            }
        } else {
            return true;
        }
    }
}
