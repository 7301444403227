import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'sai-lexicon',
    templateUrl: './lexicon.component.html',
    styleUrls: ['./lexicon.component.sass']
})
export class LexiconComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
