import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Swal from "sweetalert2";
import {PermissionDto} from "@shared/models/PermissionDto";
import {RoleDto} from "@shared/models/RoleDto";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'track-permission-form',
    templateUrl: './permission-form.component.html',
    styleUrls: ['./permission-form.component.scss']
})
export class PermissionFormComponent implements OnInit {
    @Input() permission: PermissionDto;
    @Input() modalTitle: string;
    @Input() isCreationMode: boolean;
    @Output() modificationToBeSaved = new EventEmitter<PermissionDto>();
    @Output() modificationToBeCanceled = new EventEmitter<void>();

    @Input() isSaving = false;

    constructor(private translate: TranslateService) {
        this.permission = new PermissionDto();
        this.modalTitle = '';
        this.isCreationMode = false;
    }

    ngOnInit() {
    }

    public saveModification() {
        if (this.validatePermission()) {
            this.modificationToBeSaved.emit(this.permission);
        }
    }

    public cancelModification() {
        this.modificationToBeCanceled.emit();
    }

    private validatePermission(): boolean {
        let valid = true;

        if (!this.permission.code) {
            // Le code
            Swal.fire(this.translate.instant("management.right.permission.CodeMissingPopupMsg"), '', 'error');
            valid = false;
        } else if (!this.permission.labelEn) {
            // Le label EN
            Swal.fire(this.translate.instant("management.right.permission.LabelEnMissingPopupMsg"), '', 'error');
            valid = false;
        } else if (!this.permission.labelFr) {
            // Le label FR
            Swal.fire(this.translate.instant("management.right.permission.LabelFrMissingPopupMsg"), '', 'error');
            valid = false;
        } else if (!this.permission.descriptionEn) {
            // La description EN
            Swal.fire(this.translate.instant("management.right.permission.DescriptionEnMissingPopupMsg"), '', 'error');
            valid = false;
        } else if (!this.permission.descriptionFr) {
            // La description FR
            Swal.fire(this.translate.instant("management.right.permission.DescriptionFrMissingPopupMsg"), '', 'error');
            valid = false;
        }

        return valid;
    }

}
