<div class="px-5 mt-3">

    <table class="table table-sm table-borderless text-center table-hover">
        <thead>
        <tr>
            <th scope="col">{{ "anomaly.summary.site_de_depart_analyse" | translate }}</th>
            <th scope="col">{{ "anomaly.summary.transporteurs_nom" | translate }}</th>
            <th scope="col">{{ "anomaly.summary.nbTotalPattern" | translate }}</th>
            <th scope="col">{{ "anomaly.summary.enjeu_transporteur" | translate }}</th>
            <th scope="col">{{ "anomaly.summary.enjeu_global" | translate }}</th>
            <th scope="col">{{ "anomaly.summary.criticite_des_patterns" | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let summary of anomalyMonthlySummary">
            <td>{{ summary.site_de_depart_analyse }}</td>
            <td class="clickable"
                (click)="goToReco(summary.transporteurs_nom)">{{ summary.transporteurs_nom | translate }}</td>
            <td>{{ summary.nbTotalPattern }}</td>
            <td>{{ summary.enjeu_transporteur | percent: "1.0-2" }}</td>
            <td>{{ summary.enjeu_global | percent: "1.0-2" }}</td>
            <td class="cell-size">
                <sai-criticality-bar [summary]="summary"></sai-criticality-bar>
            </td>

        </tr>
        </tbody>
    </table>

</div>

