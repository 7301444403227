import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {RoleDto} from "@shared/models/RoleDto";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import Swal from "sweetalert2";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {PermissionDto} from "@shared/models/PermissionDto";
import {Subscription} from "rxjs";
import {RightNames} from "@shared/constants/right-names.constant";
import {RightManagementService} from "../services/right-management.service";

@Component({
    selector: 'track-right-management',
    templateUrl: './right-management.component.html',
    styleUrls: ['./right-management.component.scss']
})
export class RightManagementComponent implements OnInit, OnDestroy {
    public readonly RightNames = RightNames;

    public roleForCreation: RoleDto;
    public roleForEdition: RoleDto;
    public permissionForCreation: PermissionDto;
    public permissionForEdition: PermissionDto;
    public rolesDto: RoleDto[];
    public permissionsDto: PermissionDto[];
    public currentLanguage: string;
    public isSavingRole = false;
    public isSavingPermission = false;
    public isLoadingRole = false;
    public isLoadingPermission = false;

    public modalRef: BsModalRef;
    @ViewChild('createRoleModal') createRoleModal: TemplateRef<any> | undefined;
    @ViewChild('editRoleModal') editRoleModal: TemplateRef<any> | undefined;
    @ViewChild('createPermissionModal') createPermissionModal: TemplateRef<any> | undefined;
    @ViewChild('editPermissionModal') editPermissionModal: TemplateRef<any> | undefined;

    private languageChangedSubscription!: Subscription;

    constructor(
        private rightManagementService: RightManagementService,
        private translate: TranslateService,
        private modalService: BsModalService) {

        this.roleForCreation = new RoleDto();
        this.roleForEdition = new RoleDto();
        this.permissionForCreation = new PermissionDto();
        this.permissionForEdition = new PermissionDto();
        this.rolesDto = [];
        this.permissionsDto = [];
        this.modalRef = new BsModalRef();

        this.currentLanguage = translate.currentLang;
    }

    ngOnInit(): void {
        this.loadRoles();
        this.loadPermissions();

        this.languageChangedSubscription = this.translate.onLangChange
            .subscribe((langChangeEvent: LangChangeEvent) => this.currentLanguage = langChangeEvent.lang);
    }

    public openCreationRoleModal() {
        this.roleForCreation = new RoleDto();
        this.openModal(this.createRoleModal);
    }

    public openCreationPermissionModal() {
        this.permissionForCreation = new PermissionDto();
        this.openModal(this.createPermissionModal);
    }

    public openEditionRoleModalFor(role: RoleDto) {
        this.roleForEdition = role;
        this.openModal(this.editRoleModal);
    }

    public openEditionPermissionModalFor(permission: PermissionDto) {
        this.permissionForEdition = permission;
        this.openModal(this.editPermissionModal);
    }

    private openModal(template: TemplateRef<any> | undefined) {
        if (template) {
            this.modalRef = this.modalService.show(template);
        }
    }

    public loadRoles() {
        this.isLoadingRole = true;
        this.rightManagementService.getAllRoles()
            .then((roles: RoleDto[]) => {
                this.rolesDto = roles
                    .map((r) => {
                        if (!r.permissionCodes) {
                            r.permissionCodes = [];
                        }
                        return r;
                    })
                    .sort((a, b) => a.weight - b.weight);
            }).finally(() => this.isLoadingRole = false);
    }

    private loadPermissions() {
        this.isLoadingPermission = true;
        this.rightManagementService.getAllPermissions()
            .then((permissions: PermissionDto[]) => {
                this.permissionsDto = permissions.sort((a, b) => a.columnOrder - b.columnOrder);
            }).finally(() => this.isLoadingPermission = false);
    }

    public createRole(role: RoleDto) {
        this.isSavingRole = true;
        this.rightManagementService.createRole(role)
            .then((role: RoleDto) => {
                if (role) {
                    Swal.fire(this.translate.instant("management.right.role.roleCreatedPopupTitle"),
                        this.translate.instant("management.right.role.roleCreatedPopupMsg"),
                        'success')
                        .then(() => {
                            this.closeCurrentModal();
                            this.loadRoles();
                        });
                }
            })
            .catch((error) => console.log('Error! create role: ', error))
            .finally(() => this.isSavingRole = false);
    }

    public createPermission(permissionDto: PermissionDto) {
        this.isSavingPermission = true;
        this.rightManagementService.createPermission(permissionDto)
            .then((permission: PermissionDto) => {
                if (permission) {
                    Swal.fire(this.translate.instant("management.right.permission.PermissionCreatedPopupTitle"),
                        this.translate.instant("management.right.permission.PermissionCreatedPopupMsg"),
                        'success')
                        .then(() => {
                            this.closeCurrentModal();
                            this.loadPermissions();
                        });
                }
            })
            .catch((error) => console.log('Error! create permission: ', error))
            .finally(() => this.isSavingPermission = false);
    }

    public updateRole(role: RoleDto) {
        this.isSavingRole = true;
        this.rightManagementService.updateRole(role)
            .then((roleDto: RoleDto) => {
                if (roleDto) {
                    Swal.fire(this.translate.instant("management.right.role.roleUpdatedPopupTitle"),
                        this.translate.instant("management.right.role.roleUpdatedPopupMsg"), 'success').then(() => {
                        this.closeCurrentModal();
                        this.loadRoles();
                    });
                }
            })
            .catch((error) => console.log('Error! update role: ', error))
            .finally(() => this.isSavingRole = false);
    }

    public updatePermission(permissionDto: PermissionDto) {
        this.isSavingPermission = true;
        this.rightManagementService.updatePermission(permissionDto)
            .then((permission: PermissionDto) => {
                if (permission) {
                    Swal.fire(this.translate.instant("management.right.permission.PermissionUpdatedPopupTitle"),
                        this.translate.instant("management.right.permission.PermissionUpdatedPopupMsg"), 'success').then(() => {
                        this.closeCurrentModal();
                        this.loadRoles();
                    });
                }
            })
            .catch((error) => console.log('Error! update permission: ', error))
            .finally(() => this.isSavingPermission = false);
    }

    public isLanguageFrench() {
        return this.currentLanguage === 'fr';
    }

    public isLanguageEnglish() {
        return this.currentLanguage === 'en';
    }

    public closeCurrentModal() {
        this.modalRef.hide();
    }

    ngOnDestroy(): void {
        this.languageChangedSubscription.unsubscribe();
    }
}
