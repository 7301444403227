import {Component, OnInit} from '@angular/core';
import {UserService} from './user/user.service';
import {CookieService} from "./shared/services/cookie.service";
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {EnvironmentService} from "@shared/services/environment.service";

@Component({
    selector: 'sai-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
})
export class SatisfaiComponent implements OnInit {
    title = 'satisfai-app';

    constructor(private userService: UserService,
                private cookieService: CookieService,
                private router: Router,
                private translate: TranslateService,
                private environment: EnvironmentService) {
        this.handleToken();
        this.handleInternationalisation();
        this.retrieveNCheckConnectedUser().then();
    }

    ngOnInit(): void {
    }

    private handleToken() {
        const token = this.findIdToken();
        if (token) {
            this.cookieService.setSightnessToken(token);
        }
    }

    private handleInternationalisation() {
        // Deux langues proposées
        this.translate.addLangs(['fr', 'en']);

        // Par défaut au chargement de l'application on utilise la langue du navigateur, ou bien la langue par défaut (fr dans AppModule) si non définie
        this.translate.use(this.translate.getDefaultLang());
    }

    private findIdToken(): string | undefined {
        const idTokenParam = 'idToken=';
        const tokenIndex = document.location.search.indexOf(idTokenParam);
        if (tokenIndex > 0) {
            return document.location.search.substring(
                tokenIndex + idTokenParam.length,
                document.location.search.length
            );
        }
        return undefined;
    }

    /**
     * Récupère l'utilisateur courant et le stocke en local storage pour que l'application puisse l'avoir à porter.
     * Si l'utilisateur ne peut être récupéré pour X ou Y raisons on considère qu'il n'a pas l'authorisation d'accéder à l'application, donc la méthode le redirige vers une page d'erreur Access
     * @private
     */
    private async retrieveNCheckConnectedUser() {
        const userExists = await this.userService.loadConnectedUser();
        if (!userExists) {
            // Si user undefined (non existant en base) : il n'a pas le droit d'accéder à l'app donc on le redirige
            document.location.href = `${this.environment.accessBaseUrl}/errors/authz`;
        }
    }
}
