import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Recommendation} from '../model/recommendation.model';
import {Endpoints} from 'src/app/shared/services/endpoint.constant';
import {Period} from 'src/app/shared/components/monther/period.model';
import {Anomaly} from '../model/anomaly.model';
import {PatternService} from './pattern.service';
import {Pattern} from '../model/pattern.model';
import {Regle} from '../model/regle.model';
import {Observable} from 'rxjs';

@Injectable()
export class RecommendationsService {
    constructor(private http: HttpClient, public patternService: PatternService) {
    }

    endpoints = new Endpoints();

    public getRecommendationsFor(period: Period): Promise<Recommendation[]> {
        const params = new HttpParams().set('year', period.year.toString()).set('month', period.month.toString());
        return this.http
            .get<Recommendation[]>(this.endpoints.RECOMMENDATIONS, {params})
            .toPromise();
    }

    public getRegles(): Observable<any> {
        return this.http.get(this.endpoints.REGLES);
    }

    public changeVisibilityFor(recommendation: Recommendation): Promise<any> {
        if (recommendation.recommended) {
            // Delete
            return this.removeFromRecommendation(recommendation.anomaly.idanomalies);
        } else {
            // Post
            return this.http.post<Anomaly>(this.endpoints.RECOMMENDATIONS, recommendation.anomaly).toPromise();
        }
    }

    public removeFromRecommendation(idanomalies: number): Promise<any> {
        return this.http.delete(this.endpoints.RECOMMENDATIONS + '/' + idanomalies).toPromise();
    }

    public async getRecommendedAnomaliesFor(period: Period): Promise<Pattern[]> {
        const params = new HttpParams().set('year', period.year.toString()).set('month', period.month.toString());
        const anomalies = await this.http
            .get<Anomaly[]>(this.endpoints.RECOMMENDED, {params})
            .toPromise();
        return this.patternService.buildPatternListFromAnomalies(anomalies);
    }
}
