import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sai-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.sass']
})
export class AlertComponent implements OnInit {
    @Input() overdueActions: number;

    constructor() {
    }

    ngOnInit(): void {
    }

}
