<div class="filters-block">
    <ng-container *ngIf="filters">
        <ng-container *saiHasRight="'CAN_FILTER_CARRIER'">
            <sai-dropdown-filter
                [filtersLabel]="'regle'"
                [filters]="filters.regleFilters"
                (filterSelected)="filterHasBeenSelected()"
            ></sai-dropdown-filter>
        </ng-container>
        <ng-container *saiHasRight="'CAN_FILTER_CARRIER'">
            <sai-dropdown-filter
                [filtersLabel]="'carrier'"
                [filters]="filters.carrierFilters"
                (filterSelected)="filterHasBeenSelected()"
            ></sai-dropdown-filter>
        </ng-container>
    </ng-container>
</div>
