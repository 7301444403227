import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home/home.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {HomeService} from './services/home.service';
import {TableSummaryComponent} from './table-summary/table-summary.component';
import {CriticalityBarComponent} from './criticality-bar/criticality-bar.component';
import {ChartsModule} from 'ng2-charts';
import {DoughnutComponent} from './doughnut/doughnut.component';
import {LegendComponent} from './legend/legend.component';

@NgModule({
    declarations: [HomeComponent, TableSummaryComponent, CriticalityBarComponent, DoughnutComponent, LegendComponent],
    imports: [CommonModule, TranslateModule, SharedModule, ChartsModule],
    exports: [HomeComponent],
    providers: [HomeService]
})
export class HomeModule {
}
