import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {RoleDto} from "@shared/models/RoleDto";

@Component({
    selector: 'track-role-form',
    templateUrl: './role-form.component.html',
    styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent implements OnInit {
    @Input() role: RoleDto;
    @Input() modalTitle: string;
    @Input() isCreationMode: boolean;
    @Output() modificationToBeSaved = new EventEmitter<RoleDto>();
    @Output() modificationToBeCanceled = new EventEmitter<void>();

    @Input() isSaving = false;

    constructor(private translate: TranslateService) {
        this.role = new RoleDto();
        this.modalTitle = '';
        this.isCreationMode = false;
    }

    ngOnInit() {
    }

    public saveModification() {
        if (this.validateRole()) {
            this.modificationToBeSaved.emit(this.role);
        }
    }

    public cancelModification() {
        this.modificationToBeCanceled.emit();
    }

    private validateRole(): boolean {
        let valid = true;

        if (!this.role.name) {
            // Le label
            Swal.fire(this.translate.instant("management.right.role.NameMissingPopupMsg"), '', 'error');
            valid = false;
        } else if (this.role.weight !== 0 && !this.role.weight) {
            // Le label technique
            Swal.fire(this.translate.instant("management.right.role.WeightMissingPopupMsg"), '', 'error');
            valid = false;
        } else if (this.role.weight <= 0) {
            // On veut que le poids soit d'au moins 1 parce que zéro est reservé à None et on veut pas un rôle en dessous de ça
            Swal.fire(this.translate.instant("management.right.role.WeightTooSmallPopupMsg"), '', 'error');
            valid = false;
        }

        return valid;
    }
}
