import {Component, Input, OnInit} from '@angular/core';
import {KnownAccountableService} from 'src/app/anomalies/services/known-accountable.service';

@Component({
    selector: 'sai-action-accountable',
    templateUrl: './action-accountable.component.html',
    styleUrls: ['./action-accountable.component.sass'],
})
export class ActionAccountableComponent implements OnInit {
    @Input() accountable: string;

    constructor(private knownAccountableService: KnownAccountableService) {
    }

    ngOnInit(): void {
    }

    public isKnownAccountable(accountable: string): boolean {
        return this.knownAccountableService.isKnownAccountable(accountable);
    }
}
