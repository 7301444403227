import {Injectable} from '@angular/core';
import {environment} from "@environments/environment";
import {IEnvironment} from "@environments/ienvironment";

@Injectable()
export class EnvironmentService implements IEnvironment {

    constructor() {
    }

    get cookieDomain(): string {
        return environment.cookieDomain;
    }

    get production(): boolean {
        return environment.production;
    }

    get apiBaseUrl(): string {
        return environment.apiBaseUrl;
    }

    get accessBaseUrl(): string {
        return environment.accessBaseUrl;
    }

    get tokenReturnMethod(): string {
        return environment.tokenReturnMethod;
    }
}
