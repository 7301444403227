import {Component, Input, OnInit} from '@angular/core';
import {Location} from "@angular/common";

@Component({
    selector: 'sai-management-title',
    templateUrl: './management-title.component.html',
    styleUrls: ['./management-title.component.sass']
})
export class ManagementTitleComponent implements OnInit {

    @Input() titleText: string;
    @Input() isLoadingSomething: boolean;

    constructor(private location: Location) {
        this.titleText = '';
        this.isLoadingSomething = false;
    }

    ngOnInit(): void {
    }

    goToLastPage() {
        this.location.back();
    }

}
