import {Component, Input, OnInit} from '@angular/core';
import {Investigation} from 'src/app/anomalies/model/investigation.model';

@Component({
    selector: 'sai-action-to-do',
    templateUrl: './action-to-do.component.html',
    styleUrls: ['./action-to-do.component.sass'],
})
export class ActionToDoComponent implements OnInit {
    @Input() action: Investigation;

    constructor() {
    }

    ngOnInit(): void {
    }

    public isActionDescInCommentaire(): boolean {
        return this.action.idaction_list === 0;
    }
}
