import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AddResponseDialogComponent} from '../add-response-dialog/add-response-dialog.component';
import {Investigation} from 'src/app/anomalies/model/investigation.model';
import {ActionStatus} from '../model/action-status.constant';
import {ActionMonitoring} from '../model/action-monitoring.model';
import {AnomalyDetailDialogComponent} from '../anomaly-detail-dialog/anomaly-detail-dialog.component';
import {ActionsMonitoringFilter} from './actions-monitoring.filter';
import {ActionsMonitoringService} from '../services/actions-monitoring.service';
import {ActionRecapService} from 'src/app/shared/services/action-recap.service';
import {InitDate} from '../actions-date-filter/init-date.model';
import {ActivatedRoute} from '@angular/router';
import {isAfter} from 'date-fns';
import {FilterConfig} from 'src/app/anomalies/model/filter-config.model';
import {ActionService} from '../services/action.service';
import {KnownAccountableService} from 'src/app/anomalies/services/known-accountable.service';
import {Subscription} from 'rxjs';
import {AnomalyFilterService} from 'src/app/filters/services/anomaly-filter.service';
import {AnomalyFilters} from 'src/app/filters/model/anomaly-filters.model';

@Component({
    selector: 'sai-actions-monitoring',
    templateUrl: './actions-monitoring.component.html',
    styleUrls: ['./actions-monitoring.component.sass'],
})
export class ActionsMonitoringComponent implements OnInit, OnDestroy {
    /**
     * Constantes pour les status d'une action
     */
    public actionStatus: ActionStatus;
    public unfilteredActionsMonitoring: ActionMonitoring[];
    public filteredActionsMonitoring: ActionMonitoring[];
    public dateLancement: Date;
    public filterConfig: FilterConfig;
    public overdue: InitDate;
    public loading: boolean;

    private reportSubscription: Subscription;
    private filterSubscription: Subscription;

    /**
     * Filtre sur le tableau
     */
    private tableFilters: ActionsMonitoringFilter;
    private anomalyFilters: AnomalyFilters;
    public overdueActions: number;

    // Le composant de type dialog permettant d'apporter une réponse
    @ViewChild(AddResponseDialogComponent) addResponseDialog: AddResponseDialogComponent;
    // Celui pour voir le détail de l'anomalie
    @ViewChild(AnomalyDetailDialogComponent) anomalyDetailDialog: AnomalyDetailDialogComponent;

    constructor(
        private actionsMonitoringService: ActionsMonitoringService,
        private actionRecapService: ActionRecapService,
        private route: ActivatedRoute,
        private actionService: ActionService,
        private filterService: AnomalyFilterService,
        private knownAccountableService: KnownAccountableService
    ) {
        this.actionStatus = new ActionStatus();
        this.tableFilters = new ActionsMonitoringFilter();
        this.anomalyFilters = new AnomalyFilters();
        this.filterConfig = new FilterConfig();

        this.unfilteredActionsMonitoring = [];
        this.filteredActionsMonitoring = [];

        // Retrieve value from query params
        this.route.queryParams.subscribe((params: any) => {
            if (params.status_done) {
                this.statusFilter = this.actionStatus.done;
            } else if (params.status_cancelled) {
                this.statusFilter = this.actionStatus.cancelled;
            } else if (params.status_inProgress) {
                this.statusFilter = this.actionStatus.inProgress;
            } else {
                this.statusFilter = this.actionStatus.all;
            }
        });

        this.reportSubscription = this.actionRecapService.actionReport.subscribe((action) => {
            this.overdueActions = action.nbOverdueInvestigations;
        });

        this.filterSubscription = this.filterService.anomalyFilters.subscribe((anomalyFilters) => {
            this.anomalyFilters = anomalyFilters;
            this.findActionsMonitoringForCurrentFilter();
        });
    }

    ngOnInit() {
        this.filterConfig.carrier = true;
        this.filterConfig.department = true;
        this.filterConfig.departureSite = true;
        this.filterConfig.enseigne = true;

        // Chargement des données et application du filtre
        this.loadActionsMonitoring().then(() => this.findActionsMonitoringForCurrentFilter());
    }

    ngOnDestroy(): void {
        this.reportSubscription.unsubscribe();
        this.filterSubscription.unsubscribe();
    }

    private async loadActionsMonitoring() {
        this.loading = true;
        await this.actionsMonitoringService
            .findAllActionsMonitoring()
            .then((actionsMonitoring) => (this.unfilteredActionsMonitoring = actionsMonitoring))
            .finally(() => (this.loading = false));
    }

    private findActionsMonitoringForCurrentFilter() {
        this.actionsMonitoringService
            .filterActionsMonitoringFor(this.unfilteredActionsMonitoring, this.anomalyFilters, this.tableFilters)
            .then((actionsMonitoring) => (this.filteredActionsMonitoring = actionsMonitoring))
            .then(() => this.updateNbRecordOfFilters());
    }

    private updateNbRecordOfFilters() {
        this.actionsMonitoringService.updateNbRecordOfFiltersForList(
            this.anomalyFilters,
            this.filteredActionsMonitoring
        );
    }

    public overdueAlertFilter() {
        this.statusFilter = this.actionStatus.inProgress;
        this.tableFilters.deadline = {
            selectedDate: new Date(),
            temporality: -1,
        };
        this.findActionsMonitoringForCurrentFilter();
    }

    openAddResponseDialogFor(action: Investigation) {
        this.addResponseDialog.openDialogFor(action);
    }

    addingResponseCompleted(action: Investigation) {
        let anomalyIndex: number;
        let actionIndex: number;

        anomalyIndex = this.filteredActionsMonitoring.findIndex((monitoring) => {
            actionIndex = monitoring.investigations.findIndex(
                (investigation) => investigation.idaction_suivi.toString() === action.idaction_suivi.toString()
            );
            return actionIndex !== -1;
        });

        if (actionIndex !== -1 && anomalyIndex !== -1) {
            this.filteredActionsMonitoring[anomalyIndex].investigations[actionIndex] = action;
        }
    }

    addingInvestigationCompleted(investigation: Investigation) {
        const correspondingAction = this.filteredActionsMonitoring.find(
            (action) => action.latest_anomaly.anomalie_pattern === investigation.anomalie_pattern
        );
        if (correspondingAction) {
            correspondingAction.investigations.splice(0, 0, investigation);
        }
    }

    openAnomalyDetailFor(anomaly: ActionMonitoring) {
        this.anomalyDetailDialog.openDialogFor(anomaly);
    }

    dateFieldCheck(initdate: InitDate) {
        if (initdate.temporality != null && initdate.selectedDate === null) {
            return true;
        }
        return false;
    }

    overdueCheck(action: Investigation) {
        if (action.statut === this.actionStatus.inProgress) {
            if (isAfter(Date.now(), Date.parse(action.date_deadline))) {
                return true;
            }
        }
    }

    updateResponsable(investigation: Investigation) {
        this.actionService.updateResponsable(investigation);
    }

    public isKnownAccountable(accountable: string): boolean {
        return this.knownAccountableService.isKnownAccountable(accountable);
    }

    get anomalyFilter(): string {
        return this.tableFilters.anomaly;
    }

    set anomalyFilter(value: string) {
        this.tableFilters.anomaly = value;
        this.findActionsMonitoringForCurrentFilter();
    }

    get actionFiler(): string {
        return this.tableFilters.action;
    }

    set actionFiler(value: string) {
        this.tableFilters.action = value;
        this.findActionsMonitoringForCurrentFilter();
    }

    get accountableFilter(): string {
        return this.tableFilters.accountable;
    }

    set accountableFilter(value: string) {
        this.tableFilters.accountable = value;
        this.findActionsMonitoringForCurrentFilter();
    }

    get statusFilter(): string {
        return this.tableFilters.status;
    }

    set statusFilter(value: string) {
        this.tableFilters.status = value;
        this.findActionsMonitoringForCurrentFilter();
    }

    get accountabilityFilter(): string {
        return this.tableFilters.accountability;
    }

    set accountabilityFilter(value: string) {
        this.tableFilters.accountability = value;
        this.findActionsMonitoringForCurrentFilter();
    }

    get nameFilter(): string {
        return this.tableFilters.name;
    }

    set nameFilter(value: string) {
        this.tableFilters.name = value;
        this.findActionsMonitoringForCurrentFilter();
    }

    public initDateFilter(value: InitDate) {
        this.tableFilters.initDate = value;
        this.findActionsMonitoringForCurrentFilter();
    }

    public deadlineFilter(value: InitDate) {
        this.tableFilters.deadline = value;
        this.findActionsMonitoringForCurrentFilter();
    }
}
