import {Component, Input, OnInit} from '@angular/core';
import {Event} from 'src/app/anomalies/model/event.model';

@Component({
    selector: 'sai-table-r3',
    templateUrl: './table-r3.component.html',
    styleUrls: ['./table-r3.component.sass']
})
export class TableR3Component implements OnInit {
    @Input() eventR3: Event[];

    constructor() {
    }

    ngOnInit(): void {
    }

}
