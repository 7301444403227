import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserModule} from '../user/user.module';
import {SharedModule} from '@shared/shared.module';
import {UsersManagementComponent} from './users-management/users-management.component';
import {UserManagementService} from './services/user-management.service';
import {TranslateModule} from '@ngx-translate/core';
import {UserDetailComponent} from './user-detail/user-detail.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RightManagementComponent} from "./right-management/right-management.component";
import {RightTableComponent} from "./right-management/right-table/right-table.component";
import {RoleFormComponent} from "./right-management/role-form/role-form.component";
import {PermissionFormComponent} from "./right-management/permission-form/permission-form.component";
import {RightManagementService} from "./services/right-management.service";
import {ManagementMainComponent} from "./management-main/management-main.component";
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import {PopoverModule} from "ngx-bootstrap/popover";

@NgModule({
    declarations: [
        UsersManagementComponent,
        UserDetailComponent,
        RightManagementComponent,
        RightTableComponent,
        RoleFormComponent,
        PermissionFormComponent,
        ManagementMainComponent
    ],
    imports: [
        CommonModule,
        UserModule,
        SharedModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbPopoverModule,
        PopoverModule,
    ],
    providers: [
        UserManagementService,
        RightManagementService],
})
export class ManagementModule {
}
