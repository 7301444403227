import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sai-loading-circle',
    templateUrl: './loading-circle.component.html',
    styleUrls: ['./loading-circle.component.sass'],
})
export class LoadingCircleComponent implements OnInit {
    @Input() loading: boolean;

    constructor() {
        this.loading = false;
    }

    ngOnInit(): void {
    }
}
