<!--Le corps de la page-->

<div class="admin-container">
    <sai-management-title [titleText]="'management.right.title' | translate"></sai-management-title>

    <div id="role-and-permission-container">

        <div id="role-container" class="mb-3">
            <h3>
                {{ 'management.right.Roles' | translate }}
                <i [hidden]="!isLoadingRole" class="fas fa-fw fa-sm fa-circle-notch fa-spin spinner-color"></i>
            </h3>

            <button
                *saiHasRight="RightNames.CAN_WRITE_RIGHT"
                class="btn btn-primary mb-2"
                title="{{ ' management.right.CreateRole' | translate }}"
                (click)="openCreationRoleModal()">
                <i class="fas fa-plus fa-fw"></i>
                {{ "management.right.CreateRole" | translate }}
            </button>

            <ng-container *ngIf="!isLoadingRole">
                <ng-container *ngIf="!rolesDto.length; else rolesTable">
                    <p class="alert alert-info">
                        {{ "management.right.NoRoleYet" | translate }}
                    </p>
                </ng-container>
            </ng-container>
            <ng-template #rolesTable>
                <table class="table table-hover table-striped mb-0">
                    <thead>
                    <tr>
                        <th>{{ "management.right.role.Name" | translate }}</th>
                        <th>{{ "management.right.role.Weight" | translate }}</th>
                        <th *saiHasRight="RightNames.CAN_WRITE_RIGHT" class="text-right">{{ "Actions" | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let role of rolesDto">
                        <td>{{ role.name }}</td>
                        <td>{{ role.weight }}</td>
                        <td *saiHasRight="RightNames.CAN_WRITE_RIGHT" class="text-right mr-2">
                            <button class="btn btn-outline-primary mr-2 btn-sm" (click)="openEditionRoleModalFor(role)">
                                <i class="fas fa-pencil-alt"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ng-template>
        </div>

        <div id="permission-container" class="mb-3">
            <h3>
                {{ 'management.right.Permissions' | translate }}
                <i [hidden]="!isLoadingPermission" class="fas fa-fw fa-sm fa-circle-notch fa-spin spinner-color"></i>
            </h3>

            <button
                *saiHasRight="RightNames.CAN_WRITE_RIGHT"
                class="btn btn-primary mb-2"
                title="{{ ' management.right.CreatePermission' | translate }}"
                (click)="openCreationPermissionModal()">
                <i class="fas fa-plus fa-fw"></i>
                {{ "management.right.CreatePermission" | translate }}
            </button>

            <ng-container *ngIf="!isLoadingPermission">
                <ng-container *ngIf="!permissionsDto.length; else permissionsTable">
                    <p class="alert alert-info">
                        {{ "management.right.NoPermissionYet" | translate }}
                    </p>
                </ng-container>
            </ng-container>

            <ng-template #permissionsTable>
                <table class="table table-hover table-striped mb-0">
                    <thead>
                    <tr>
                        <th>{{ "management.right.permission.Code" | translate }}</th>
                        <th *ngIf="isLanguageFrench()">{{ "management.right.permission.LabelFr" | translate }}</th>
                        <th *ngIf="isLanguageEnglish()">{{ "management.right.permission.LabelFr" | translate }}</th>
                        <th *ngIf="isLanguageFrench()">{{ "management.right.permission.DescriptionFr" | translate }}</th>
                        <th *ngIf="isLanguageEnglish()">{{ "management.right.permission.DescriptionEn" | translate }}</th>
                        <th>{{ "management.right.permission.ColumnOrder" | translate }}</th>
                        <th *saiHasRight="RightNames.CAN_WRITE_RIGHT" class="text-right">{{ "Actions" | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="!permissionsDto.length">
                        <td colspan="6" class="text-center pt-4 pb-4">
                            {{ "NoPermissionYet" | translate }}
                        </td>
                    </tr>
                    <tr *ngFor="let permission of permissionsDto">
                        <td >{{ permission.code }}</td>
                        <td *ngIf="isLanguageFrench()">
                            {{ permission.labelFr }}
                        </td>
                        <td *ngIf="isLanguageEnglish()">
                            {{ permission.labelEn }}
                        </td>
                        <td *ngIf="isLanguageFrench()">
                            {{ permission.descriptionFr }}
                        </td>
                        <td *ngIf="isLanguageEnglish()">
                            {{ permission.descriptionEn }}
                        </td>
                        <td>{{ permission.columnOrder }}</td>
                        <td *saiHasRight="RightNames.CAN_WRITE_RIGHT" class="text-right mr-2">
                            <button class="btn btn-outline-primary btn-sm mr-2"
                                    (click)="openEditionPermissionModalFor(permission)">
                                <i class="fas fa-pencil-alt"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ng-template>
        </div>

    </div>

    <div class="dropdown-divider my-3"></div>

    <div>
        <h3>
            {{ 'management.right.Permission by role' | translate }}
            <i [hidden]="!isLoadingRole" class="fas fa-fw fa-sm fa-circle-notch fa-spin spinner-color"></i>
        </h3>
        <ng-container *ngIf="rolesDto.length > 0">
            <track-right-table
                [permissions]="permissionsDto"
                [roles]="rolesDto"
                (permissionHasChanged)="loadRoles()"
            ></track-right-table>
        </ng-container>
    </div>
</div>


<!--Les popins-->

<!-- La popin du formulaire de création d'un Role -->
<ng-template #createRoleModal>
    <track-role-form
        [isSaving]="isSavingRole"
        [role]="roleForCreation"
        [modalTitle]="'management.right.FormCreateRole' | translate"
        [isCreationMode]="true"
        (modificationToBeCanceled)="closeCurrentModal()"
        (modificationToBeSaved)="createRole($event)"
    ></track-role-form>
</ng-template>
<!-- La popin du formulaire d'édition d'un Role -->
<ng-template #editRoleModal>
    <track-role-form
        [isSaving]="isSavingRole"
        [role]="roleForEdition"
        [modalTitle]="'management.right.FormEditRole' | translate"
        [isCreationMode]="false"
        (modificationToBeCanceled)="closeCurrentModal()"
        (modificationToBeSaved)="updateRole($event)"
    ></track-role-form>
</ng-template>

<!-- La popin du formulaire de création d'un Role -->
<ng-template #createPermissionModal>
    <track-permission-form
        [isSaving]="isSavingPermission"
        [permission]="permissionForCreation"
        [modalTitle]="'management.right.FormCreatePermission' | translate"
        [isCreationMode]="true"
        (modificationToBeCanceled)="closeCurrentModal()"
        (modificationToBeSaved)="createPermission($event)"
    ></track-permission-form>
</ng-template>
<!-- La popin du formulaire d'édition d'un Role -->
<ng-template #editPermissionModal>
    <track-permission-form
        [isSaving]="isSavingPermission"
        [permission]="permissionForEdition"
        [modalTitle]="'management.right.FormEditPermission' | translate"
        [isCreationMode]="false"
        (modificationToBeCanceled)="closeCurrentModal()"
        (modificationToBeSaved)="updatePermission($event)"
    ></track-permission-form>
</ng-template>
