import {Filter} from './filter.model';

export class ModerationFilters {
    regleFilters: Filter[];
    carrierFilters: Filter[];

    constructor() {
        this.regleFilters = [];
        this.carrierFilters = [];
    }
}
