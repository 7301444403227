import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {
    constructor() {
    }

    public set(key: string, value: any, local = false) {
        const storage = local ? window.localStorage : window.sessionStorage;
        storage.setItem(key, JSON.stringify(value));
    }

    public get(key: string, local = false) {
        const storage = local ? window.localStorage : window.sessionStorage;
        return JSON.parse(storage.getItem(key));
    }

    public remove(key: string, local = false) {
        const storage = local ? window.localStorage : window.sessionStorage;
        storage.removeItem(key);
    }
}
