import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoadingService} from '../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private activeRequests: number;

    constructor(private loadingService: LoadingService) {
        this.activeRequests = 0;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.activeRequests === 0) {
            this.loadingService.startLoading();
        }
        this.activeRequests++;

        return next.handle(request).pipe(
            finalize(() => {
                if (this.activeRequests > 0) {
                    this.activeRequests--;
                }
                if (this.activeRequests === 0) {
                    this.loadingService.stopLoading();
                }
            })
        );
    }
}
