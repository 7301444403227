import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {MonthlySelection} from './monthly-selection.model';
import {addMonths, getMonth, getYear} from 'date-fns';
import {Period} from './period.model';
import {MontherService} from '../../services/monther.service';

@Component({
    selector: 'sai-monther',
    templateUrl: './monther.component.html',
    styleUrls: ['./monther.component.sass'],
})
export class MontherComponent implements OnInit {
    private today: Date;
    public monthly: MonthlySelection;
    public selectedPeriod: Period;
    public selectableYears: number[];
    public selectableMonths: number[];

    @Output() periodSelected = new EventEmitter<Period>();

    constructor(private montherService: MontherService) {
    }

    ngOnInit(): void {
        this.selectedPeriod = this.getSelectedPeriod();
        this.today = new Date(this.selectedPeriod.year, this.selectedPeriod.month);
        this.initModel(this.today);
        this.selectableYears = this.buildYearsSelection();
        this.selectableMonths = this.buildMontsSelection();
    }

    getSelectedPeriod(): Period {
        return this.montherService.getSelectedPeriod();
    }

    public selectYearAndMonth(year: number, month: number) {
        const newDate = new Date(year, month);
        this.initModel(newDate);
    }

    public selectNextMonth(): void {
        this.initModel(addMonths(this.getSelectedDate(), 1));
    }

    public selectPreviousMonth(): void {
        this.initModel(addMonths(this.getSelectedDate(), -1));
    }

    private initModel(day: Date) {
        this.selectedPeriod = {month: getMonth(day), year: getYear(day)};
        this.monthly = {
            currentMonth: getMonth(day),
            previousMonth: getMonth(addMonths(day, -1)),
            nextMonth: getMonth(addMonths(day, 1)),
        };

        this.montherService.updateSelectedDate(this.selectedPeriod);
        this.periodSelected.emit(this.selectedPeriod);
    }

    private getSelectedDate(): Date {
        return new Date(this.selectedPeriod.year, this.selectedPeriod.month);
    }

    private buildYearsSelection(): number[] {
        const day = new Date();
        const currentYear = getYear(day);
        // Abitrairement on se dit que les 5 dernières années sont sélectionnables
        return [currentYear - 4, currentYear - 3, currentYear - 2, currentYear - 1, currentYear];
    }

    private buildMontsSelection(): number[] {
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    }
}
