import {Component, OnInit} from '@angular/core';
import {ModerationFilters} from '../model/moderation-filters.model';
import {ModerationFilterService} from '../services/moderation-filter.service';

@Component({
    selector: 'sai-moderation-filters',
    templateUrl: './moderation-filters.component.html',
    styleUrls: ['./moderation-filters.component.sass'],
})
export class ModerationFiltersComponent implements OnInit {
    public filters: ModerationFilters;

    constructor(private moderationFilterService: ModerationFilterService) {
    }

    ngOnInit(): void {
        this.moderationFilterService.moderationFilters.subscribe(
            (moderationFilters) => (this.filters = moderationFilters)
        );
    }

    filterHasBeenSelected() {
        this.moderationFilterService.filterHasBeenSelected(this.filters);
    }
}
