<sai-page-title title="{{ 'events.title.events' | translate }}"></sai-page-title>

<sai-monther (periodSelected)="updatePeriod($event)"></sai-monther>

<div class="container-fluid px-5 mt-2">
    <div class="row">
        <div class="col">
            <sai-anomaly-filters [filterRights]="filterRights"></sai-anomaly-filters>
        </div>
    </div>

    <sai-loading-circle [loading]="isLoading()"></sai-loading-circle>

    <ng-container *ngIf="!isLoading()">
        <div class="row">
            <div class="col">
                <div *ngIf="filterSelected == true; else alert" class="mt-4">
                    <div class="mb-5" *ngIf="eventR1.length > 0">
                        <sai-table-r1 [eventR1]="eventR1"></sai-table-r1>
                    </div>

                    <div *ngIf="eventR3.length > 0">
                        <sai-table-r3 [eventR3]="eventR3"></sai-table-r3>
                    </div>

                    <ng-container *ngIf="eventR1.length == 0 && eventR3.length == 0">
                        <div class="alert alert-info">
                            {{ "alert.no_event_available" | translate }}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <ng-template #alert>
            <div class="alert alert-info">
                {{ "alert.site_de_depart" | translate }}
            </div>
        </ng-template>
    </ng-container>
</div>
