import {Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy} from '@angular/core';
import {UserService} from 'src/app/user/user.service';
import {Subscription} from "rxjs";

@Directive({
    selector: '[saiHasRight]',
})
export class HasRightDirective implements OnInit, OnDestroy {
    private subscription!: Subscription;

    // the role the user must have
    @Input() set saiHasRight(right: string) {
        this.subscription = this.userService.connectedUser.subscribe(user => {
            if (user && user.rights && user.rights.includes(right)) {
                // Si l'utilisateur a le droit demandé on affiche l'élément
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            } else {
                // Dans le cas contraire, non
                this.viewContainerRef.clear();
            }
        });
    }

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
