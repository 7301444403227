<nav class="navbar navbar-expand navbar-dark shadow-sm px-5 py-0 mb-4">
    <div class="navbar-nav">
        <a class="nav-link py-1" [routerLink]="['/home']"
           routerLinkActive="active">{{ "header.nav.home" | translate }} </a>
        <a class="nav-link py-1" *saiHasRight="'CAN_WRITE_RECOMMENDATION'" [routerLink]="['/recommendations-selection']"
           routerLinkActive="active">{{ "header.nav.recommendations selection" | translate }} </a>
        <a class="nav-link py-1" *saiHasRight="'CAN_READ_RECOMMENDATION'"
           [routerLink]="['/recommendations-visualization']"
           routerLinkActive="active">{{ "header.nav.recommendations visualization" | translate }} </a>
        <a class="nav-link py-1" *saiHasRight="'CAN_READ_EVENT'" [routerLink]="['/events']"
           routerLinkActive="active">{{ "header.nav.events" | translate }} </a>
        <a class="nav-link py-1" *saiHasRight="'CAN_READ_ACTION'" [routerLink]="['/actions-monitoring']"
           routerLinkActive="active">{{ "header.nav.actions" | translate }}
            <sai-badge></sai-badge>
        </a>
        <a class="nav-link py-1" *saiHasRight="'CAN_READ_DASHBOARD'" [routerLink]="['/dashboard']"
           routerLinkActive="active">{{ "header.nav.dashboard" | translate }} </a>
        <a class="nav-link py-1" *saiHasRight="'CAN_WRITE_MANAGEMENT'" [routerLink]="['/management']"
           routerLinkActive="active">{{ "header.nav.management" | translate }} </a>
        <a class="nav-link py-1" *saiHasRight="'CAN_READ_DATA'" [routerLink]="['/lexicon']"
           routerLinkActive="active">{{ "header.nav.lexicon" | translate }} </a>
    </div>
</nav>
