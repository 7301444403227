import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from './user.model';
import {Endpoints} from '@shared/services/endpoint.constant';
import {BehaviorSubject} from 'rxjs';
import {StorageService} from '@shared/services/storage.service';
import {StorageKeys} from '@shared/services/storage.constant';
import {CookieService} from '@shared/services/cookie.service';

@Injectable()
export class UserService {
    endpoints = new Endpoints();
    public connectedUser: BehaviorSubject<User>;

    constructor(private storage: StorageService, private http: HttpClient, private cookieService: CookieService) {
        this.connectedUser = new BehaviorSubject<User>({} as User);
    }

    /**
     * Récupère l'utilisateur courant sous la forme d'un utilisateur authentifié et authorisé (donc présent en BDD) en le stockant dans un observable : connectedUser
     * Retourne false si aucun utilisateur n'a été trouvé
     */
    public async loadConnectedUser(): Promise<boolean> {
        const currentUser = await this.getConnectedUserFromServer();

        if (currentUser) {
            this.connectedUser.next(currentUser);
            return true;
        }
        return false;
    }

    public logoutAndGetRedirectionUrl(): string {
        this.removeConnectedUser();
        return this.endpoints.LOGOUT;
    }

    public removeConnectedUser() {
        this.cookieService.removeSightnessToken();
    }

    private getConnectedUserFromServer(): Promise<User | undefined> {
        let res = this.http.get<User>(this.endpoints.ME).toPromise();
        if (res) {
            return res;
        }
        return undefined;
    }
}
