import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Anomaly} from '../model/anomaly.model';
import {Investigation} from '../model/investigation.model';

@Component({
    selector: 'sai-add-investigation',
    templateUrl: './add-investigation.component.html',
    styleUrls: ['./add-investigation.component.sass'],
})
export class AddInvestigationComponent implements OnInit {
    @Input() buttonLabel: string;
    @Input() buttonCssClasses: string;
    @Input() anomaly: Anomaly;
    @Input() showInvestigationList: boolean;

    @Output() addInvestigationCompleted = new EventEmitter<Investigation>();

    private addInvestigationModal: NgbActiveModal;

    @ViewChild('addInvestigation', {static: true}) addInvestigation: ElementRef;

    constructor(public modalService: NgbModal) {
    }

    ngOnInit(): void {
    }

    openModalAddInvestigation() {
        this.addInvestigationModal = this.modalService.open(this.addInvestigation, {
            size: 'xl',
            backdropClass: 'light-dialog-backdrop',
            windowClass: 'dark-dialog dialog-xl',
        });
    }

    closeDialog() {
        this.addInvestigationModal.close();
    }

    addingInvestigationCompleted(investigation: Investigation) {
        this.addInvestigationCompleted.emit(investigation);

        // Dans le cas où on n'affiche pas la liste des investigations, on ferme la fenêtre pour simplifier la vie de l'utilisateur
        if (!this.showInvestigationList) {
            this.closeDialog();
        }
    }
}
