<div class="container-fluid px-5">
    <div class="row">
        <h1 class="display-4 col">{{ "home.welcome" | translate: personDisplayName }}</h1>
    </div>
</div>
<sai-monther (periodSelected)="updatePeriod($event)"></sai-monther>
<div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
</div>

<div class="container-fluid px-5">
    <div *ngIf="anomalyMonthlySummary?.length > 0; else noDataBlock" class="row">
        <div class="col-auto">
            <sai-doughnut [totalPattern]="patternTotal" [sumCriticalityPattern]="sumCriticalityPattern"></sai-doughnut>
        </div>
        <div class="col">
            <sai-table-summary [anomalyMonthlySummary]="anomalyMonthlySummary"></sai-table-summary>
        </div>
    </div>

    <ng-template #noDataBlock>
        <ng-container *ngIf="loading === true; else noDataMsgBlock">
            <div class="text-center my-6">
                <i class="fas fa-circle-notch fa-spin fa-3x spinner-color"></i>
            </div>
        </ng-container>
        <ng-template #noDataMsgBlock>
            <div class="alert alert-info">{{ "alert.no_anomaly_available" | translate }}</div>
        </ng-template>
    </ng-template>
</div>
