import {Injectable} from '@angular/core';
import {CookieService as NgxCookieService} from 'ngx-cookie-service';
import {EnvironmentService} from './environment.service';
import {StorageKeys} from './storage.constant';

@Injectable()
export class CookieService {

    constructor(private ngxCookieService: NgxCookieService,
                private environmentService: EnvironmentService) {
    }


    public setSightnessToken(tokenValue: string) {
        this.ngxCookieService.set(
            StorageKeys.SIGHTNESS_TOKEN,
            tokenValue,
            0,
            '/',
            this.environmentService.cookieDomain,
            undefined,
            'Strict'
        );
    }

    public removeSightnessToken() {
        this.ngxCookieService.delete(StorageKeys.SIGHTNESS_TOKEN, '/', this.environmentService.cookieDomain);
    }
}
