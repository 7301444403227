import {Component, OnDestroy, OnInit} from '@angular/core';
import {RecommendationsService} from '../services/recommendations.service';
import {Recommendation} from '../model/recommendation.model';
import {Period} from 'src/app/shared/components/monther/period.model';
import {cloneDeep, filter, orderBy} from 'lodash';
import {MontherService} from '../../shared/services/monther.service';
import {ModerationFilterService} from 'src/app/filters/services/moderation-filter.service';
import {ModerationFilters} from 'src/app/filters/model/moderation-filters.model';
import {Filter} from 'src/app/filters/model/filter.model';
import {Anomaly} from '../model/anomaly.model';
import {Subscription} from 'rxjs';

@Component({
    selector: 'sai-recommendations',
    templateUrl: './reco-selection.component.html',
    styleUrls: ['./reco-selection.component.sass'],
})
export class RecommendationsSelectionComponent implements OnInit, OnDestroy {
    public recommendations: Recommendation[];
    public filteredRecommendations: Recommendation[];
    private filters: ModerationFilters;
    public loading: boolean;

    private filterSubscription: Subscription;

    public cols = {
        num_regle: -1,
        statut: -1,
        aussi_present: -1,
        transporteurs_nom: -1,
        site_de_depart_analyse: -1,
        jour_de_depart: -1,
        date_de_depart: -1,
        livraison_tranch_distance: -1,
        augmentation_volume: -1,
        joursferier_entre_exp_liv: -1,
        jour_de_livraison_theorique: -1,
        departement_de_destination: -1,
        ville_arrivee: -1,
        enseigne_libelle: -1,
        libelle_client: -1,
        code_client: -1,
        enjeu_global: -1,
        exp_en_retard: -1,
        exp_a_lheure: -1,
        taux_retard: -1,
        rst: -1,
        exp_en_retard_hors: -1,
        exp_a_lheure_hors: -1,
        taux_retard_hors: -1,
        deff_taux_retard_pattern_taux_retard_hors_pattern: -1,
    };

    constructor(
        public recommendationService: RecommendationsService,
        private moderationFilterService: ModerationFilterService
    ) {
        this.filterSubscription = this.moderationFilterService.moderationFilters.subscribe(
            (moderationFilters: ModerationFilters) => {
                this.filters = moderationFilters;
                this.filterRecommendations();
            }
        );
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.filterSubscription.unsubscribe();
    }

    private getAnomaliesFor(period: Period) {
        this.loading = true;
        this.recommendationService
            .getRecommendationsFor(period)
            .then(
                (recommendations) =>
                    (this.recommendations = orderBy(recommendations, (reco) => reco.anomaly.num_regle, 'asc'))
            )
            .then(() => this.filterRecommendations())
            .finally(() => (this.loading = false));
    }

    public updatePeriod(period: Period) {
        this.getAnomaliesFor(period);
    }

    public changeVisibilityFor(recommendation: Recommendation, currentRow: any): boolean {
        currentRow.loading = true;
        this.recommendationService
            .changeVisibilityFor(recommendation)
            .then(() => (recommendation.recommended = !recommendation.recommended))
            .finally(() => (currentRow.loading = false));
        return false;
    }

    sortTable(col: string) {
        // resetting arrow icons
        const tmp = this.cols[col] === -1 ? 1 : this.cols[col] + 1;
        Object.keys(this.cols).map((k) => (this.cols[k] = -1));
        this.cols[col] = tmp;

        this.filteredRecommendations = this.filteredRecommendations.sort((a, b) => {
            const tmpA = a.anomaly[col];
            const tmpB = b.anomaly[col];

            if (tmpA === tmpB) {
                return 0;
            }
            return this.cols[col] % 2 ? (tmpA < tmpB ? -1 : 1) : tmpA < tmpB ? 1 : -1;
        });
    }

    public filterRecommendations() {
        this.filteredRecommendations = cloneDeep(this.recommendations);

        if (this.filteredRecommendations) {
            // filtre en eux mêmes
            const reglesToFilter = this.filters.regleFilters
                .filter((regleFilter) => regleFilter.selected)
                .map((regleFilter) => regleFilter.value);

            if (reglesToFilter.length > 0) {
                this.filteredRecommendations = filter(this.filteredRecommendations, (recommendation) => {
                    return reglesToFilter.includes(recommendation.anomaly.num_regle);
                });
            }

            const carriersToFilter = this.filters.carrierFilters
                .filter((carrierFilter) => carrierFilter.selected)
                .map((carrierFilter) => carrierFilter.value);

            if (carriersToFilter.length > 0) {
                this.filteredRecommendations = filter(this.filteredRecommendations, (recommendation) => {
                    return carriersToFilter.includes(recommendation.anomaly.transporteurs_nom);
                });
            }

            // Pastilles bleus
            this.filters.carrierFilters.forEach((carrier: Filter) => (carrier.nbRecord = 0));
            this.filters.regleFilters.forEach((regle: Filter) => (regle.nbRecord = 0));

            this.filteredRecommendations
                .map((recommendation: Recommendation) => recommendation.anomaly)
                .forEach((anomaly: Anomaly) => {
                    this.filters.carrierFilters.forEach((carrier: Filter) => {
                        if (carrier.value.includes(anomaly.transporteurs_nom)) {
                            carrier.nbRecord++;
                        }
                    });

                    this.filters.regleFilters.forEach((regle: Filter) => {
                        if (regle.value.includes(anomaly.num_regle)) {
                            regle.nbRecord++;
                        }
                    });
                });
        }
    }
}
